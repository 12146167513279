<template>
  <div>
    <slot
      :connectDomain="connectDomain"
      :disconnectDomain="disconnectDomain"
      :isConnectedDomain="isConnectedDomain"
      :getNs="getNs"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const StoreRepository = Repository.get("store");
const OwnerRepository = Repository.get("owner");
export default {
  methods: {
    async connectDomain(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        await StoreRepository.connectDomain(payload);
        this.$store.commit("toggle/toggleLoading", false);
        this.getNsByStore();
        this.getStores(this.$auth.user().hash_id);
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}` });
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async disconnectDomain(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        await StoreRepository.disconnectDomain(payload);
        this.$store.commit("toggle/toggleLoading", false);
        this.getNsByStore();
        this.getStores(this.$auth.user().hash_id);
        this.$notifications.showSuccess({ message: 'fo-domain-disconnected-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("domain/reset");
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async isConnectedDomain(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await StoreRepository.isConnectedDomain(payload);
        if(data.info) {
          this.$notifications.showSuccess({ message: 'fo-domain-connected' });
          this.getStores(this.$auth.user().hash_id);
        } else {
          this.$notifications.showSuccess({ message: 'fo-try-again-in-a-few-minutes' });
        }
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
   async getNs(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        await StoreRepository.getNs(payload);
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async getNsByStore() {
      this.$store.commit("toggle/toggleLoading", true);
      try {
       const { data } = await StoreRepository.getNsByStore({
          params: { store_hash_id: this.store.hash_id },
        });
        if(data.info) {
          this.$store.commit("domain/setDomainInfo", data.info);
          this.$store.commit("domain/setStep", 2);
        } else {
          this.$store.commit("domain/setStep", 1);
        }
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } 
    },
    async getStores(id) {
      try {
        const { data } = await OwnerRepository.getStores(id);
        if(data.length) {
           this.$store.commit("store/setStore", data[0]);
        }
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
  },
  computed: {
     ...mapState("store", ["store"]),
  },
  created() {
    this.getNsByStore();
  },
};
</script>

<style>
</style>