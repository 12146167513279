<template>
  <div>
    <slot
      @getCategory="getCategory"
      :updateCategory="updateCategory"
      :createCategoryFromProduct="createCategoryFromProduct"
      :createCategory="createCategory"
      :getCategory="getCategory"
      :deleteCategory="deleteCategory"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const CategoryRepository = Repository.get("categories");
const StoreRepository = Repository.get("store");
export default {
  computed: {
    ...mapState("store", ["store"]),
  },
  methods: {
    async getStoreCategories(id) {
      this.$store.commit("category/setLoading", true);
      try {
        const { data } = await StoreRepository.getStoreCategories(id);
        this.$store.commit("category/setCategories", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("category/setLoading", false);
      } finally {
        this.$store.commit("category/setLoading", false);
      }
    },
    async createCategory(payload) {
      try {
        let id = this.store.hash_id;
        await CategoryRepository.create(id, payload);
        this.getStoreCategories(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-category-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async createCategoryFromProduct(payload) {
      try {
        let id = this.store.hash_id;
        const { data } = await CategoryRepository.create(id, payload);
        this.getStoreCategories(this.store.hash_id);
        this.$store.commit("product/updateProductInfo", { key: 'category_hash_id', value: data.hash_id });
        this.$notifications.showSuccess({ message: 'fo-category-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getCategory(id) {
      try {
        const { data } = await CategoryRepository.getCategoryById(id);
        this.$store.commit("category/setCategory", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async updateCategory(payload) {
      try {
        await CategoryRepository.update(payload);
        this.getStoreCategories(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-category-updated-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteCategory(id) {
      try {
        await CategoryRepository.delete(id);
        this.getStoreCategories(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-category-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
  },
  created() {
    this.getStoreCategories(this.store.hash_id);
  },
};
</script>

<style>
</style>