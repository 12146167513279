<template>
  <OwnerLayout>
    <SharedStore v-if="this.$auth.user()">
      <router-view />
    </SharedStore>
  </OwnerLayout>
</template>

<script>
import OwnerLayout from "@/layouts/owner";
import SharedStore from "@/components/shared/Store";
export default {
  components: {
    OwnerLayout,
    SharedStore,
  },
};
</script>

<style>
</style>