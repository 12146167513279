export default {
    install(Vue, store) {
        Vue.prototype.$notifications = {
            showError({ message = '', code = '', color = 'red', type = 'error' }) {
                store.commit('alert/showMessage', { message, code, color, type })
            },
            showSuccess({ message = '', code = '', color = 'green', type = 'success' }) {
                store.commit('alert/showMessage', { message, code, color, type })
            },
        };
    }
}