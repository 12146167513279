<template>
  <v-col cols="12" v-if="!loading">
    <v-row v-if="products.length > 0">
      <v-col cols="12">
        <v-row justify="end" align="center">
          <v-col cols="12" xl="6" lg="4" md="4" sm="6">
             <v-text-field
              v-model="search"
              solo
              flat
              :label="$t('fo-name')"
              clearable
              hide-details
              append-icon="mdi-magnify"
              @input="searchProduct"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" v-if="$vuetify.breakpoint.smAndUp">
            <v-btn depressed height="48" :ripple="false" @click.stop="list = !list"><v-icon>{{ list ? 'mdi-menu' : 'mdi-view-grid' }}</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-for="(product, index) in visibleProducts" cols="12" :xl="cols.xl" :lg="cols.lg" :md="cols.md" :sm="cols.sm" :key="index">
        <ProductCard :product="product" />
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="auto">
            <v-pagination
              v-model="page"
              :total-visible="7"
              :length="pageCount"
              class="elevation-0"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
      <DeleteModal @yes="deleteItemConfirm" />
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        {{ $t("fo-no-registered-products") }}
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" v-else>
    <Loader :height="400" />
  </v-col>
</template>

<script>
import ProductCard from "@/components/owner/ProductCard";
import DeleteModal from "@/components/owner/DeleteModal";
import Loader from "@/components/shared/Loader";
import { mapState } from "vuex";
export default {
  props: ["ownerProducts"],
  components: {
    ProductCard,
    Loader,
    DeleteModal
  },
  data() {
    return {
      search: null,
      list: false,
      cols: {
        xl: 2,
        lg: 3,
        md: 3,
        sm: 6
      },
      page: 1,
      itemsPerPage: 8,
    }
  },
  watch: {
    list(val) {
      val ? this.cols = { xl: 12, lg: 12,  md: 12, sm: 12 } : this.cols = { xl: 2, lg: 3,  md: 3, sm: 6 }
    }
  },
  computed: {
    ...mapState("product", ["info","products", "paginationInfo"]),
    ...mapState("store", ["store"]),
    ...mapState("toggle", ["loading"]),
     filteredProducts() {
      return (
        !this.products.length ||
        this.products
          .filter((i) => {
            return !this.search || i.name.toLowerCase().includes(this.search.toLowerCase());
          })
      );
    },
    visibleProducts () {
      return !this.products.length || this.filteredProducts.slice((this.page - 1) * this.itemsPerPage, this.page* this.itemsPerPage);
    },
    pageCount() {
      return Math.ceil(this.filteredProducts.length/this.itemsPerPage);
    }
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$emit("getProducts", this.store.hash_id);
    },
    deleteItemConfirm() {
      this.$emit('delete', this.info.hash_id);
      this.$store.commit("toggle/toggleDeleteModal", false);
    },
    searchProduct(search) {
      this.search = search;
    },
  }
};
</script>

<style>
</style>