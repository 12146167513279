<template>
  <v-container class="fill-height" :class="$vuetify.breakpoint.smAndDown ? null : 'py-16'">
    <v-row class="fill-height">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <div class="text-h6 font-weight-black">
              {{ $t("fo-complete-your-order") }}
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6">
            <v-row>
              <v-col>
                <v-item-group
                  v-model="selected"
                  class="pa-2"
                  mandatory
                  active-class="primary"
                >
                  <template v-for="(delivery_type, i) in store.delivery_types">
                    <v-item :key="`item-${i}`" :value="delivery_type">
                      <template #default="{ active, toggle }">
                        <v-btn
                          :input-value="active"
                          class="me-2"
                          text
                          @click.native="toggle"
                        >
                          {{ $t("fo-"+delivery_type.toLowerCase()) }}
                        </v-btn>
                      </template>
                    </v-item>
                  </template>
                </v-item-group>
                <v-window v-model="selected" class="grey lighten-5">
                  <template v-for="(delivery_type, i) in store.delivery_types">
                    <v-window-item :key="`window-${i}`" :value="delivery_type">
                      <v-card
                        min-height="200"
                        class="rounded-xl pa-8"
                        color="muted"
                        flat
                      >
                        <v-row>
                          <v-col cols="auto me-auto">
                            <div class="text-h6 font-weight-black">
                              {{ $t("fo-"+delivery_type.toLowerCase()) }}
                            </div>
                          </v-col>
                          <v-col v-if="selected == 'DELIVERY'" cols="auto">
                            <v-btn
                             v-if="defaultAddress"
                              text
                              :ripple="false"
                              color="primary"
                              class="text-none px-0"
                              @click="toggleAddressModal"
                              >{{ $t("fo-edit") }}
                              <v-icon right color="primary">
                                mdi-pencil
                              </v-icon></v-btn
                            >
                              <v-btn
                                v-else
                                depressed
                                :ripple="false"
                                color="primary"
                                class="text-none"
                                @click="toggleAddressModal"
                                >{{ $t("fo-new-address") }}
                              </v-btn
                            >
                          </v-col>
                          <v-col v-if="defaultAddress" cols="12">
                            <v-icon class="me-2"> mdi-map-marker </v-icon>
                            <span>{{ defaultAddress }}</span>
                          </v-col>
                          <v-col v-else cols="12">
                            {{ $t("fo-you-have-no-registered-addresses") }}
                          </v-col>
                          <v-col v-if="deliveryTime" cols="12">
                            <v-icon class="me-2"> mdi-clock-time-three </v-icon>
                            <span>{{ deliveryTime }}</span>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-window-item>
                  </template>
                </v-window>
              </v-col>
              <v-col cols="12" v-if="!$auth.isLoggedIn()">
                <v-col cols="12">
                  <div class="text-h6 font-weight-black">
                    {{ $t("fo-client-data") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-form @submit.prevent ref="userForm" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-text-field
                          :value="order.buyer_name"
                          @input="updateOrder('buyer_name', $event)"
                          outlined
                          :label="$t('fo-name')"
                          :rules="required"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          :rules="required"
                          :value="order.buyer_phone"
                          @input="updateOrder('buyer_phone', $event)"
                          outlined
                          :label="$t('fo-phone')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <div class="text-h6 font-weight-black">
                      {{ $t("fo-payment-method") }}
                    </div>
                  </v-col>
                  <v-col v-if="filteredPaymentOptions" cols="12">
                    <v-radio-group @change="setPaymentMethod($event)" :value="order.payment_method">
                      <v-radio
                        class="m-0 p-0"
                        v-for="(payment, index) in filteredPaymentOptions"
                        :key="index"
                        :label="$t('fo-'+payment.toLowerCase().replace('_', '-'))"
                        :value="payment"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" class="ms-auto">
            <v-card class="rounded-xl pa-8" min-height="550">
              <CartItems :hasRemoveButton="false" :hasDeliveryFee="selected == 'DELIVERY' ? true : false" />
            </v-card>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6">
            <v-btn
              color="primary"
              :ripple="false"
              depressed
              large
              width="100%"
              class="rounded-lg text-none"
              @click="validate"
              >{{ $t("fo-finish-order") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AddressModal />
  </v-container>
</template>

<script>
import CartItems from "@/components/shop/default/CartItems";
import AddressModal from "@/components/shop/default/AddressModal";
import { validationRules } from "@/mixins/formValidation";
import { mapMutations, mapState } from "vuex";
export default {
  mixins: [validationRules],
  components: {
    AddressModal,
    CartItems,
  },
  data() {
    return {
      valid: false,
      selected: 0,
    };
  },
  created() {
    this.$emit("getCart");
    // if(!this.$auth.isLoggedIn() && !this.cartId) {
    //   this.back()
    // }
  },
  methods: {
    ...mapMutations({
      toggleAddressModal: "toggle/toggleAddressModal",
    }),
    edit() {
      this.$store.commit("buyer/setStep", 0);
    },
    setPaymentMethod(paymentOption) {
      this.updateOrder("payment_method", paymentOption);
    },
    finishOrder() {
      this.updateOrder("cart_hash_id", this.cart.hash_id);
      this.updateOrder("delivery_method", this.selected);
      this.updateOrder("store_hash_id", this.$route.params.id);
      this.updateOrder("buyer_address", this.buyerAddress);
      this.updateOrder("timezone", this.timezone);
      if(this.selected == "DELIVERY") {
        this.updateOrder("delivery_fee", this.store.delivery_fee);
      }
      if (this.selected == "WITHDRAW") {
        this.updateOrder("store_address", this.storeAddress);
      }
      if (this.$auth.isLoggedIn() && this.$auth.getScope().includes('buyer')) {
        this.updateOrder("buyer_hash_id", this.$auth.user().hash_id);
      } 
      if (this.$auth.isLoggedIn() && this.$auth.getScope().includes('owner')) {
        this.updateOrder("buyer_name", this.$auth.user().name);
        this.updateOrder("buyer_phone", this.store.phone);
      }
    },
    validate() {
      if (this.order.payment_method == null) {
        this.$notifications.showError({ message: "fo-please-select-a-payment-method" });
      } else if (this.selected == "DELIVERY" && !this.buyerAddress) {
        this.$notifications.showError({ message: "fo-please-register-an-address" });
      } else if (!this.$auth.isLoggedIn() && !this.$refs.userForm.validate()) {
        this.$notifications.showError({ message: "fo-please-insert-name-and-phone" });
      } else {
        this.finishOrder();
        if (this.order.payment_method == "ONLINE_PAYMENT") {
          this.$store.commit("order/setStep", 1);
        } else {
          this.$store.commit('order/setStep', 2);
        } 
      }
    },
    updateOrder(key, value) {
      this.$store.commit("order/updateOrder", { key: key, value });
    },
    back() {
      this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}` });
    },
  },
  computed: {
    ...mapState("store", ["store"]),
    ...mapState("order", ["order"]),
    ...mapState("cart", ["cart"]),
    ...mapState("buyer", ["address", "addresses"]),
    defaultAddress() {
      return this.selected == "DELIVERY" ? this.buyerAddress : this.storeAddress;
    },
    deliveryTime() {
      if(!this.store.delivery_time_min && !this.store.delivery_time_max) {
        return null
      } if(this.store.delivery_time_min) {
        return `${this.store.delivery_time_min}-${this.store.delivery_time_max} ${this.$t("fo-min")}`;
      } else {
        return `${this.store.delivery_time_max} ${this.$t("fo-min")}`;
      }
    },
    storeAddress() {
      return (
        `${this.store.address} - ${this.store.city_name}/${this.store.state_name}`
      );
    },
    buyerAddress() {
      return this.address.address ? `${this.address.address} - ${this.address.city_name}/${this.address.state_name} - ${this.address.zip_code}` : null;
    },
    // cartId() {
    //   let cartHash = this.$cookies.get("cart_hash");
    //   return cartHash;
    // },
    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    // filtra as opções de pagamento para não exibir a opção de pagamento online caso não haja um gateway ativo
    filteredPaymentOptions() {
      return !this.store.payment_options || this.store.payment_options.filter(item => { return !this.store.owner_gateway ? item != 'ONLINE_PAYMENT' : item});
    }
  },
};
</script>

<style>
</style>