<template>
  <v-sheet class="v-swatches">
    <v-btn
      v-for="(color, index) in swatches"
      :key="index"
      :color="color"
      :depressed="depressed"
      :disabled="disabled"
      :elevation="elevation"
      :height="size"
      :outlined="outlined"
      :plain="plain"
      :raised="raised"
      :tile="tile"
      :value="color"
      :width="size"
      min-width="auto"
      @click="onSwatchClick($event)"
    >
      <v-icon v-if="color === modelValue" :size="iconSize">
        {{ icon }}
      </v-icon>
    </v-btn>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="customColor ? customColor : 'primary'"
          :depressed="depressed"
          :disabled="disabled"
          :elevation="elevation"
          :height="size"
          :outlined="outlined"
          :plain="plain"
          :raised="raised"
          :tile="tile"
          :width="size"
          min-width="auto"
          @click="menu = true"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-color-picker v-model="customColor" @input="setCustomColor($event)"></v-color-picker>
      </v-card>
    </v-menu>
  </v-sheet>
</template>

<script>
import colors from "vuetify/lib/util/colors";
export default {
  props: {
    modelValue: { type: String, default: "#FF5E03" },
    swatches: {
      type: Array,
      default: () => [
        colors.shades.black,
        colors.red.base,
        colors.pink.base,
        colors.purple.base,
        colors.deepPurple.base,
        colors.indigo.base,
        colors.blue.base,
        colors.lightBlue.base,
        colors.cyan.base,
        colors.teal.base,
        colors.green.base,
        colors.lightGreen.base,
        colors.lime.base,
        colors.yellow.base,
        colors.amber.base,
        colors.orange.base,
        colors.deepOrange.base,
        colors.brown.base,
        colors.blueGrey.base,
        colors.grey.base,
      ],
    },
    size: { type: String, default: "2rem" },
    icon: { type: String, default: "mdi-checkbox-marked-circle" },
    iconSize: { type: String, default: "1rem" },
    depressed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    elevation: { type: [Number, String], default: undefined },
    outlined: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    raised: { type: Boolean, default: true },
    rounded: { type: Boolean, default: false },
    tile: { type: Boolean, default: false },
  },
  data() {
    return {
      menu: false,
      customColor: "#006CFF",
    };
  },
  //   model: {
  //     prop: "modelValue",
  //     event: "update:modelValue",
  //   },
  methods: {
    onSwatchClick(e) {
      console.log(e.target.value);
      const value = e.target.value;
      if (!value || this.modelValue === value) {
        return;
      }
      this.$emit("update", value);
    },
    setCustomColor(e) {
        this.$emit("setCustomColor", e);
    }
  },
};
</script>

<style>
.v-swatches {
  width: auto;
  display: inline;
}
.v-swatches .v-btn {
  padding: 0 !important;
  margin: 0.1rem;
}
.v-swatches .v-btn .v-icon {
  filter: invert(100%) grayscale(100%) contrast(100);
}
</style>