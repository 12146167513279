<template>
    <Store v-if="this.$auth.user()">
      <template v-slot="{ createStore, updateStore, getCurrency }">
          <OwnerStore v-if="!loading" @createStore="createStore" @updateStore="updateStore" @getCurrency="getCurrency" />
          <v-col cols="12" v-else>
            <Loader :height="400" />
          </v-col>
      </template>
    </Store>
</template>

<script>
import OwnerStore from "@/components/owner/Store"
import Store from "@/components/shared/Store"
import Loader from "@/components/shared/Loader";
import { mapState } from "vuex";
export default {
  components: {
    OwnerStore,
    Store,
    Loader
  },
  computed: {
    ...mapState("toggle", ["loading"]) 
  }
}
</script>

<style>

</style>