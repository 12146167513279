<template>
  <v-form @submit.prevent ref="form" v-model="formValidation" lazy-validation>
    <v-row class="d-flex flex-column" align="center">
       <v-col cols="12" v-if="hasName" class="py-0">
        <v-text-field
          outlined
          :rules="nameRules"
          :label="$t('fo-name')"
          :value="info.name" @input="updateBuyerInfo('name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" v-if="hasEmail" class="py-0">
        <v-text-field
          outlined
          :rules="emailRules"
          :label="$t('fo-email')"
          :value="info.email" @input="updateBuyerInfo('email', $event)"
          @keyup="lowercase"
        ></v-text-field>
      </v-col>
      <v-col v-if="hasPhone" class="py-0">
        <v-text-field
          :value="info.phone"
           @input="updateBuyerInfo('phone', $event)"
          :rules="phoneRules"
          :label="$t('fo-phone')"
          outlined
        ></v-text-field>
      </v-col>
      <v-col v-if="hasPassword" class="py-0">
        <v-text-field
          required
          outlined
          :label="$t('fo-password')"
          :value="info.password"
           @input="updateBuyerInfo('password', $event)"
          :hide-details="hasPasswordConfirmation ? false : undefined"
          :rules="hasPasswordConfirmation ? passwordRules : required"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          placeholder="*********"
          name="input-10-1"
          @click:append="show = !show"
        ></v-text-field>
      </v-col>
      <v-col class="text-right text-xs-center" v-if="hasLink">
        <RouterLink
          :to="`/delivery/${$route.params.slug}/${$route.params.id}/forgot-password`"
          class="text-decoration-none font-weight-bold"
        >
          {{ $t('fo-forgot-password') }}
        </RouterLink>
      </v-col>
      <v-col v-if="hasPasswordConfirmation" class="py-0">
        <v-text-field
          required
          outlined
          :value="info.confirm_password"
           @input="updateBuyerInfo('confirm_password', $event)"
          :label="$t('fo-confirm-password')"
          :rules="passwordRules.concat(passwordConfirmationRule)"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          placeholder="*********"
          name="input-10-2"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" lg="6" sm="auto">
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          x-large
          class="rounded-lg"
          outlined
          width="100%"
          :loading="loading"
          :disabled="valid ? loading : !valid"
          @click="validate"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationRules } from "@/mixins/formValidation";
import { mapState } from 'vuex';
export default {
  mixins: [validationRules],
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    hasName: {
      type: Boolean,
      default: true,
    },
    hasEmail: {
      type: Boolean,
      default: true,
    },
    hasPhone: {
      type: Boolean,
      default: true,
    },
    hasPassword: {
      type: Boolean,
      default: true,
    },
    hasPasswordConfirmation: {
      type: Boolean,
      default: true,
    },
    hasLink: {
      type: Boolean,
      default: true,
    },
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {      
      show: false,
      show2: false,
    };
  },
  computed: {
    ...mapState('buyer', ['info', 'loading', 'valid']),
    passwordConfirmationRule() {
      return () =>
        this.info.password === this.info.confirm_password ||
        this.$t("fo-password-must-match");
    },
    formValidation: {
      get() {
        return this.$store.state.buyer.valid;
      },
      set(value) {
        return this.$store.commit("buyer/setValid", value);
      },
    },
  },
  mounted() {
    this.updateBuyerInfo('store_hash_id', this.$route.params.id);
  },
  methods: {
    updateBuyerInfo(key,value) {
      this.$store.commit("buyer/updateBuyerInfo", {key: key, value});
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("validate", this.info);
      } else {
        this.loading = false;
      }
    },
    lowercase() {
      this.info.email = this.info.email.toLowerCase();
    },
  },
};
</script>

<style>
</style>