<template>
 <v-row justify="center">
  <v-dialog v-model="dialog" persistent fullscreen width="100%"
      hide-overlay
      transition="dialog-bottom-transition">
    <v-card width="100%">
      <v-card-title class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5">
        <v-spacer></v-spacer>
        <v-btn :ripple="false" plain icon color="primary" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
      <v-row align="center">
        <v-col cols="auto">
          <h2>{{ $t("fo-additionals") }}</h2> 
        </v-col>
        <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto" class="ms-auto">
          <v-btn color="primary" width="100%" class="text-caption text-xl-body-1 text-lg-body-1 text-md-body-1" depressed large @click="newAdditionalGroup"
            ><v-icon>mdi-plus</v-icon> {{ $t('fo-additionals-group') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="filteredGroupAdditionals.length > 0">
          <v-row>
            <v-col cols="12" xl="2" lg="3" md="4" sm="6" v-for="(group, index) in filteredGroupAdditionals" :key="index">
              <v-card class="mx-auto" max-width="344">
                <v-card-title>
                  <v-row>
                    <v-col cols="6" xl="7" lg="7" md="7">
                      <span>{{ group.name }}</span> 
                    </v-col>
                    <v-col cols="auto" class="ms-auto">
                      <v-btn
                        icon
                        color="primary"
                        @click.stop="editGroupAdditionals(group)"
                      > <v-icon> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary"
                        @click.stop="deleteItem('additionalGroup', group.hash_id)"
                      ><v-icon> mdi-delete-outline </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle class="font-italic font-weight-medium">
                  {{ group.mandatory == 0 ? $t("fo-optional") : $t("fo-mandatory") }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                  >
                   {{ $t("fo-additionals") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                   <v-btn
                    color="primary"
                    class="text-caption text-xl-body-1 text-lg-body-1 text-md-body-1"
                    small
                    icon
                    depressed
                    @click="newAdditional(group.hash_id)"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                  <v-btn icon @click="group.show = !group.show">
                    <v-icon>{{ group.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="group.show">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row align="center">
                        <v-col cols="12">
                          <v-row dense align="center">
                            <v-col cols="12" v-for="(item, index) in group.complements" :key="index">
                              <v-row dense align="center">
                                <v-col cols="5" class="mr-auto">
                                  {{ item.name }}
                                </v-col>
                                <v-col cols="auto">
                                  {{ $money.formatMoney(store.currency_code, item.price / 100) }}
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn
                                    icon
                                    color="primary"
                                    small
                                    @click="editAdditionals(item)"
                                  >
                                    <v-icon small> mdi-pencil-outline </v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    color="primary"
                                    small
                                    @click="deleteItem('additional', item.hash_id)"
                                  >
                                    <v-icon small> mdi-delete-outline </v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12" class="py-8 justify-items-center">
          <h4>{{ $t('fo-no-additionals-groups-registered') }}</h4>
        </v-col>
        <v-dialog v-model="additionalGroupModal" eager scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600px">
          <v-card class="rounded-lg">
            <v-card-title class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5">
              <span>{{ $t('fo-additionals-group') }}</span>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                plain
                icon
                color="primary"
                @click="additionalGroupModal = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-5">
              <v-form @submit.prevent ref="additionalGroupForm" v-model="validAdditionalGroupForm" lazy-validation>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      :label="$t('fo-additionals-group-name')"
                      @input="updateAdditionalGroupInfo('name', $event)"
                      :value="additionalGroup.name"
                      :rules="required"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      :label="$t('fo-group-description')"
                      @input="updateAdditionalGroupInfo('description', $event)"
                      :value="additionalGroup.description"
                      :rules="required"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      :label="$t('fo-maximum-number-of-selected-items')"
                      @input="updateAdditionalGroupInfo('maximum', $event)"
                      :value="additionalGroup.maximum"
                      :rules="quantityRules"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <h3>{{ $t("fo-is-mandatory") }}</h3>
                    <v-radio-group
                      @change="updateAdditionalGroupInfo('mandatory', $event)"
                      :value="additionalGroup.mandatory"
                      :rules="radioRules"
                      mandatory
                    >
                      <v-radio :label="$t('fo-optional')" :value="0"></v-radio>
                      <v-radio :label="$t('fo-mandatory')" :value="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :ripple="false"
                depressed
                large
                class="rounded-lg"
                @click="validateGroupAdditional"
              >
                {{ $t("fo-save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="additionalModal" eager scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600px">
          <v-card class="rounded-lg">
            <v-card-title class="text-xl-h4 text-lg-h4 text-md-h5 text-sm-h5">
              <span>{{ $t("fo-additional") }}</span>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                plain
                icon
                color="primary"
                @click="additionalModal = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-5">
              <v-form @submit.prevent ref="additionalForm" v-model="validAdditionalForm" lazy-validation>
                <v-row>
                  <v-col cols="12" class="pt-2 pb-0">
                    <v-text-field
                      :label="$t('fo-additional-name')"
                      outlined
                      @input="updateAdditionalInfo('name', $event)"
                      :value="additional.name"
                      :rules="required"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" class="py-0">
                    <v-text-field
                      :label="$t('fo-description')"
                      outlined
                      @input="updateAdditionalInfo('description', $event)"
                      :value="additional.description"
                      :rules="required"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <MoneyInput
                      :label="$t('fo-value')"
                      :inputValue="additional.price"
                      @update="additional.price = $event"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="validateAdditional"
                color="primary"
                :ripple="false"
                depressed
                large
                class="rounded-lg"
                >{{ $t('fo-save') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <DeleteModal @yes="deleteItemConfirm" />
 </v-row>
</template>

<script>
import MoneyInput from "@/components/shared/MoneyInput";
import { validationRules } from "@/mixins/formValidation";
import { mapState } from "vuex";
import DeleteModal from "@/components/owner/DeleteModal";
export default {
  components: {
    MoneyInput,
    DeleteModal
  },
  mixins: [validationRules],
  data() {
    return {
      validAdditionalGroupForm: true,
      validAdditionalForm: true,
      additionalGroupModal: false,
      additionalModal: false,
      show: false,
      id: null,
    };
  },
  methods: {
    updateAdditionalInfo(key, value) {
      this.$store.commit("additional/updateAdditionalInfo", { key: key, value });
    },
    updateAdditionalGroupInfo(key, value) {
      this.$store.commit("additional/updateAdditionalGroupInfo", { key: key, value });
    },
    newAdditional(id) {
      this.$store.commit("additional/resetAdditional");
      this.updateAdditionalInfo("complement_group_hash_id", id);
      this.$refs.additionalForm.resetValidation();
      this.additionalModal = true;
    },
    newAdditionalGroup() {
      this.$store.commit("additional/resetAdditionalGroup");
      this.$refs.additionalGroupForm.resetValidation();
      this.additionalGroupModal = true;
    },
    editGroupAdditionals(group) {
      this.additionalGroupModal = true;
      this.$store.commit("additional/setAdditionalGroup", group);
    },
    editAdditionals(item) {
      this.additionalModal = true;
      this.$store.commit("additional/setAdditional", item);
    },
    validateGroupAdditional() {
      if (this.$refs.additionalGroupForm.validate()) {
        this.saveGroupAdditional();
      }
    },
    validateAdditional() {
      if (this.$refs.additionalForm.validate()) {
        this.saveAdditional();
      }
    },
    saveAdditional() {
      !this.additional.hash_id ? this.$emit("createAdditional", this.additional) : this.$emit("updateAdditional", this.additional);
      this.closeAdditionalModal();
    },
    saveGroupAdditional() {
      !this.additionalGroup.hash_id ? this.$emit("createAdditionalGroup", this.additionalGroup) : this.$emit("updateAdditionalGroup", this.additionalGroup);
      this.closeGroupAdditionalModal();
    },
    closeAdditionalModal() {
      this.additionalModal = false;
      this.$nextTick(() => {
       
      });
    },
    closeGroupAdditionalModal() {
      this.additionalGroupModal = false;
      this.$nextTick(() => {
        
      });
    },
    close() {
      this.dialog = false;
    },
    deleteItemConfirm() {
      this.type == "additionalGroup" ? this.$emit("deleteAdditionalGroup", this.id) : this.$emit("deleteAdditional", this.id);
      this.closeDelete();
    },
    deleteItem(type, id) {
      this.type = type;
      this.id = id;
      this.$nextTick(() => {
        this.$store.commit("toggle/toggleDeleteModal", true);
      });
    },
    closeDelete() {
      this.$store.commit("toggle/toggleDeleteModal", false);
      this.id = null;
    }
  },
  computed: {
    ...mapState("additional", [
      "additional",
      "additionalGroup",
      "additionals",
      "groupAdditionals",
    ]),
     ...mapState("store", ["store"]),
    dialog: {
      get() {
        return this.$store.state.toggle.additionalsModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleAdditionalsModal", value);
      },
    },
     filteredGroupAdditionals() {
      return (
        !this.groupAdditionals || this.groupAdditionals.map((group) => {
          this.$set(group, "show", false);
          return group;
        })
      );
    },
  },
};
</script>

<style>
</style>