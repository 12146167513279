<template>
  <v-container>
      <v-row justify="center" align="center">
          <v-col cols="auto" class="text-heading-1 primary--text text-uppercase">
              {{ $t('fo-page-not-font') }}
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>