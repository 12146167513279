import { render, staticRenderFns } from "./CustomDrawer.vue?vue&type=template&id=662fb162&scoped=true&"
import script from "./CustomDrawer.vue?vue&type=script&lang=js&"
export * from "./CustomDrawer.vue?vue&type=script&lang=js&"
import style0 from "./CustomDrawer.vue?vue&type=style&index=0&id=662fb162&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662fb162",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
