// initial state
const initialState = () => ({
  info: {
    id: null,
    name: null,
    price: null,
    description: null,
    hash_id: null,
    // store_hash_id: null,
    image: null,
    image_url: null,
    active: null,
    category_hash_id: null,
    complement_groups: null
  },
  products: [],
  buttonLoading: false
});

export const state = initialState;

// mutations
const mutations = {
  setProduct(state, payload) {
    state.info.id = payload.id
    state.info.name = payload.name
    state.info.price = payload.price
    state.info.hash_id = payload.hash_id
    state.info.description = payload.description
    state.info.store_hash_id = payload.store_hash_id
    state.info.image = payload.image
    state.info.image_url = payload.image_url
    state.info.active = payload.active
    state.info.category_hash_id = payload.category_hash_id
    state.info.complement_groups = payload.complement_groups
  },
  setProducts(state, payload) {
    state.products = payload
  },
  setButtonLoading(state, payload) {
    state.buttonLoading = payload;
  },
  cleanCheckedComplementGroups(state) {
    if (state.info.complement_groups) {
      state.info.complement_groups.map((complement) => {
        complement.checked ? complement.checked.splice(0) : '';
      })
    }
  },
  updateProductInfo(state, { key, value }) {
    state.info[key] = value;
  },
  reset(state) {
    Object.assign(state, initialState())
  }
}

const actions = {
  setProduct: ({ commit }, payload) => {
    return new Promise(() => {
      commit('setProduct', payload);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}