
// initial state
const initialState = () => ({
  user: null,
  token: null,
});

const state = initialState;

//getters
const getters = {
  token: state => {
    return state.token;
  },
  user: state => {
    return state.user;
  }
}

// mutations
const mutations = {
  login (state, token) {
    state.token = token;
    
    if (!token){
      state.user = null;
      return;
    }
  },
  logout(state) {
    state.user = null,
    state.token = null
  },
  reset(state) {
    Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}