import client from './Clients/AxiosClient';
const resource = '/category';

export default {
    get() {
        return client.get(`${resource}/all`);
    },
    getCategoryById(id) {
        return client.get(`${resource}/${id}`);
    },
    create(id, payload) {
        return client.post(`${resource}/${id}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
    getCategoryItems(id) {
        return client.get(`${resource}/${id}/items`);
    },
};