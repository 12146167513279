<template>
  <SharedBuyer v-if="this.$store.state.store.store.hash_id">
    <template v-slot="{ getBuyersByStore }">
      <OwnerCustomers @getBuyersByStore="getBuyersByStore" />
    </template>
  </SharedBuyer>
</template>

<script>
import SharedBuyer from "@/components/shared/Buyer"
import OwnerCustomers from "@/components/owner/Customers";
export default {
  components: {
    SharedBuyer,
    OwnerCustomers
  },
};
</script>

<style>
</style>