<template>
  <v-container class="px-4 py-10">
    <v-row>
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">{{ $t("fo-categories") }}</div>
            <div class="text-body-1 font-weight-black secondary--text text--darken-4">{{ $t('fo-create-and-update-categories') }}</div></v-col
          >
          <v-col  cols="12" xl="auto" lg="auto" md="auto" sm="auto">
            <v-btn
              color="primary"
              :ripple="false"
              depressed
              width="100%"
              large
              class="rounded"
              @click="openCategoryDialog"
              >{{ $t("fo-new-category") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat class="d-flex flex-column rounded-lg" min-height="450">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="categories"
              disable-filtering
              disable-sort
              :loading="loading"
              :loading-text="$t('fo-loading-categories')"
              :no-data-text="$t('fo-no-registered-categories')"
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
            >
            <template #[`item.active`]="{ item }">
                <v-chip class="ma-2" x-small color="primary">
                  {{ item.active  == 1 ? $t('fo-active') : $t('fo-inactive') }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="me-2"
                  color="primary"
                  @click="editItem(item.hash_id)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon small color="primary" @click="deleteItem(item.hash_id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              v-model="page"
              :length="pageCount"
              class="elevation-0"
            ></v-pagination>
          </v-card-actions>
        </v-card>
        <CategoryModal @createCategory="$emit('createCategory', info)" @updateCategory="$emit('updateCategory', info)" />
        <DeleteModal @yes="deleteItemConfirm" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
import CategoryModal from "@/components/owner/CategoryModal";
import DeleteModal from "@/components/owner/DeleteModal";
export default {
  mixins: [validationRules],
  components: {
    CategoryModal,
    DeleteModal
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 8,
      headers: [
        {
          text: this.$t("fo-category"),
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: this.$t("fo-state"),
          value: "active",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("fo-actions"),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      id: null,
    };
  },
  computed: {
    ...mapState("category", ["info", "categories", "loading"])
  },
  methods: {
    updateCategoryInfo(key, value) {
      this.$store.commit("category/updateCategoryInfo", { key: key, value });
    },
    openCategoryDialog() {
      this.$store.commit("category/resetCategory");
      this.$store.commit("toggle/toggleCategoryModal", true);
    },
    editItem(id) {
      this.$emit("getCategory", id);
      this.$nextTick(() => {
        this.$store.commit("toggle/toggleCategoryModal", true);
      });
    },
    deleteItem(id) {
      this.id = id;
      this.$nextTick(() => {
        this.$store.commit("toggle/toggleDeleteModal", true);
      });
    },
    deleteItemConfirm() {
      this.$emit("deleteCategory", this.id);
      this.closeDelete();
    },
    closeDelete() {
      this.$store.commit("toggle/toggleDeleteModal", false);
    }
  },
};
</script>

<style>
</style>