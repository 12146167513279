<template>
  <v-container class="fill-height">
    <Alert />
    <v-row class="my-auto">
      <v-col cols="6" v-if="$vuetify.breakpoint.smAndUp">
        <v-card flat max-width="600" class="mx-auto">
          <CartImage />
        </v-card>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="6">
        <v-card class="rounded-xl pa-5" height="100%">
          <v-row class="fill-height" align="center">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <span class="primary--text text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 text-h5 font-weight-bold"
                  >{{ $t("fo-register") }}</span
                  >
                </v-col>
                <v-col cols="12">
                   <Authentication>
                    <template v-slot="{ register }">
                      <AuthenticationForm @validate="register" :hasLink="false" :buttonText="$t('fo-register')" />
                    </template>
                  </Authentication>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/shared/Alert";
import AuthenticationForm from "@/components/shop/default/AuthenticationForm";
import Authentication from "@/components/shared/Authentication";
import CartImage from "@/components/shared/CartImage";
export default {
  components: {
    AuthenticationForm,
    Authentication,
    Alert,
    CartImage
  },
};
</script>

<style>
</style>