<template>
  <v-container class="fill-height py-16">
    <v-row align="center" justify="center" v-if="step == 0">
      <v-col cols="auto">
        <v-row justify="center" align="center" class="flex-column">
          <v-col cols="auto">
            <v-row dense align="center">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  icon
                  class="text-none"
                  @click="setOrderStep(0)"
                >
                  <v-icon> mdi-chevron-left </v-icon></v-btn
                >
              </v-col>
              <v-col cols="auto" class="text-h6 font-weight-black">
                {{ $t("fo-choose-how-you-want-to-pay") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="this.store.owner_gateway" cols="auto">
            <v-item-group active-class="white">
              <VueHorizontal
                responsive
                :scroll="$vuetify.breakpoint.mdAndDown"
                :button="false"
                class="horizontal"
              >
                <v-item
                  v-for="(payment, index) in this.store.owner_gateway.config.payment_methods"
                  :key="index"
                  v-slot="{ active, toggle }"
                  active-class="white"
                  class="my-5"
                >
                  <v-card
                    :flat="!active"
                    :outlined="!active"
                    class="ma-4 rounded-xl d-flex align-items-center"
                    height="130"
                    width="130"
                    @click="setPaymentMethod(payment)"
                    @click.native="toggle"
                  >
                    <v-scale-transition>
                      <v-icon
                        style="position: absolute; margin-left: 115px"
                        class="mt-n2"
                        v-if="active"
                        right
                        color="primary"
                        size="24"
                        >mdi-check-circle</v-icon
                      >
                    </v-scale-transition>
                    <v-row
                      class="fill-height flex-column"
                      align="center"
                      justify="center"
                      dense
                    >
                      <v-col cols="auto">
                        <v-icon x-large :color="active ? 'primary' : undefined">
                          {{ `$${payment.toLowerCase()}` }}</v-icon
                        >
                      </v-col>
                      <v-col cols="auto" class="text-body-2">
                        {{ $t("fo-" + payment.toLowerCase().replace("_", "-")) }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-item>
              </VueHorizontal>
            </v-item-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="flex-column" v-else>
      <v-col cols="12" xl="5" lg="5" md="5">
        <v-row align="center" justify="start">
          <v-col cols="12">
            <v-row dense align="center">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  icon
                  class="text-none"
                  @click="setPaymentStep(0)"
                >
                  <v-icon> mdi-chevron-left </v-icon></v-btn
                >
              </v-col>
              <v-col cols="auto">
                {{ $t( "fo-" + order.payment.payment_method.toLowerCase().replace("_", "-")) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="store.gateway.name == 'Stripe'">
            <Stripe />
          </v-col>
          <v-col cols="12" v-else>
            <Pagarme />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import VueHorizontal from "vue-horizontal";
import Stripe from "@/components/shop/default/PaymentForms/Stripe";
import Pagarme from "@/components/shop/default/PaymentForms/Pagarme";
export default {
  components: {
    VueHorizontal,
    Stripe,
    Pagarme,
  },
  computed: {
    ...mapState("store", ["store"]),
    ...mapState("payment", ["step"]),
    ...mapState("order", ["order"]),
  },
  methods: {
    setPaymentMethod(payment) {
      this.setPaymentStep(1);
      this.$store.commit("order/updatePayment", { key: "payment_method", value: payment });
    },
    setOrderStep(value) {
      this.$store.commit("order/setStep", value);
    },
    setPaymentStep(value) {
      this.$store.commit("payment/setStep", value);
    },
  },
};
</script>

<style>
</style>