<template>
  <v-navigation-drawer
    class="elevation-3"
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '229'"
    color="white"
    v-model="ownerNavigation"
    :permanent="$vuetify.breakpoint.smAndUp"
    :mini-variant="ownerNavigation == false && !$vuetify.breakpoint.xsOnly"
    mini-variant-width="70"
    :right="$vuetify.rtl"
    app
    :clipped="!$vuetify.breakpoint.xsOnly"
  >
    <v-list color="primary" v-if="$vuetify.breakpoint.xsOnly">
      <v-list-item class="px-2">
        <v-btn
          class="ms-auto"
          color="white"
          icon
          @click.stop="ownerNavigation = !ownerNavigation"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item link v-if="$auth.user()">
        <v-list-item-content>
          <v-list-item-title class="text-h6 white--text">
            {{ `${$t("fo-hello")} ${$auth.user().name}` }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text">{{
            $auth.user().email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list class="py-0">
      <v-list-item
        :to="item.link"
        v-for="item in items"
        :key="item.title"
        link
        color="primary"
      >
        <v-list-item-icon>
          <v-icon color="secondary-darken-4">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="secondary--text text--darken-4">{{
            $t(item.title)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "fo-dashboard",
          icon: "mdi-view-dashboard",
          link: "/owner/dashboard",
        },
        { title: "fo-store", icon: "mdi-store", link: "/owner/store" },
        {
          title: "fo-categories",
          icon: "mdi-shape",
          link: "/owner/categories",
        },
        {
          title: "fo-products",
          icon: "mdi-shopping",
          link: "/owner/products",
        },
        {
          title: 'fo-domains',
          icon: "mdi-transit-connection-variant",
          link: "/owner/domain",
        },
        { title: "fo-orders", icon: "mdi-pencil", link: "/owner/orders" },
        { title: "fo-customers", icon: "mdi-account", link: "/owner/customers" },
        {
          title: "fo-gateways",
          icon: "mdi-credit-card-settings-outline",
          link: "/owner/gateway",
        },
      ],
    };
  },
  computed: {
    ownerNavigation: {
      get() {
        return this.$store.state.toggle.ownerNavigation;
      },
      set(value) {
        return this.$store.commit("toggle/toggleOwnerNavigation", value);
      },
    },
  },
};
</script>

<style>
</style>