import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Shop from '@/views/Shop'
import Home from '@/views/Shop/home'
import Redirect from '@/views/Shop/redirect'
import Account from '@/views/Shop/Account'
import AccountOrders from '@/views/Shop/Account/orders'
import Profile from '@/views/Shop/Account/profile'
import Login from '@/views/Shop/login'
import ForgotPassword from '@/views/Shop/forgot-password'
import RecoverPassword from '@/views/Shop/recover-password'
import Register from '@/views/Shop/register'
import Counter from '@/views/Shop/Counter'
import Owner from '@/views/Owner'
import Categories from '@/views/Owner/categories'
import Products from '@/views/Owner/products'
import Product from '@/views/Owner/product'
import Orders from '@/views/Owner/orders'
import Store from '@/views/Owner/store'
import Domain from '@/views/Owner/domain'
import Gateway from '@/views/Owner/gateway'
import Customers from '@/views/Owner/customers'
import Dashboard from '@/views/Owner/dashboard'
import OwnerAuth from '@/views/Owner/authentication'
import Page404 from '@/components/shared/Page404'


Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: Redirect,
  },
  {
    path: '/delivery/:slug/:id', component: Shop,
    children: [
      { path: '', component: Home },
      { 
        path: 'login', 
        component: Login,
        meta: {
          hideForAuth: true
        }, 
      },
      { 
        path: 'forgot-password', 
        component: ForgotPassword,
        meta: {
          hideForAuth: true
        }, 
      },
      { 
        path: 'recover-password', 
        component: RecoverPassword,
        meta: {
          hideForAuth: true
        }, 
      },
      { 
        path: 'register', 
        component: Register,
        meta: {
          hideForAuth: true
        }, 
      },
      { path: 'counter', component: Counter },
      {
        path: 'account',
        component: Account,
        meta: {
          requiresAuth: true
        },
        beforeEnter(to, from, next) {
          if (Vue.prototype.$auth.getScope().includes('buyer')) {
            next();
          } else {
            next({ path: `/delivery/${to.params.slug}/${to.params.id}` });
          }
        },
        children: [
          { path: 'orders', component: AccountOrders },
          { path: 'profile', component: Profile },
        ]
      },
    ],
  },
  {
    path: '/owner', 
    component: Owner,
    meta: {
      requiresAuth: true
    },
    async beforeEnter(to, from, next) {
      if (Vue.prototype.$auth.getScope().includes('owner')) {
        next();
      } 
      else {
        next({ path: `/delivery/${to.params.slug}/${to.params.id}` });
      }
    },
    children: [
      { path: 'dashboard', component: Dashboard },
      { path: 'categories', component: Categories },
      { path: 'products', component: Products },
      { path: 'products/:productId', component: Product },
      { path: 'orders', component: Orders },
      { path: 'store', component: Store },
      { path: 'domain', component: Domain },
      { path: 'gateway', component: Gateway },
      { path: 'customers', component: Customers }

    ]
  },
  {
    path: '/auth', 
    component: OwnerAuth
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404
  },  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isHide = to.matched.some((record) => record.meta.hideForAuth);

  if (Vue.prototype.$auth.ownerHasStore() == false && to.path != '/owner/store') {
    store.commit('toggle/toggleRedirectModal', true)
  } else {
    next();
  }
  
  if (isAuth && Vue.prototype.$auth.isTokenExpired()) {
    if (to.matched[0].path == '/owner') {
      window.location.href = process.env.VUE_APP_OFFICE_URL;
    } else {
      next({ path: `/delivery/${to.params.slug}/${to.params.id}/login` });
    }
  } else if (isHide &&  !Vue.prototype.$auth.isTokenExpired()) {
    next({ path: `/delivery/${to.params.slug}/${to.params.id}` });
  }
  next();
})


export default router