<template>
  <v-app-bar flat height="80" color="secondary" app>
    <v-container class="fill-height">
      <v-row align="center" justify="start">
        <v-col cols="auto" class="me-auto" v-if="$vuetify.breakpoint.smAndUp">
          <v-row align="center" class="fill-height">
            <v-col cols="auto" v-if="store.logo_url" class="pa-0">
              <v-img :src="store.logo_url" max-height="70" max-width="90" contain></v-img>
            </v-col>
            <v-col v-if="store.name" cols="auto" class="font-weight-black text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6">
                {{ store.name }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="ms-auto px-0" v-if="($auth.isLoggedIn() && $auth.getScope().includes('buyer')) || ($vuetify.breakpoint.xsOnly  && !$auth.isLoggedIn())">
          <v-row align="center" dense>
            <v-col cols="auto" v-if="this.$auth.user() && !$vuetify.breakpoint.xsOnly">
              {{ this.$auth.user().name }}
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                color="primary"
                depressed
                :ripple="false"
                large
                @click.stop="navigation = !navigation"
                ><v-icon>mdi-account-outline</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="px-0" v-if="!$auth.isLoggedIn()">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            :to="`/delivery/${$route.params.slug}/${$route.params.id}/login`"
            color="primary"
            :ripple="false"
            depressed
            large
            class="rounded-lg me-2"
            >{{ $t("fo-login") }}</v-btn
          >
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            :to="`/delivery/${$route.params.slug}/${$route.params.id}/register`"
            color="primary"
            :ripple="false"
            depressed
            large
            class="rounded-lg"
            outlined
            plain
            >{{ $t("fo-register") }}</v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="cart.items.length"
            color="primary"
            class="rounded-xl"
            :ripple="false"
            depressed
            large
            @click.stop="cartDrawer = !cartDrawer"
          >
            <v-icon color="secondary" class="me-2">$cart</v-icon>
            {{ store. currency_code ? $money.formatMoney(store.currency_code, total) : "" }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            :ripple="false"
            depressed
            icon
            @click.stop="cartDrawer = !cartDrawer"
          >
            <v-icon color="primary">$cart</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
export default {
  methods: {
    ...mapMutations({
      toggleDrawer: "toggle/toggleDrawer",
      toggleUserSideNavigation: "toggle/toggleUserNavigation",
    }),
  },
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapState("store", ["store"]),
    totalOrderItems() {
      if(this.cart.items.length > 0){
      let total = this.cart.items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
      return total/100 }
      else { return 0 }
    },
    totalComplementsItems() {
      const total = this.cart.items.reduce((result, item) => {
        return result + (item.quantity * item.cart_complements.reduce((a, complement) => a + complement.price, 0));
      }, 0);
      return total/100
    },
    total() {
      return this.totalOrderItems + this.totalComplementsItems
    },
    cartDrawer: {
      get() {
        return this.$store.state.toggle.cartDrawer;
      },
      set(value) {
        return this.$store.commit("toggle/toggleCartDrawer", value);
      },
    },
    navigation: {
      get() {
        return this.$store.state.toggle.userNavigation;
      },
      set(value) {
        return this.$store.commit("toggle/toggleUserNavigation", value);
      },
    },
  },
};
</script>

<style>
</style>