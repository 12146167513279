
// initial state
const initialState = () => ({
    info: {
      hash_id: "",
      description: "",
      status: "",
      store_hash_id: null,
      allowsToDelete: null
    },
    status: [],
    loading: false
  });
  
const state = initialState;

  //getters
  const getters = {
 
  }
  
  // mutations
  const mutations = {
    updateStoreStatusInfo(state, { key, value }) {
      state.info[key] = value;
    },
    setStatus(state, payload) {
      state.status = payload
    },
    setStoreStatus(state, payload) {
      state.info.hash_id = payload.hash_id
      state.info.description = payload.description
      state.info.status = payload.status
      state.info.store_hash_id = payload.store_hash_id
      state.info.allowsToDelete = payload.allowsToDelete
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    resetStatus(state) {
      state.info.hash_id = "",
      state.info.description = "",
      state.info.status = "",
      state.info.store_hash_id = null
    },
    reset(state) {
        Object.assign(state, initialState())
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  }