<template>
  <component :is="layout">
    <component :is="template" />
  </component>
</template>

<script>
import HomeDefault from '@/components/shop/default'
import DefaultLayout from "@/layouts/default";

export default {
  components: {
    HomeDefault,
    DefaultLayout
  },
   data: () => ({
    user: {
      template: 'default'
    },
  }),
  computed: {
    template() {
      if(this.user.template == 'default') {
        return 'HomeDefault'
      } else {
        return 'HomeNewTemplate'
      }
    },
     layout() {
      if (this.user.template == "default") {
        return "DefaultLayout";
      } else {
        return "NewLayout";
      }
    },
  }
}
</script>

<style>

</style>