import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import TicketIcon from '@/components/shared/icons/TicketIcon.vue'
import AddCartIcon from '@/components/shared/icons/AddCartIcon.vue'
import CalendarIcon from '@/components/shared/icons/CalendarIcon.vue'
import CartIcon from '@/components/shared/icons/CartIcon.vue'
import CashIcon from '@/components/shared/icons/CashIcon.vue'
import CouponIcon from '@/components/shared/icons/CouponIcon.vue'
import CreditCardIcon from '@/components/shared/icons/CreditCardIcon.vue'
import MapMarkerIcon from '@/components/shared/icons/MapMarkerIcon.vue'
import PixIcon from '@/components/shared/icons/PixIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary:{
          base: '#006CFF',
          // darken1: '#006FE6',
          // lighten1: '#1A7BFF',
          // lighten2: '#3389FF',
          // lighten3: '#4D98FF'
        },
        secondary: {
          base: '#F3F3F3',
          darken1: '#DADADA',
          darken2: '#B4B4B4',
          darken3: '#8F8F8F',
          darken4: '#696969',
        },
        tertiary: {
          base: '#7800FF',
          lighten1: '#9333FF',
          lighten2: '#AE66FF',
          lighten3: '#C999FF',
          lighten4: '#E4CCFF'
        },
        background: '#E9EDF2',
        muted: '#F3F5F8',
        warning: '#FFC000',
        success: '#63AD0E',
        error: '#FF003C'
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
    values: {
      ticket: { component: TicketIcon },
      add_cart: { component: AddCartIcon },
      calendar: { component: CalendarIcon },
      cart: { component: CartIcon },
      cash: { component: CashIcon },
      coupon: { component: CouponIcon },
      credit_card: { component: CreditCardIcon },
      debit_card: { component: CreditCardIcon },
      mapmarker: { component: MapMarkerIcon },
      pix: { component: PixIcon }
    },
  },
});
