<template>
  <SharedOrder v-if="this.$store.state.store.store.hash_id">
    <template v-slot="{ getOrders, updateOrderStatus }">
      <OwnerOrders @getOrders="getOrders" @updateOrderStatus="updateOrderStatus" />
    </template>
  </SharedOrder>
</template>

<script>
import SharedOrder from "@/components/shared/Order";
import OwnerOrders from "@/components/owner/Orders";
export default {
  components: {
    SharedOrder,
    OwnerOrders
  }
};
</script>

<style>
</style>