<template>
    <component :is="template" />
</template>

<script>
import RegisterDefault from '@/components/shop/default/Register'
export default {
  components: {
    RegisterDefault
  },
   data: () => ({
    user: {
      template: 'default'
    },
  }),
  computed: {
    template() {
      if(this.user.template == 'default') {
        return 'RegisterDefault'
      } else {
        return 'RegisterNewTemplate'
      }
    }
  }
}
</script>

<style>

</style>