<template>
    <SharedProducts v-if="this.$store.state.store.store.hash_id">
      <template v-slot="{ createProduct, updateProduct, getProduct, detachComplementGroup, attachComplementGroup }">
        <OwnerProduct @createProduct="createProduct" @updateProduct="updateProduct" @getProduct="getProduct" @detachComplementGroup="detachComplementGroup" @attachComplementGroup="attachComplementGroup" />
      </template>
    </SharedProducts>
</template>

<script>
import SharedProducts from "@/components/shared/Products";
import OwnerProduct from "@/components/owner/Product";
export default {
  components: {
    OwnerProduct,
    SharedProducts,
  }
};
</script>

<style>
</style>