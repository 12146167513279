
// initial state
const initialState = () => ({
    token: null,
    user: null
});

const state = initialState;

//getters
const getters = {
    token: state => {
        return state.token;
    },
    user: state => {
        return state.user;
    }
}

// mutations
const mutations = {
    setUser(state, payload) {
        state.user = payload
    },
    reset(state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}