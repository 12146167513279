<template>
  <v-tooltip bottom v-model="copied" :open-on-hover="false">
    <template v-slot:activator="{ on, attrs }">
      <v-row dense>
        <v-col>
          <v-text-field
            v-on="on"
            v-bind="attrs"
            flat
            dense
            :append-icon="!hasButton ? 'mdi-content-copy' : null"
            @click:append="copyLink"
            v-model="link"
            ref="contentToCopy"
            full-width
            outlined
            readonly
            hide-details
          ></v-text-field>
        </v-col>
        <v-col v-if="hasButton" cols="12" xl="auto" lg="auto" md="auto" sm="auto">
          <v-btn color="primary" :height="$vuetify.breakpoint.smAndUp ? '100%' : null" width="100%" large depressed @click="copyLink">
            {{ $t("fo-copy-link") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <span> {{ $t("fo-copied-link") }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
    },
    hasButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      copied: false,
    };
  },

  watch: {
    copied() {
      setTimeout(() => (this.copied = false), 5000);
    },
  },
  methods: {
    copyLink() {
      let contentToCopy = this.$refs.contentToCopy.$el.querySelector("input");
      contentToCopy.select();
      document.execCommand("copy");
      this.copied = true;
    },
  },
};
</script>

<style>
.input-link.v-text-field input {
  cursor: pointer !important;
}
.input-link.v-text-field.v-text-field--enclosed.v-text-field--single-line
  .v-text-field__suffix {
  cursor: pointer !important;
}
</style>