<template>
  <svg class="custom-icon" width="73" height="54" viewBox="0 0 73 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.6449 0H54.4301C53.4634 0 52.687 0.773913 52.687 1.73773C52.687 2.70155 53.4633 3.47546 54.4301 3.47546H68.9018V17.9027C68.9018 18.8664 69.6781 19.6404 70.6449 19.6404C71.6117 19.6404 72.388 18.8665 72.388 17.9027V1.73773C72.4027 0.788534 71.6117 0 70.6449 0Z"/>
    <path d="M17.9579 0H1.74309C0.776383 0 0 0.773913 0 1.73773V17.9027C0 18.8664 0.776301 19.6404 1.74309 19.6404C2.70989 19.6404 3.48619 18.8665 3.48619 17.9027V3.47546H17.9579C18.9246 3.47546 19.701 2.70155 19.701 1.73773C19.701 0.773913 18.91 0 17.9579 0Z"/>
    <path d="M70.6449 34.3596C69.6782 34.3596 68.9018 35.1335 68.9018 36.0974V50.5246H54.4301C53.4634 50.5246 52.687 51.2985 52.687 52.2623C52.687 53.2261 53.4633 54 54.4301 54H70.6449C71.6116 54 72.388 53.2261 72.388 52.2623V36.0974C72.4027 35.1336 71.6117 34.3596 70.6449 34.3596Z"/>
    <path d="M17.9579 50.5246H3.48619V36.0974C3.48619 35.1336 2.70989 34.3596 1.74309 34.3596C0.776301 34.3596 0 35.1335 0 36.0974V52.2623C0 53.226 0.776301 54 1.74309 54H17.9579C18.9246 54 19.701 53.2261 19.701 52.2623C19.701 51.2985 18.91 50.5246 17.9579 50.5246Z"/>
    <path d="M60.2308 39.6894V14.3104C60.2308 13.3466 59.4545 12.5726 58.4877 12.5726C57.5209 12.5726 56.7446 13.3465 56.7446 14.3104V39.6748C56.7446 40.6386 57.5209 41.4126 58.4877 41.4126C59.4545 41.4126 60.2308 40.6386 60.2308 39.6894Z"/>
    <path d="M49.5671 12.5728C48.6004 12.5728 47.824 13.3467 47.824 14.3105V36.0828C47.824 37.0465 48.6003 37.8205 49.5671 37.8205C50.5339 37.8205 51.3102 37.0466 51.3102 36.0828V14.3105C51.3102 13.3613 50.5339 12.5728 49.5671 12.5728Z"/>
    <path d="M40.6469 12.5728C39.6802 12.5728 38.9038 13.3467 38.9038 14.3105V36.0828C38.9038 37.0465 39.6801 37.8205 40.6469 37.8205C41.6137 37.8205 42.39 37.0466 42.39 36.0828V14.3105C42.4047 13.3613 41.6137 12.5728 40.6469 12.5728Z"/>
    <path d="M31.7411 12.5728C30.7744 12.5728 29.998 13.3467 29.998 14.3105V36.0828C29.998 37.0465 30.7743 37.8205 31.7411 37.8205C32.7079 37.8205 33.4842 37.0466 33.4842 36.0828V14.3105C33.4842 13.3613 32.6933 12.5728 31.7411 12.5728Z"/>
    <path d="M22.821 12.5728C21.8543 12.5728 21.0779 13.3467 21.0779 14.3105V36.0828C21.0779 37.0465 21.8542 37.8205 22.821 37.8205C23.7878 37.8205 24.5641 37.0466 24.5641 36.0828V14.3105C24.5641 13.3613 23.7731 12.5728 22.821 12.5728Z"/>
    <path d="M15.6434 39.6894V14.3104C15.6434 13.3466 14.8671 12.5726 13.9003 12.5726C12.9335 12.5726 12.1572 13.3465 12.1572 14.3104V39.6748C12.1572 40.6386 12.9335 41.4126 13.9003 41.4126C14.8671 41.4126 15.6434 40.6386 15.6434 39.6894Z"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
  .custom-icon {
    fill: currentColor;
  }
</style>