<template>
  <SharedDomain v-if="this.$store.state.store.store.hash_id">
    <template v-slot="{ connectDomain, disconnectDomain, isConnectedDomain }">
      <OwnerDomain @connectDomain="connectDomain" @disconnectDomain="disconnectDomain" @isConnectedDomain="isConnectedDomain" />
    </template>
  </SharedDomain>
</template>

<script>
import SharedDomain from "@/components/shared/Domain";
import OwnerDomain from "@/components/owner/Domain";
export default {
  components: {
    SharedDomain,
    OwnerDomain,
  },
};
</script>

<style>
</style>