<template>
  <svg viewBox="0 0 366 359" fill="curentColor" class="custom-cart-image custom-width" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_205_21)">
    <path d="M245.409 155.24L241.534 164.301L217.765 195.886C217.765 195.886 201.489 213.49 210.532 214.526C219.574 215.561 225.516 198.216 225.516 198.216L253.418 170.514L256.518 157.829L245.409 155.24Z" fill="#9F616A"/>
    <path d="M257.293 118.478L253.548 119.528C253.548 119.528 236.732 164.292 238.283 169.729C239.833 175.166 246.808 181.897 249.908 181.379C253.008 180.861 257.293 118.478 257.293 118.478Z"/>
    <path d="M309.62 348.523C309.62 348.523 307.975 356.781 312.918 353.476C317.861 350.171 320.438 343.78 320.438 343.78L309.62 348.523Z" fill="#2F2E41"/>
    <path d="M251.093 162.23C251.093 162.23 198.648 347.595 217.249 353.032C235.85 358.468 263.235 352.514 262.977 348.889C262.719 345.265 258.068 273.553 258.068 273.553C258.068 273.553 285.195 368.824 318.781 346.559C352.366 324.295 344.874 320.153 341.774 318.599C338.674 317.046 291.912 243.263 292.171 229.541C292.429 215.82 291.654 172.845 285.97 166.372L251.093 162.23Z" fill="#2F2E41"/>
    <path d="M366.781 359H48.4884V357.788H367L366.781 359Z" fill="#3F3D56"/>
    <path d="M284.404 96.3253C282.386 94.4964 281.127 91.9744 280.877 89.2594C280.628 86.5443 281.405 83.8344 283.056 81.6667C282.837 81.872 282.624 82.0851 282.419 82.3117C280.492 84.4511 279.491 87.2701 279.636 90.1489C279.782 93.0277 281.062 95.7308 283.196 97.664C285.329 99.5972 288.142 100.602 291.015 100.458C293.888 100.314 296.586 99.0333 298.517 96.8965C298.721 96.6699 298.911 96.4356 299.093 96.1972C297.107 98.0605 294.494 99.1078 291.773 99.1315C289.053 99.1552 286.422 98.1536 284.404 96.3253Z" fill="#2F2E41"/>
    <path d="M283.386 98.9319C295.729 98.9319 305.734 88.9058 305.734 76.538C305.734 64.1701 295.729 54.144 283.386 54.144C271.044 54.144 261.039 64.1701 261.039 76.538C261.039 88.9058 271.044 98.9319 283.386 98.9319Z" fill="#2F2E41"/>
    <path d="M223.191 352.255C223.191 352.255 217.249 358.209 223.191 358.209C229.133 358.209 234.817 354.326 234.817 354.326L223.191 352.255Z" fill="#2F2E41"/>
    <path d="M269.178 90.5179C269.178 90.5179 273.053 106.569 270.211 109.676C267.369 112.782 261.169 115.112 261.169 115.112L262.202 144.108C262.202 144.108 274.086 139.966 274.603 139.448C275.12 138.93 285.712 122.102 285.712 122.102L296.046 112.265C296.046 112.265 280.287 104.757 284.937 91.8124C289.587 78.8679 269.178 90.5179 269.178 90.5179Z" fill="#9F616A"/>
    <path d="M291.997 85.1665C293.185 77.8275 288.212 70.9126 280.888 69.7217C273.564 68.5307 266.664 73.5147 265.475 80.8537C264.287 88.1927 269.261 95.1075 276.584 96.2985C283.908 97.4895 290.808 92.5055 291.997 85.1665Z" fill="#9F616A"/>
    <path d="M285.97 166.372C285.97 166.372 291.912 146.697 294.754 144.108C297.596 141.519 304.571 117.183 304.571 117.183C304.571 117.183 300.179 111.229 296.562 111.229C292.946 111.229 289.587 112.523 285.453 116.407C283.507 118.236 279.956 120.248 276.744 121.865C275.604 122.449 274.36 122.799 273.083 122.895C271.807 122.991 270.524 122.832 269.31 122.426C268.095 122.019 266.974 121.375 266.011 120.53C265.048 119.684 264.263 118.655 263.701 117.503C263.059 116.135 262.848 114.825 263.494 113.818C263.494 113.818 253.935 116.407 253.418 120.031C252.901 123.656 253.418 131.163 252.643 132.717C251.868 134.27 249.543 136.6 250.576 146.438C251.609 156.276 249.497 168.716 249.497 168.716C259.148 177.944 272.518 172.248 285.97 166.372Z" fill="currentColor"/>
    <path d="M278.607 78.7384C284.885 78.7384 289.975 75.2612 289.975 70.9718C289.975 66.6823 284.885 63.2051 278.607 63.2051C272.329 63.2051 267.24 66.6823 267.24 70.9718C267.24 75.2612 272.329 78.7384 278.607 78.7384Z" fill="#2F2E41"/>
    <path d="M297.983 70.454C303.976 70.454 308.834 65.5858 308.834 59.5806C308.834 53.5754 303.976 48.7073 297.983 48.7073C291.991 48.7073 287.133 53.5754 287.133 59.5806C287.133 65.5858 291.991 70.454 297.983 70.454Z" fill="#2F2E41"/>
    <path d="M305.734 67.3474C303.013 67.347 300.392 66.3221 298.39 64.476C296.387 62.6299 295.15 60.0972 294.924 57.3801C294.899 57.6792 294.883 57.9808 294.883 58.2862C294.884 61.169 296.027 63.9335 298.06 65.9722C300.094 68.0108 302.853 69.1567 305.73 69.158C308.606 69.1592 311.366 68.0156 313.401 65.9787C315.437 63.9418 316.582 61.1782 316.585 58.2955V58.2862C316.585 57.9808 316.569 57.6792 316.544 57.3801C316.318 60.0972 315.081 62.6299 313.079 64.476C311.076 66.3221 308.455 67.347 305.734 67.3474Z" fill="#2F2E41"/>
    <path d="M280.285 233.645H276.675L269.462 296.694L293.253 295.893L280.285 233.645Z"/>
    <path opacity="0.2" d="M280.285 233.645H276.675L269.462 296.694L293.253 295.893L280.285 233.645Z" fill="black"/>
    <path d="M279.081 232.839L274.673 296.293L328.67 300.31L333.078 238.461L279.081 232.839Z"/>
    <path opacity="0.2" d="M297.261 239.267C297.705 239.267 298.065 238.907 298.065 238.462C298.065 238.017 297.705 237.656 297.261 237.656C296.817 237.656 296.457 238.017 296.457 238.462C296.457 238.907 296.817 239.267 297.261 239.267Z" fill="black"/>
    <path opacity="0.2" d="M314.094 241.273C314.538 241.273 314.898 240.912 314.898 240.467C314.898 240.022 314.538 239.662 314.094 239.662C313.65 239.662 313.29 240.022 313.29 240.467C313.29 240.912 313.65 241.273 314.094 241.273Z" fill="black"/>
    <path d="M314.294 240.473H313.895C313.895 240.301 314.177 223.36 304.469 221.799C302.556 221.494 300.993 221.927 299.823 223.088C295.814 227.072 297.472 238.323 297.489 238.434L297.095 238.495C297.023 238.023 295.393 226.927 299.546 222.805C300.81 221.544 302.495 221.071 304.536 221.399C314.582 223.01 314.299 240.301 314.294 240.473Z" fill="#3F3D56"/>
    <path d="M305.347 152.91L304.055 167.408L306.638 210.901C306.638 210.901 312.58 232.907 303.538 232.389C294.496 231.871 297.854 209.348 297.854 209.348L293.721 158.865L305.347 152.91Z" fill="#9F616A"/>
    <path d="M297.854 113.559L304.571 117.184C304.571 117.184 313.614 178.77 308.447 184.725C303.28 190.679 292.687 193.009 292.171 190.679C291.654 188.349 289.845 141.001 289.845 141.001L297.854 113.559Z"/>
    <path d="M36.5718 0H29.0375C28.7726 0 28.5185 0.105458 28.3312 0.293176C28.1439 0.480895 28.0386 0.735498 28.0386 1.00097V4.31487C28.0386 4.58035 28.1439 4.83495 28.3312 5.02267C28.5185 5.21039 28.7726 5.31585 29.0375 5.31585H30.286V10.2812H35.3232V5.31585H36.5718C36.8367 5.31585 37.0907 5.21039 37.2781 5.02267C37.4654 4.83495 37.5706 4.58035 37.5706 4.31487V1.00097C37.5706 0.735503 37.4654 0.480906 37.2781 0.293189C37.0908 0.105471 36.8367 8.84095e-06 36.5718 0Z" fill="#E6E6E6"/>
    <path d="M44.118 30.3288C39.5038 23.5629 37.1449 15.5071 37.3793 7.3155C37.3835 7.12649 37.3223 6.94186 37.2061 6.79294C37.0898 6.64403 36.9257 6.54001 36.7417 6.49857V5.06665H28.7845V6.47867H28.6748C28.5676 6.47881 28.4615 6.50011 28.3625 6.54136C28.2635 6.5826 28.1735 6.64298 28.0978 6.71906C28.0221 6.79513 27.9621 6.8854 27.9212 6.98472C27.8803 7.08404 27.8593 7.19047 27.8594 7.29792C27.8595 7.31778 27.8602 7.33763 27.8616 7.35746C28.4885 16.1142 26.3717 23.967 21.5112 30.916C21.2315 31.3154 21.0886 31.7949 21.104 32.2825L21.974 59.2526C21.995 59.8541 22.2469 60.4244 22.6773 60.8442C23.1077 61.2641 23.6833 61.5012 24.284 61.5059H42.2819C42.8894 61.501 43.471 61.2585 43.9028 60.8302C44.3345 60.4019 44.5822 59.8215 44.5933 59.2128L44.9286 33.0271C44.9385 32.066 44.6557 31.1247 44.118 30.3288Z" fill="#E6E6E6"/>
    <path d="M41.2689 39.2874H25.6031C25.3712 39.2874 25.1421 39.3376 24.9314 39.4347C24.7207 39.5317 24.5334 39.6733 24.3824 39.8496C24.2314 40.026 24.1203 40.233 24.0566 40.4565C23.9929 40.6799 23.9782 40.9145 24.0135 41.1442L26.3808 56.5639H39.9743L42.8497 41.1961C42.8933 40.9633 42.8851 40.7238 42.8258 40.4946C42.7665 40.2653 42.6575 40.052 42.5066 39.8698C42.3556 39.6875 42.1665 39.5409 41.9526 39.4402C41.7387 39.3395 41.5052 39.2874 41.2689 39.2874V39.2874Z" fill="white"/>
    <path d="M120.442 80.7922H112.908C112.643 80.7922 112.389 80.8977 112.202 81.0854C112.015 81.2731 111.909 81.5277 111.909 81.7932V85.1071C111.909 85.3726 112.015 85.6272 112.202 85.8149C112.389 86.0026 112.643 86.1081 112.908 86.1081H114.157V91.0734H119.194V86.1081H120.442C120.707 86.1081 120.961 86.0026 121.149 85.8149C121.336 85.6272 121.441 85.3726 121.441 85.1071V81.7932C121.441 81.5277 121.336 81.2731 121.149 81.0854C120.961 80.8977 120.707 80.7922 120.442 80.7922Z" fill="#E6E6E6"/>
    <path d="M127.989 111.121C123.375 104.355 121.016 96.2993 121.25 88.1078C121.254 87.9187 121.193 87.7341 121.077 87.5852C120.961 87.4363 120.797 87.3323 120.613 87.2908V85.8589H112.655V87.2709H112.546C112.438 87.2711 112.332 87.2924 112.233 87.3336C112.134 87.3748 112.044 87.4352 111.969 87.5113C111.893 87.5874 111.833 87.6776 111.792 87.777C111.751 87.8763 111.73 87.9827 111.73 88.0902C111.73 88.11 111.731 88.1299 111.732 88.1497C112.359 96.9064 110.243 104.759 105.382 111.708C105.102 112.108 104.959 112.587 104.975 113.075L105.845 140.045C105.866 140.646 106.118 141.217 106.548 141.636C106.979 142.056 107.554 142.293 108.155 142.298H126.153C126.76 142.293 127.342 142.051 127.774 141.622C128.205 141.194 128.453 140.614 128.464 140.005L128.799 113.819C128.809 112.858 128.527 111.917 127.989 111.121Z" fill="#E6E6E6"/>
    <path d="M125.14 120.08H109.474C109.242 120.08 109.013 120.13 108.802 120.227C108.591 120.324 108.404 120.466 108.253 120.642C108.102 120.818 107.991 121.025 107.927 121.249C107.864 121.472 107.849 121.707 107.884 121.936L110.252 137.356H123.845L126.721 121.988C126.764 121.756 126.756 121.516 126.697 121.287C126.637 121.058 126.528 120.844 126.377 120.662C126.226 120.48 126.037 120.333 125.823 120.232C125.609 120.132 125.376 120.08 125.14 120.08V120.08Z" fill="white"/>
    <path d="M25.9795 195.009H22.4057C22.28 195.009 22.1595 195.059 22.0706 195.148C21.9818 195.237 21.9319 195.358 21.9319 195.484V197.056C21.9319 197.181 21.9818 197.302 22.0706 197.391C22.1595 197.48 22.28 197.53 22.4057 197.53H22.9979V199.886H25.3873V197.53H25.9795C26.1052 197.53 26.2257 197.48 26.3145 197.391C26.4034 197.302 26.4533 197.181 26.4533 197.056V195.484C26.4533 195.358 26.4034 195.237 26.3145 195.148C26.2257 195.059 26.1052 195.009 25.9795 195.009Z" fill="#E6E6E6"/>
    <path d="M29.5592 209.395C27.3705 206.186 26.2516 202.364 26.3628 198.479C26.3647 198.389 26.3357 198.302 26.2806 198.231C26.2254 198.16 26.1476 198.111 26.0603 198.091V197.412H22.2858V198.082H22.2338C22.183 198.082 22.1326 198.092 22.0856 198.112C22.0387 198.131 21.996 198.16 21.9601 198.196C21.9242 198.232 21.8957 198.275 21.8763 198.322C21.8569 198.369 21.847 198.42 21.847 198.47C21.847 198.48 21.8474 198.489 21.8481 198.499C22.1965 202.464 21.129 206.424 18.8358 209.674C18.7031 209.863 18.6353 210.091 18.6426 210.322L19.0553 223.115C19.0653 223.4 19.1848 223.671 19.3889 223.87C19.5931 224.069 19.8661 224.182 20.1511 224.184H28.6883C28.9765 224.181 29.2523 224.066 29.4571 223.863C29.6619 223.66 29.7794 223.385 29.7847 223.096L29.9437 210.675C29.9484 210.219 29.8143 209.773 29.5592 209.395Z" fill="#E6E6E6"/>
    <path d="M28.3305 214.098H20.1846V218.179H28.3305V214.098Z" fill="white"/>
    <path d="M42.6158 195.009H39.042C38.9163 195.009 38.7958 195.059 38.7069 195.148C38.6181 195.237 38.5682 195.358 38.5682 195.484V197.056C38.5682 197.181 38.6181 197.302 38.7069 197.391C38.7958 197.48 38.9163 197.53 39.042 197.53H39.6342V199.886H42.0236V197.53H42.6158C42.7415 197.53 42.862 197.48 42.9509 197.391C43.0397 197.302 43.0896 197.181 43.0896 197.056V195.484C43.0896 195.421 43.0774 195.36 43.0536 195.302C43.0298 195.244 42.9949 195.192 42.9509 195.148C42.9069 195.104 42.8546 195.069 42.7971 195.045C42.7396 195.021 42.678 195.009 42.6158 195.009Z" fill="#E6E6E6"/>
    <path d="M46.1953 209.395C44.0066 206.186 42.8877 202.364 42.9989 198.479C43.0008 198.389 42.9718 198.302 42.9167 198.231C42.8615 198.16 42.7837 198.111 42.6964 198.091V197.412H38.9219V198.082H38.8699C38.8191 198.082 38.7687 198.092 38.7218 198.112C38.6748 198.131 38.6321 198.16 38.5962 198.196C38.5603 198.232 38.5318 198.275 38.5124 198.322C38.493 198.369 38.4831 198.42 38.4831 198.47C38.4831 198.48 38.4835 198.489 38.4842 198.499C38.8326 202.464 37.7651 206.424 35.4719 209.674C35.3392 209.863 35.2715 210.091 35.2787 210.322L35.6915 223.115C35.7014 223.4 35.8209 223.671 36.0251 223.87C36.2292 224.069 36.5023 224.182 36.7872 224.184H45.3244C45.6126 224.181 45.8885 224.066 46.0932 223.863C46.298 223.66 46.4156 223.385 46.4208 223.096L46.5798 210.675C46.5845 210.219 46.4504 209.773 46.1953 209.395Z" fill="#E6E6E6"/>
    <path d="M44.9667 214.098H36.8207V218.179H44.9667V214.098Z" fill="white"/>
    <path d="M59.2521 195.009H55.6782C55.5526 195.009 55.432 195.059 55.3432 195.148C55.2543 195.237 55.2044 195.358 55.2044 195.484V197.056C55.2044 197.181 55.2543 197.302 55.3432 197.391C55.432 197.48 55.5526 197.53 55.6782 197.53H56.2705V199.886H58.6598V197.53H59.2521C59.3777 197.53 59.4983 197.48 59.5871 197.391C59.676 197.302 59.7259 197.181 59.7259 197.056V195.484C59.7259 195.358 59.676 195.237 59.5871 195.148C59.4983 195.059 59.3777 195.009 59.2521 195.009Z" fill="#E6E6E6"/>
    <path d="M62.8318 209.395C60.6431 206.186 59.5242 202.364 59.6353 198.479C59.6373 198.389 59.6083 198.302 59.5532 198.231C59.498 198.16 59.4202 198.111 59.3329 198.091V197.412H55.5584V198.082H55.5064C55.4556 198.082 55.4052 198.092 55.3583 198.112C55.3113 198.131 55.2686 198.16 55.2327 198.196C55.1968 198.232 55.1683 198.275 55.1489 198.322C55.1295 198.369 55.1196 198.42 55.1196 198.47C55.1196 198.48 55.12 198.489 55.1207 198.499C55.4691 202.464 54.4016 206.424 52.1084 209.674C51.9757 209.863 51.9079 210.091 51.9152 210.322L52.3279 223.115C52.3379 223.4 52.4574 223.671 52.6615 223.87C52.8657 224.069 53.1387 224.182 53.4237 224.184H61.9609C62.2491 224.181 62.5249 224.066 62.7297 223.863C62.9345 223.66 63.052 223.385 63.0573 223.096L63.2163 210.675C63.221 210.219 63.0869 209.773 62.8318 209.395Z" fill="#E6E6E6"/>
    <path d="M61.6031 214.098H53.4571V218.179H61.6031V214.098Z" fill="white"/>
    <path d="M107.354 163.753H99.8194C99.5544 163.753 99.3004 163.859 99.1131 164.047C98.9257 164.234 98.8205 164.489 98.8205 164.754V168.068C98.8205 168.334 98.9257 168.588 99.1131 168.776C99.3004 168.964 99.5544 169.069 99.8194 169.069H101.068V174.035H106.105V169.069H107.354C107.485 169.069 107.615 169.043 107.736 168.993C107.857 168.943 107.967 168.869 108.06 168.776C108.153 168.683 108.226 168.573 108.276 168.451C108.327 168.33 108.352 168.2 108.352 168.068V164.754C108.352 164.489 108.247 164.234 108.06 164.047C107.873 163.859 107.619 163.753 107.354 163.753Z" fill="#E6E6E6"/>
    <path d="M114.9 194.082C110.286 187.316 107.927 179.261 108.161 171.069C108.166 170.88 108.104 170.695 107.988 170.546C107.872 170.397 107.708 170.293 107.524 170.252V168.82H99.5666V170.232H99.4569C99.3497 170.232 99.2436 170.254 99.1445 170.295C99.0455 170.336 98.9556 170.396 98.8799 170.472C98.8042 170.549 98.7442 170.639 98.7033 170.738C98.6624 170.837 98.6414 170.944 98.6415 171.051C98.6415 171.071 98.6423 171.091 98.6437 171.111C99.2706 179.868 97.1538 187.72 92.2933 194.669C92.0136 195.069 91.8707 195.548 91.886 196.036L92.7561 223.006C92.777 223.608 93.029 224.178 93.4594 224.598C93.8898 225.018 94.4654 225.255 95.0661 225.259H113.064C113.671 225.254 114.253 225.012 114.685 224.584C115.117 224.155 115.364 223.575 115.375 222.966L115.711 196.781C115.721 195.819 115.438 194.878 114.9 194.082Z" fill="#E6E6E6"/>
    <path d="M112.051 203.041H96.3851C96.1532 203.041 95.9241 203.091 95.7134 203.188C95.5027 203.285 95.3154 203.427 95.1644 203.603C95.0134 203.779 94.9023 203.986 94.8386 204.21C94.7749 204.433 94.7602 204.668 94.7955 204.898L97.1628 220.317H110.756L113.632 204.95C113.675 204.717 113.667 204.477 113.608 204.248C113.548 204.019 113.439 203.805 113.289 203.623C113.138 203.441 112.948 203.294 112.735 203.194C112.521 203.093 112.287 203.041 112.051 203.041V203.041Z" fill="white"/>
    <path d="M111.467 64.978H66.5555C69.6954 47.9664 68.973 27.4244 66.5555 5.33276H111.467C109.114 27.4244 108.41 47.9665 111.467 64.978Z" fill="#E6E6E6"/>
    <path d="M89.2818 44.3733C95.2586 44.3733 100.104 39.518 100.104 33.5287C100.104 27.5394 95.2586 22.6841 89.2818 22.6841C83.3049 22.6841 78.4597 27.5394 78.4597 33.5287C78.4597 39.518 83.3049 44.3733 89.2818 44.3733Z" fill="white"/>
    <path d="M70.3432 144.144H25.4318C28.5717 127.132 27.8494 106.59 25.4318 84.4983H70.3432C67.99 106.59 67.2868 127.132 70.3432 144.144Z" fill="#E6E6E6"/>
    <path d="M48.1579 123.539C54.1348 123.539 58.9799 118.684 58.9799 112.694C58.9799 106.705 54.1348 101.85 48.1579 101.85C42.1811 101.85 37.3359 106.705 37.3359 112.694C37.3359 118.684 42.1811 123.539 48.1579 123.539Z" fill="white"/>
    <path d="M136.899 68.7737H91.9872C95.1271 51.7621 94.4047 31.22 91.9872 9.12842H136.899C134.545 31.2201 133.842 51.7621 136.899 68.7737Z" fill="#E6E6E6"/>
    <path d="M114.713 48.1689C120.69 48.1689 125.535 43.3136 125.535 37.3243C125.535 31.335 120.69 26.4797 114.713 26.4797C108.737 26.4797 103.891 31.335 103.891 37.3243C103.891 43.3136 108.737 48.1689 114.713 48.1689Z" fill="white"/>
    <path d="M136.899 66.0625H91.9872C95.1271 49.0509 94.4047 28.5088 91.9872 6.41724H136.899C134.545 28.5089 133.842 49.0509 136.899 66.0625Z" fill="#E6E6E6"/>
    <path d="M114.713 45.4577C120.69 45.4577 125.535 40.6025 125.535 34.6131C125.535 28.6238 120.69 23.7686 114.713 23.7686C108.737 23.7686 103.891 28.6238 103.891 34.6131C103.891 40.6025 108.737 45.4577 114.713 45.4577Z" fill="white"/>
    <path d="M136.899 68.7737H91.9872C95.1271 51.7621 94.4047 31.22 91.9872 9.12842H136.899C134.545 31.2201 133.842 51.7621 136.899 68.7737Z" fill="#E6E6E6"/>
    <path d="M114.713 48.1689C120.69 48.1689 125.535 43.3136 125.535 37.3243C125.535 31.335 120.69 26.4797 114.713 26.4797C108.737 26.4797 103.891 31.335 103.891 37.3243C103.891 43.3136 108.737 48.1689 114.713 48.1689Z" fill="white"/>
    <path d="M147.18 61.7246H0V77.9915H147.18V61.7246Z" fill="#E6E6E6"/>
    <path d="M147.18 142.517H0V158.784H147.18V142.517Z" fill="#E6E6E6"/>
    <path d="M147.18 223.309H0V239.576H147.18V223.309Z" fill="#E6E6E6"/>
    </g>
    <defs>
    <clipPath id="clip0_205_21">
    <rect width="367" height="359" fill="white"/>
    </clipPath>
    </defs>
</svg>
</template>

<script>
export default {};
</script>

<style>
</style>