<template>
  <div>
    <v-container v-if="!loading" class="px-4 py-10">
      <v-row>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="auto me-auto">
              <div class="text-h5 font-weight-black primary--text">
                {{ $t("fo-domains") }}
              </div>
              <div class="text-body-1 font-weight-black secondary--text text--darken-4">
                {{ $t("fo-connect-your-domain") }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="step == 1" cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-title>
              <span class="text-h5"> {{ $t("fo-connect-domain") }} </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div class="mb-2">
                {{ $t("fo-please-first-connect-your-domain") }}
              </div>
              <v-form @submit.prevent ref="domainForm" lazy-validation>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      :value="info.domain"
                      @input="updateDomainInfo('domain', $event)"
                      :rules="urlRules"
                      :placeholder="$t('fo-domain-example')"
                      dense
                      outlined
                      :label="$t('fo-domain')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
                    <v-btn
                      @click="connect"
                      color="success"
                      height="40"
                      width="100%"
                      :ripple="false"
                      depressed
                      large
                      class="rounded-lg text-caption text-xl-body-1 text-lg-body-1 text-md-body-1"
                    >
                      {{ $t("fo-connect") }}
                    </v-btn>
                    
                  </v-col>
                  <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
                    <v-btn
                      color="primary"
                      height="40"
                      width="100%"
                      :ripple="false"
                      depressed
                      large href="https://office.builderall.com/br/office/buy-domain" 
                      target="_blank"
                      class="rounded-lg text-caption text-xl-body-1 text-lg-body-1 text-md-body-1">
                      <v-icon left v-if="$vuetify.breakpoint.smAndUp">
                        mdi-cart
                      </v-icon>
                      {{ $t("fo-buy-my-domain-at-builderall") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pb-5 px-5">
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="step == 2" cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-title>
              <span class="text-h5">
                {{ $t("fo-domain-information") }}
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <Link :link="info.domain" :hasButton="false" />
                </v-col>
                <v-col v-if="info.ns_1 && info.ns_2 && info.ip_1 && info.ip_2" cols="12">
                  <v-row>
                    <v-col cols="12">
                      {{ $t("fo-domain-connection-steps") }}
                    </v-col>
                    <v-col cols="12">
                      <div>{{ $t("fo-domain-connection-step-1") }}</div>
                      <div>{{ $t("fo-domain-connection-step-2") }}</div>
                      <div>{{ $t("fo-domain-connection-step-3") }}</div>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-row align="center" dense>
                        <v-col cols="12" xl="auto" lg="auto" md="auto">
                          {{ $t("fo-ns-1") }}
                        </v-col>
                        <v-col>
                          <Link :link="info.ns_1" :hasButton="false" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-row align="center" dense>
                        <v-col cols="12" xl="auto" lg="auto" md="auto">
                          IP 1
                        </v-col>
                        <v-col>
                          <Link :link="info.ip_1" :hasButton="false" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-row align="center" dense>
                        <v-col cols="12" xl="auto" lg="auto" md="auto">
                          {{ $t("fo-ns-2") }}
                        </v-col>
                        <v-col>
                          <Link :link="info.ns_2" :hasButton="false" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                      <v-row align="center" dense>
                        <v-col cols="12" xl="auto" lg="auto" md="auto">
                          IP 2
                        </v-col>
                        <v-col>
                          <Link :link="info.ip_2" :hasButton="false" />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else cols="auto">
                  <v-alert
                    dense
                    text
                    border="left"
                    icon="mdi-information-outline"
                    color="info"
                  >
                    {{ $t("fo-domain-not-connected-yet") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-5 px-5">
              <v-spacer></v-spacer>
              <v-row justify="end" align="center">
                <v-col cols="12" xl="auto" lg="auto" md="auto">
                  <v-btn
                    @click="checkConnection"
                    color="success"
                    :ripple="false"
                    width="100%"
                    depressed
                    large
                    class="rounded-lg text-caption text-xl-body-2 text-lg-body-2 text-md-body-2"
                  >
                    {{ $t("fo-verify-connection") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" xl="auto" lg="auto" md="auto">
                  <v-btn
                    color="error"
                    width="100%"
                    :ripple="false"
                    @click="disconnect"
                    depressed
                    large
                    class="rounded-lg text-caption text-xl-body-2 text-lg-body-2 text-md-body-2"
                  >
                    {{ $t("fo-disconnect-domain") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Loader v-else :height="450" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/shared/Loader";
import Link from "@/components/shared/Link";
import { validationRules } from "@/mixins/formValidation";
export default {
  mixins: [validationRules],
  components: {
    Loader,
    Link,
  },
  methods: {
    updateDomainInfo(key, value) {
      this.$store.commit("domain/updateDomainInfo", { key: key, value });
    },
    connect() {
      this.updateDomainInfo("store_hash_id", this.store.hash_id);
      if (this.$refs.domainForm.validate()) {
        this.$emit("connectDomain", this.info);
      }
    },
    checkConnection() {
      this.$emit("isConnectedDomain", this.info);
    },
    disconnect() {
      this.$emit("disconnectDomain", this.info);
    },
  },
  computed: {
    ...mapState("domain", ["info", "step"]),
    ...mapState("store", ["store"]),
    ...mapState("toggle", ["loading"]),
  },
};
</script>

<style>
</style>