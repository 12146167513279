export default {
    install(Vue, i18n) {
        Vue.prototype.$money = {
            formatMoney(currency, value, style = 'currency') {
                let valueFormatted = parseFloat(value);
                return valueFormatted.toLocaleString(i18n.locale, { style: style, currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
            },
            getCurrencySymbol(locale, currency) {
                return (0).toLocaleString(locale, { style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()
            },
            formatFloatDb(value) {
                if (value) {
                    value = value.replace(".", "");
                    value = value.replace(",", ".");
                    return parseFloat(value);
                }
            },
        };
    }
}