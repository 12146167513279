<template>
  <v-container fill-height>
      <v-row>
        <v-col sm="12" md="12" lg="12" xl="12" align="center">
          <v-card flat color="transparent" class="pa-2 d-flex" :height="height ? height : '739'">
            <v-row>
              <v-col class="justify-content-center align-items-center" align-self="center" align="center">
                <div>
                  <v-progress-circular :size="50" color="grey" indeterminate></v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  props: {
    height: {
      type: [ String, Number ]
    }
  }
}
</script>

<style>
</style>