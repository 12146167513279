import client from './Clients/AxiosClient';
const resource = '/order';

export default {
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    getOrders(param) {
        return client.get(`${resource}`, param);
    },
    getOrdersByBuyer(param) {
        return client.get(`${resource}/orders-by-buyer`, param);
    },
    payOrder(payload) {
        return client.post(`${resource}/payment`, payload);
    },
    updateOrderStatus(payload) {
        return client.put(`${resource}/update-status`, payload);
    }
};