<template>
  <svg class="custom-icon" width="55" height="56" viewBox="0 0 55 56" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.7868 43.6129H6.82678C6.37415 43.6129 5.94005 43.4331 5.61999 43.113C5.29993 42.793 5.12012 42.3589 5.12012 41.9063C5.12012 41.4536 5.29993 41.0195 5.61999 40.6995C5.94005 40.3794 6.37415 40.1996 6.82678 40.1996H47.7868C48.2394 40.1996 48.6735 40.3794 48.9936 40.6995C49.3136 41.0195 49.4934 41.4536 49.4934 41.9063C49.4934 42.3589 49.3136 42.793 48.9936 43.113C48.6735 43.4331 48.2394 43.6129 47.7868 43.6129Z"/>
    <path d="M44.3732 48.7329H10.2399C9.78723 48.7329 9.35314 48.5531 9.03307 48.233C8.71301 47.913 8.5332 47.4789 8.5332 47.0262C8.5332 46.5736 8.71301 46.1395 9.03307 45.8194C9.35314 45.4994 9.78723 45.3196 10.2399 45.3196H44.3732C44.8258 45.3196 45.2599 45.4994 45.58 45.8194C45.9001 46.1395 46.0799 46.5736 46.0799 47.0262C46.0799 47.4789 45.9001 47.913 45.58 48.233C45.2599 48.5531 44.8258 48.7329 44.3732 48.7329Z"/>
    <path d="M3.41345 29.9596H1.70679V35.0796C1.70679 35.9849 2.06641 36.8531 2.70653 37.4932C3.34665 38.1333 4.21485 38.4929 5.12012 38.4929H10.2401V36.7863C10.2381 34.9763 9.51827 33.2411 8.23845 31.9613C6.95863 30.6814 5.22339 29.9616 3.41345 29.9596Z"/>
    <path d="M51.2001 26.5463H52.9068V19.7196H51.2001C48.4853 19.7165 45.8825 18.6367 43.9628 16.717C42.0431 14.7973 40.9632 12.1945 40.9601 9.47962V7.77295H13.6535V9.47962C13.6503 12.1945 12.5705 14.7973 10.6508 16.717C8.7311 18.6367 6.12832 19.7165 3.41345 19.7196H1.70679V26.5463H3.41345C6.12832 26.5494 8.7311 27.6292 10.6508 29.5489C12.5705 31.4686 13.6503 34.0714 13.6535 36.7863V38.4929H40.9601V36.7863C40.9632 34.0714 42.0431 31.4686 43.9628 29.5489C45.8825 27.6292 48.4853 26.5494 51.2001 26.5463ZM27.3068 33.3729C25.2815 33.3729 23.3017 32.7724 21.6177 31.6472C19.9338 30.522 18.6213 28.9227 17.8463 27.0516C17.0712 25.1805 16.8684 23.1216 17.2635 21.1352C17.6587 19.1489 18.6339 17.3243 20.066 15.8922C21.4981 14.4601 23.3227 13.4848 25.3091 13.0897C27.2954 12.6946 29.3544 12.8974 31.2255 13.6724C33.0966 14.4475 34.6959 15.7599 35.821 17.4439C36.9462 19.1279 37.5468 21.1077 37.5468 23.1329C37.5437 25.8478 36.4638 28.4506 34.5441 30.3703C32.6244 32.29 30.0217 33.3698 27.3068 33.3729Z"/>
    <path d="M27.3066 29.9596C31.0769 29.9596 34.1333 26.9032 34.1333 23.1329C34.1333 19.3627 31.0769 16.3063 27.3066 16.3063C23.5364 16.3063 20.48 19.3627 20.48 23.1329C20.48 26.9032 23.5364 29.9596 27.3066 29.9596Z"/>
    <path d="M44.3733 36.7863V38.4929H49.4933C50.3986 38.4929 51.2668 38.1333 51.9069 37.4932C52.547 36.8531 52.9066 35.9849 52.9066 35.0796V29.9596H51.2C49.39 29.9616 47.6548 30.6814 46.375 31.9613C45.0951 33.2411 44.3753 34.9763 44.3733 36.7863Z"/>
    <path d="M51.2 16.3063H52.9066V11.1863C52.9066 10.281 52.547 9.41282 51.9069 8.77269C51.2668 8.13257 50.3986 7.77295 49.4933 7.77295H44.3733V9.47962C44.3753 11.2896 45.0951 13.0248 46.375 14.3046C47.6548 15.5844 49.39 16.3043 51.2 16.3063Z"/>
    <path d="M10.2401 9.47962V7.77295H5.12012C4.21485 7.77295 3.34665 8.13257 2.70653 8.77269C2.06641 9.41282 1.70679 10.281 1.70679 11.1863V16.3063H3.41345C5.22339 16.3043 6.95863 15.5844 8.23845 14.3046C9.51827 13.0248 10.2381 11.2896 10.2401 9.47962Z"/>
    </svg>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon {
    fill: currentColor;
}
</style>