<template>
    <OwnerProducts v-if="this.$store.state.store.store.hash_id" />
</template>

<script>
import OwnerProducts from "@/components/owner/Products";
export default {
  components: {
    OwnerProducts
  },
};
</script>

<style>
</style>