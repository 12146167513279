
// initial state
const state = () => ({
    step: 0,
    card: null,
    pixData: {
      qr_code: null,
      qr_code_url: null
    }
  });
  
  
  //getters
  const getters = {
 
  }
  
  // mutations
  const mutations = {
    updatePixData(state, { key, value }) {
      state.pixData[key] = value;
    },
    updateCard(state, payload) {
      state.card = payload
    },
    setStep(state, payload) {
      state.step = payload;
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  }
