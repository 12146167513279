<template>
  <v-card class="custom-drawer" v-click-outside="{ handler: onClickOutsideWithConditional, closeConditional, }" :class="{ open: drawerToggle }" :dir="$vuetify.rtl == true ? 'rtl' : 'ltr'">
    <slot name="custom-drawer-content" />
  </v-card>
</template>

<script>
export default {
  props: {
    drawerToggle: {
      type: Boolean,
    }
  },
  // computed: {
  //   drawerToggle: {
  //     get() {
  //       return this.$store.state.toggle.drawer;
  //     },
  //     set(value) {
  //       return this.$store.commit("toggle/toggleDrawer", value);
  //     },
  //   },
  // },
  methods: {
    onClickOutsideWithConditional () {
      this.$emit('onClickOutsideWithConditional', false);
    },
    closeConditional () {
      return this.drawerToggle;
    },
  }
};
</script>

<style lang="scss" scoped>
.custom-drawer {
  height: 0;
  opacity: 0;
  //margin-right: -10rem;
  bottom: 0;
  top: 0;
  width: 350px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  @media only screen and (max-width: 425px) {
    width: 100%;
  }

  &.open {
    height: 100%;
    z-index: 3;
    position: fixed;
    top: 80px;
    // margin-right: 0;
    opacity: 1;
    transition: all ease-in-out 200ms;
    transform: translateX(0, 28rem);
    &[dir="ltr"] {
      right: 0;
    }
    &[dir="rtl"] {
      left: 0;
    }
  }
}
</style>