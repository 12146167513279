<template>
  <v-container class="py-16 fill-height">
    <v-row justify="center" align="start" class="fill-height">
      <v-col cols="12" xl="5" lg="5" md="5" sm="5">
        <v-card flat class="pa-5">
          <v-row>
            <v-col cols="auto" class="pa-0">
              <v-btn
                tile
                color="primary"
                depressed
                text
                :ripple="false"
                @click="back"
                class="text-none px-0 font-weight-bold"
              >
                <v-icon left> mdi-chevron-left </v-icon>
                {{ $t("fo-back") }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class="text-h6 font-weight-black">
                {{ store.name }}
              </div>
            </v-col>
            <v-col cols="12">
              <v-row align="start" dense>
                <v-col cols="12" class="font-weight-black">
                  {{ $t("fo-delivery-forecast") }}
                </v-col>
                <v-col cols="12"> {{ $t("fo-today") }} <span v-if="deliveryTime">{{ `, ${deliveryTime}` }}</span> </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" v-for="(item, index) in cart.items" :key="index">
              <v-row align="start" dense class="font-weight-black">
                <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                  {{ item.quantity + "x " + item.name }}
                </v-col>
                <v-col cols="auto">
                  {{ $money.formatMoney(currencyCode, item.price / 100) }}
                </v-col>
              </v-row>
              <v-row
                align="start"
                dense
                v-for="(complement, index) in item.cart_complements"
                :key="index"
              >
                <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                  {{ complement.name }}
                </v-col>
                <v-col cols="auto">
                  {{ $money.formatMoney(currencyCode, complement.price / 100) }}
                </v-col>
              </v-row>
              <v-row dense>
                 <v-col cols="12" v-if="item.details">
                <span class="text-body-2 grey--text">{{ `${$t("fo-dets")}: ${item.details}`  }}</span>
            </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-row align="start" dense class="font-weight-black">
                <v-col cols="auto me-auto"> {{ $t("fo-subtotal") }} </v-col>
                <v-col cols="auto">
                  {{ $money.formatMoney(currencyCode, subtotal) }}
                </v-col>
              </v-row>
              <v-row align="start" dense v-if="order.delivery_method == 'DELIVERY' ? true : false">
                <v-col cols="auto me-auto"> {{ $t("fo-delivery-fee") }} </v-col>
                <v-col cols="auto">
                  <span v-if="store.delivery_fee > 0">{{ $money.formatMoney( currencyCode, store.delivery_fee / 100 ) }}</span>
                  <v-chip v-else color="primary" small>{{ $t("fo-free") }}</v-chip>
                </v-col>
              </v-row>
              <v-row align="start" dense class="font-weight-black">
                <v-col cols="auto me-auto"> {{ $t("fo-total-payable") }} </v-col>
                <v-col cols="auto">
                  {{ $money.formatMoney(currencyCode, total) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
             <v-col cols="12">
              <v-row dense>
                <v-col cols="auto me-auto">
                  <div class="text-h6 font-weight-black">
                    {{ $t("fo-client-data") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-icon class="me-2"> mdi-account </v-icon>
                  <span>{{ $auth.user() ? $auth.user().name : order.buyer_name }}</span>
                </v-col>
                <v-col cols="12">
                  <v-icon class="me-2"> mdi-cellphone </v-icon>
                  <span v-if="$auth.user() && $auth.getScope().includes('buyer')">{{ $auth.user().phone }}</span>
                  <span v-if="$auth.user() && $auth.getScope().includes('owner')"> {{ store.phone }}</span>
                  <span v-if="!$auth.isLoggedIn()">{{ order.buyer_phone }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="auto me-auto">
                  <div class="text-h6 font-weight-black">
                    {{ order.delivery_method == "DELIVERY" ? $t("fo-delivery-on") : $t("fo-pick-up-on") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-icon class="me-2"> mdi-map-marker </v-icon>
                  <span>{{ defaultAddress }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="auto me-auto">
                  <div class="text-h6 font-weight-black">
                    {{ $t("fo-order-date") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-icon class="me-2"> mdi-calendar </v-icon>
                  <span>{{ dateAndTime }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="auto me-auto">
                  <div class="text-h6 font-weight-black">
                    {{ $t("fo-payment-method") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-icon class="me-2"> mdi-credit-card </v-icon>
                  <span v-if="order.payment_method">{{ $t("fo-"+ order.payment_method.toLowerCase().replace('_', '-')) }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="order.payment_method == 'ONLINE_PAYMENT'">
              <v-row dense>
                <v-col cols="auto me-auto">
                  <div class="text-h6 font-weight-black">
                    {{ $t("fo-payment-data") }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-card class="pa-5" flat color="muted">
                      <v-row>
                        <v-col cols="auto" class="me-auto">
                          <div v-if="order.payment.payment_method" >
                            <v-icon medium class="me-2">{{ `$${order.payment.payment_method.toLowerCase()}` }}</v-icon>
                            <span class="font-weight-bold">{{ $t("fo-" + order.payment.payment_method.toLowerCase().replace('_', '-')) }}</span>
                          </div>
                          <div v-if="card" class="ms-8">
                            <div>{{ card.card.holder_name || card.card.name}}</div>
                            <div>{{ `************${card.card.last4 || card.card.last_four_digits}   ${card.card.exp_month}/${card.card.exp_year}` }}</div>
                          </div>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn color="primary"
                            depressed
                            text
                            :ripple="false"
                            class="text-none px-0 font-weight-bold" 
                            icon 
                            @click="changePaymentData">
                            <v-icon right color="primary">mdi-pencil</v-icon>
                          </v-btn>
                        </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <!-- <v-col cols="12">
              <v-row dense>
                <v-col cols="auto">
                  <span class="text-h6 font-weight-black"
                    >{{ $t("order_status") }}:
                  </span>
                  <span>Montado</span>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col cols="12">
              <!-- <v-btn
                color="primary"
                :ripple="false"
                depressed
                large
                outlined
                width="100%"
                class="rounded-lg text-none my-2"
                >{{ $t("track_order") }}</v-btn
              > -->
              <v-btn
                color="primary"
                :ripple="false"
                depressed
                large
                :loading="loading"
                :disabled="loading"
                @click="confirm"
                width="100%"
                class="rounded-lg text-none my-2"
                >{{ order.payment_method == "ONLINE_PAYMENT" && order.payment.payment_method !== "PIX" ? $t("fo-pay-and-send-order") : $t("fo-send-order") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  mounted() {
    if (
      !this.order.cart_hash_id ||
      !this.order.payment_method ||
      !this.order.delivery_method
    ) {
      this.$store.commit('order/setStep', 0)
      // this.$router.push({ path: `/${this.$route.params.slug}/${this.$route.params.id}/counter/order` });
    }
  },
  computed: {
    ...mapState("payment", ["payment", "card"]),
    ...mapState("store", ["store"]),
    ...mapState("order", ["order"]),
    ...mapState("cart", ["cart"]),
    ...mapState("buyer", ["address", "addresses"]),
    ...mapState("toggle", ["loading"]),
    totalOrderItems() {
      if(this.cart.items.length > 0){
      let total = this.cart.items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
      return total/100 }
      else { return 0 }
    },
    totalComplementsItems() {
      const total = this.cart.items.reduce((result, item) => {
        return result + (item.quantity * item.cart_complements.reduce((a, complement) => a + complement.price, 0));
      }, 0);
      return total/100
    },
    subtotal() {
      return this.totalOrderItems + this.totalComplementsItems
    },
    total() {
      return this.order.delivery_method == 'DELIVERY' ? this.subtotal + this.store.delivery_fee/100 : this.subtotal;
    },
    defaultAddress() {
      if (this.order.delivery_method == "WITHDRAW") {
        return this.order.store_address;
      } else {
        return this.order.buyer_address;
      }
    },
    deliveryTime() {
      if(!this.store.delivery_time_min && !this.store.delivery_time_max) {
        return null
      } if(this.store.delivery_time_min) {
        return `${this.store.delivery_time_min}-${this.store.delivery_time_max} ${this.$t("fo-min")}`;
      } else {
        return `${this.store.delivery_time_max} ${this.$t("fo-min")}`;
      }
    },
    currencyCode() {
      return !this.store.currency_code ? 'USD' : this.store.currency_code
    },
    dateAndTime() {
      return `${this.$date.getDate(window.navigator.language)} • ${this.$date.getTime(window.navigator.language)}`
    }
  },
  methods: {
    confirm() {
      this.$emit("createOrder", this.order);
    },
    back() {
      this.$store.commit('order/setStep', 0);
    },
    changePaymentData() {
      this.$store.commit('order/setStep', 1);
    }
  },
};
</script>

<style>
</style>