<template>
  <Loader :height="600" />
</template>

<script>
import Loader from "@/components/shared/Loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      hosts: [
        "localhost:8080",
        "delivery.builderall.io"
      ]
    }
  },
  mounted() {
    if(this.hosts.includes(window.location.host)) {
      this.$router.back()
    } else {
      this.$emit("getUrlByDomain", window.location.host);
    } 
  },
};
</script>

<style>
</style>