<template>
  <CustomDrawer :drawerToggle="infoDrawer" @onClickOutsideWithConditional="onClickOutsideWithConditional">
    <template v-slot:custom-drawer-content>
      <div class="px-5 py-5">
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab">
              <v-tab v-for="item in items" :key="item.tab">
                {{ $t(item.tab) }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item.tab">
                <v-card flat>
                  <v-row class="pt-5">
                    <v-col cols="12" v-if="item.index == 1">
                      <v-row dense>
                        <v-col cols="auto me-auto">
                          <div class="font-weight-bold">
                            {{ $t("fo-address") }}
                          </div>
                        </v-col>
                        <v-col cols="12">
                          {{ storeAddress }}
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="auto me-auto">
                          <div class="font-weight-bold">
                            {{ $t("fo-other-info") }}
                          </div>
                        </v-col>
                        <v-col cols="12">
                          {{ `${$t("fo-phone")}: ${storeContact}` }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-else> </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </template>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from "@/components/shared/CustomDrawer";
import { mapState } from "vuex";
export default {
  components: {
    CustomDrawer,
  },
  data() {
    return {
      tab: null,
      items: [
        { index: 1, tab: "fo-about" },
        // { index: 2, tab: "fo-business-hours" },
      ],
    };
  },
  computed: {
    ...mapState("store", ["store"]),
    infoDrawer: {
      get() {
        return this.$store.state.toggle.infoDrawer;
      },
      set(value) {
        return this.$store.commit("toggle/toggleInfoDrawer", value);
      },
    },
    storeAddress() {
      return `${this.store.address} - ${this.store.city_name}/${this.store.state_name} - ${this.store.zip_code}`;
    },
    storeContact() {
      return `${this.store.phone}`;
    },
  },
  methods: {
    onClickOutsideWithConditional(value) {
      this.infoDrawer = value;
    },
  },
};
</script>

<style>
</style>