
// initial state
const initialState = () => ({
    step: 0,
    store: {
        email: null,
        name: null,
        phone: null,
        // area_code: null,
        country_code : null,
        min_order: null,
        delivery_types: null,
        logo: null,
        address: null,
        zip_code: null,
        // street: null,
        city_name: null,
        state_name: null,
        // neighborhood: null,
        // number: null,
        working_times: 'default',
        delivery_time_min: null,
        delivery_time_max: null,
        payment_options: null,
        currency_code: null,
        need_register: null,
        owner_hash_id: null,
        hash_id: null,
        delivery_fee: null,
        url_slug: null,
        language: null,
        notify_by_whatsapp: 0,
        store_url: null,
        owner_gateway: null,
        gateway: null,
        theme: '#FF5E03',
    },
    currency: []
  });
  
  const state = initialState;
  
  //getters
  const getters = {
 
  }
  
  // mutations
  const mutations = {
    updateStore(state, { key, value }) {
      state.store[key] = value;
    },
    setStore(state, payload) {
      state.store.email = payload.email
      state.store.name = payload.name
      state.store.phone = payload.phone
      // state.store.area_code = payload.area_code
      state.store.country_code = payload.country_code
      state.store.min_order = payload.min_order
      state.store.delivery_types = payload.delivery_types
      state.store.logo = payload.logo
      state.store.logo_url = payload.logo_url
      state.store.address = payload.address
      state.store.zip_code = payload.zip_code
      // state.store.street = payload.street
      state.store.city_name = payload.city_name
      state.store.state_name = payload.state_name
      // state.store.neighborhood = payload.neighborhood
      // state.store.number = payload.number
      state.store.working_times = payload.working_times
      state.store.delivery_time_min = payload.delivery_time_min
      state.store.delivery_time_max = payload.delivery_time_max
      state.store.payment_options = payload.payment_options
      state.store.currency_code = payload.currency_code
      state.store.need_register = payload.need_register
      state.store.owner_hash_id = payload.owner_hash_id
      state.store.delivery_fee = payload.delivery_fee
      state.store.hash_id = payload.hash_id
      state.store.url_slug = payload.url_slug
      state.store.language = payload.language
      state.store.notify_by_whatsapp = payload.notify_by_whatsapp
      state.store.store_url = payload.store_url
      state.store.owner_gateway = payload.owner_gateway
      state.store.gateway = payload.gateway
      state.store.theme = payload.theme
    },
    setCurrency(state, payload) {
      state.currency = payload
    },
    reset(state) {
      Object.assign(state, initialState())
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  }