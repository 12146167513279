<template>
  <v-form @submit.prevent v-model="valid" lazy-validation ref="profileForm">
    <v-row dense>
      <v-col cols="12" xl="3" lg="3" md="3">
        <v-text-field
          outlined
          :label="$t('fo-name')"
          :value="info.name"
          @input="updateBuyerInfo('name', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3">
        <v-text-field
          outlined
          :rules="emailRules"
          :label="$t('fo-email')"
          :value="info.email"
          @input="updateBuyerInfo('email', $event)"
          @keyup="lowercase"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3">
        <v-text-field
          :value="info.phone"
          @input="updateBuyerInfo('phone', $event)"
          :rules="phoneRules"
          :label="$t('fo-phone')"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              :label="$t('fo-birth-date')"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="info.birth_date"
            :max="maxDate()"
            min="1930-01-01"
            :locale="this.$i18n.localee"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-text-field
          outlined
          :label="$t('fo-current-password')"
          :value="info.current_password"
          @input="updateBuyerInfo('password', $event)"
          :rules="passwordRules"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          placeholder="*********"
          name="input-10-1"
          @click:append="show = !show"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-text-field
          outlined
          :label="$t('fo-new-password')"
          :value="info.new_password"
          @input="updateBuyerInfo('new_password', $event)"
          :rules="passwordRules"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          placeholder="*********"
          name="input-10-1"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4">
        <v-text-field
          outlined
          :value="info.confirm_password"
          @input="updateBuyerInfo('confirm_password', $event)"
          :label="$t('fo-confirm-password')"
          :rules="passwordRules.concat(passwordConfirmationRule)"
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show3 ? 'text' : 'password'"
          placeholder="*********"
          name="input-10-3"
          @click:append="show3 = !show3"
        ></v-text-field>
      </v-col>
      <v-col cols="auto" class="ms-auto">
        <v-btn @click="$emit('updateBuyer', info)" color="primary" depressed large :loading="loading" :disabled="loading">
          {{ $t("fo-save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationRules } from "@/mixins/formValidation";
import { mapState } from "vuex";
export default {
  mixins: [validationRules],
  data() {
    return {
      valid: true,
      show: false,
      show2: false,
      show3: false,
      menu: false,
    };
  },
  mounted() {
    this.setBuyerInfo();
  },
  computed: {
    ...mapState("buyer", ["info", "loading"]),
    ...mapState("store", ["store"]),
    passwordConfirmationRule() {
      return () =>
        this.info.new_password === this.info.confirm_password ||
        this.$t("fo-password-must-match");
    },
    computedDateFormatted() {
      return this.formatDate(this.info.birth_date);
    },
  },
   watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.internalActivePicker  = "YEAR"));
    },
  },
  methods: {
    updateBuyerInfo(key, value) {
      this.$store.commit("buyer/updateBuyerInfo", { key: key, value });
    },
    setBuyerInfo() {
      this.$refs.profileForm.resetValidation();
      this.updateBuyerInfo('name', this.$auth.user().name);
      this.updateBuyerInfo('email', this.$auth.user().email);
      this.updateBuyerInfo('phone', this.$auth.user().phone);
      this.updateBuyerInfo('hash_id', this.$auth.user().hash_id);
      this.updateBuyerInfo('store_id', this.$auth.user().hash_store_id);
      this.updateBuyerInfo('birth_date', this.$auth.user().birth_date);
      this.updateBuyerInfo('password', null);
      this.updateBuyerInfo('confirm_password', null);
    },
    lowercase() {
      this.info.email = this.info.email.toLowerCase();
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      const formatedDate =  `${month}-${day}-${year}`;
      return this.$date.formatDate(formatedDate, this.$i18n.locale);
      
    },
    maxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 16);
      return date.toISOString().substr(0, 10);
    },
  },
};
</script>

<style>
</style>