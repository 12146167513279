<template>
  <div class="fill-height">
    <slot
      :updateStore="updateStore"
      :createStore="createStore"
      :getStoreById="getStoreById"
      :deleteStore="deleteStore"
      :getStores="getStores"
      :getCurrency="getCurrency"
      :getUrlByDomain="getUrlByDomain"
    />
  </div>
</template>

<script>
// import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const StoreRepository = Repository.get("store");
const CurrencyRepository = Repository.get("currency");
const OwnerRepository = Repository.get("owner");
export default {
  methods: {
    async createStore(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        await StoreRepository.create(payload);
        this.$store.commit("toggle/toggleLoading", false);
        this.$auth.getUser();
        this.getStores(this.$auth.user().hash_id);
        this.$notifications.showSuccess({ message: 'fo-store-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async getStoreById(id) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await StoreRepository.getStoreById(id);
        this.$store.commit("store/setStore", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("toggle/toggleLoading", false);
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async getStores(id) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await OwnerRepository.getStores(id);
        if(data.length) {
           this.$store.commit("store/setStore", data[0]);
        }
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("toggle/toggleLoading", false);
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async updateStore(payload) {
      try {
        await StoreRepository.update(payload);
        this.$notifications.showSuccess({ message: 'fo-store-updated-successfully' });
        this.getStores(this.$auth.user().hash_id);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteStore(id) {
      try {
        await StoreRepository.delete(id);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getCurrency() {
      try {
        const { data } = await CurrencyRepository.get();
        this.$store.commit('store/setCurrency', data)
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getUrlByDomain(payload) {
      try {
        const { data } = await StoreRepository.getUrlByDomain(payload);
        if(data.url) {
          window.location.href = data.url;
        }
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    }
  },
  // computed: {
  //    ...mapState("auth", ["user"]),
  // },
  mounted() {
    if(this.$route.params.id) {
      this.getStoreById(this.$route.params.id);
    } else if(this.$auth.user()) {
      this.getStores(this.$auth.user().hash_id);
    }
  },
};
</script>

<style>
</style>