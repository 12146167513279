<template>
  <vuetify-money
    v-model="value"
    :label="label"
    :outlined="true"
    :valueWhenIsEmpty="''"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    rules: {
      type: Array,
    },
    inputValue: {
      type: Number,
    },
  },
  data() {
    return {
      options: {
        locale: "en-US",
        suffix: "",
        length: 11,
        precision: 2,
      },
    };
  },
  computed: {
    value: {
      set(newValue) {
        let value = Math.round(Number(newValue) * 100);
        this.$emit("update", value);
      },
      get() {
        let value = this.inputValue / 100;
        return value;
      },
    },
  },
};
</script>

<style>
</style>