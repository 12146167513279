<template>
  <v-container class="px-4 py-10">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">
              {{ $t("fo-gateways") }}
            </div>
            <div class="text-body-1 font-weight-black secondary--text text--darken-4">
              {{ $t("fo-manage-your-gateways") }}
            </div></v-col
          >
          <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
            <v-btn
              color="primary"
              :ripple="false"
              large
              depressed
              class="rounded"
              @click="add"
              >{{ $t("fo-add") }}</v-btn
            >
            <!-- <v-btn
              color="primary"
              :ripple="false"
              large
              depressed
              class="rounded ml-2"
              :to="{ path: `products/new` }"
              >{{ $t("fo-import") }}</v-btn
            > -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="!loading">
        <v-row align="center">
          <v-col v-for="(gateway, index) in ownerGateways" :key="index" cols="12" xl="4" lg="4" md="6" fill-height>
            <v-card rounded="lg" flat outlined :class="gateway.is_active == true ? 'custom-card-active' : ''" class="text-h4" height="100%">
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <img :src="require(`@/assets/images/${gateway.gateway.slug}.png`)" class="pa-4" max-width="300" height="100"/>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-card-actions class="pa-4">
                <v-btn
                  color="primary"
                  depressed
                  :ripple="false"
                  rounded-xl
                  small
                  @click="edit(gateway)"
                >
                  {{ $t("fo-edit") }}
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  icon
                  :ripple="false"
                  rounded-xl
                  small
                  @click="confirm(gateway)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-icon v-if="gateway.is_active == true" color="primary">mdi-check-bold</v-icon>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        <Loader :height="400" />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px" scrollable eager :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card rounded="lg">
        <v-card-title class="text-xl-h6 text-lg-h6 text-body-1">
          <span>{{ $t('fo-gateway-configuration') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            :ripple="false"
            plain
            icon
            color="primary"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12">
                <v-item-group>
                  <v-row>
                    <v-col v-for="(item, index) in filtered_gateways" :key="index" cols="4">
                      <v-item v-slot="{ active, toggle }">
                        <v-card rounded="lg" flat outlined :class="active ? 'custom-card-active' : ''" class="pa-5 text-h4" height="100%" fill-height @click.native="toggle" @click="setGatewayInfo(item)">
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <v-img :src="require(`@/assets/images/${item.slug}.png`)" class="ma-auto" width="300"></v-img>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </v-col>
              <v-col cols="12">
                <v-chip-group
                  active-class="primary--text"
                  column
                  multiple
                  :value="gateway.config.payment_methods"
                  @change="updateGatewayConfig('payment_methods', $event)"
                >
                  <v-chip
                    v-for="payment_method in payment_methods"
                    :key="payment_method"
                    :value="payment_method"
                    filter
                    outlined
                  >
                    {{ $t( "fo-" + payment_method.toLowerCase().replace("_", "-"))}}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" v-if="tutorial">
                <v-alert
                  dense
                  text
                  border="left"
                  color="info"
                  class="text-caption"
                > <span v-html="$t(tutorial.key)"></span>
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-form @submit.prevent ref="gatewayForm" v-model="valid" lazy-validation>
                  <v-row dense class="flex-column">
                    <v-col>
                      <v-text-field
                        label="Publishable Key"
                        :value="gateway.config.pk"
                        @input="updateGatewayConfig('pk', $event)"
                        :rules="required"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Secret Key"
                        :value="gateway.config.sk"
                        @input="updateGatewayConfig('sk', $event)"
                        :rules="required"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0">
                      <v-switch
                        class="my-0 py-0 text-subtitle"
                        :input-value="gateway.is_active"
                        @change="updateGateway('is_active', $event)"
                        :label="$t('fo-enable-gateway')"
                      ></v-switch>
                    </v-col>
                    <v-col class="pt-0">
                      <v-switch
                        class="my-0 py-0 text-subtitle"
                        :input-value="gateway.test"
                        @change="updateGateway('test', $event)"
                        :true-value="1"
                        :false-value="0"
                        :label="$t('fo-test')"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions  class="pb-5 px-5">
            <v-btn
              @click="validate"
              color="primary"
              :ripple="false"
              width="100%"
              depressed
              large
              class="rounded-lg">
              {{ $t("fo-save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    <DeleteModal @yes="deleteItemConfirm" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
import DeleteModal from "@/components/owner/DeleteModal";
import Loader from "@/components/shared/Loader";
export default {
  mixins: [validationRules],
  components: {
    DeleteModal,
    Loader
  },
  data() {
    return {
      dialog: false,
      valid: true,
      id: null,
      payment_methods: [],
      selected: 0,
      tutorials: {
        Stripe: {
          name: "Stripe",
          key: "fo-stripe-instructions"
        },
        Pagarme: {
          name: "Pagarme",
          key: "fo-pagarme-instructions",
        },
      },
      tutorial: null
    };
  },
  watch: {
    'filtered_gateways': {
      handler(value) {
        this.payment_methods = value[0].payment_methods;
        this.tutorial = this.tutorials[value[0].name];
      }
    }
  },
  computed: {
    ...mapState("gateway", ["gateway", "gateways", "ownerGateways"]),
    ...mapState("toggle", ["loading"]),
    filtered_gateways() {
      return this.gateway.gateway ? this.gateways.filter(item => item.hash_id == this.gateway.gateway.hash_id) : this.gateways;
    }
  },
  mounted() {
    this.$emit("getGatewayByOwner");
    this.$emit("getGateways");
  },
  methods: {
    updateGatewayConfig(key, value) {
      this.$store.commit("gateway/updateGatewayConfig", { key: key, value });
    },
    updateGateway(key, value) {
      this.$store.commit("gateway/updateGateway", { key: key, value });
    },
    add() {
      this.$store.commit("gateway/resetGateway");
      this.$refs.gatewayForm.resetValidation();
      this.dialog = true;
    },
    edit(gateway) {
      this.$store.commit("gateway/setGateway", gateway);
      // this.$store.commit("gateway/resetGateways");
      // this.payment_methods = JSON.parse(this.gateway.gateway.payment_methods);
      this.dialog = true;
    },
    setGatewayInfo(gateway) {
      this.updateGateway("gateway_hash_id", gateway.hash_id);
      this.updateGateway("owner_hash_id", this.$auth.user().hash_id);
      this.payment_methods = gateway.payment_methods;
      this.tutorial = this.tutorials[gateway.name];
    },
    validate() {
      if(this.gateway.gateway_hash_id == null) {
        this.$notifications.showError({ message: "fo-please-select-a-gateway" });
      } else if(this.gateway.config.payment_methods.length == 0) {
        this.$notifications.showError({ message: "fo-please-select-a-payment-method" });
      } else if (this.$refs.gatewayForm.validate()) {
        this.$store.commit("gateway/stringfyConfig", this.gateway.config);
        !this.gateway.hash_id ? this.$emit("createGateway", this.gateway) : this.$emit("updateGateway", this.gateway);
        this.dialog = false;
      }
    },
    confirm(gateway) {
      this.id = gateway.hash_id;
      this.$store.commit("toggle/toggleDeleteModal", true);
    },
    deleteItemConfirm() {
      this.$emit("deleteGateway", this.id);
      this.$store.commit("toggle/toggleDeleteModal", false);
    },
  },
};
</script>

<style scoped>
.custom-card-active {
  border: 2px solid var(--v-primary-base) !important;
}
</style>