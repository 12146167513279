import client from './Clients/AxiosClient';
const resource = '/buyer';

export default {
    login(id, payload) {
        return client.post(`${resource}/login/${id}`, payload);
    },
    register(id, payload) {
        return client.post(`${resource}/register/${id}`, payload);
    },
    forgotPassword(id, payload) {
        return client.post(`${resource}/forgot-password/${id}`, payload);
    },
    recoverPassword(id, payload) {
        return client.post(`${resource}/recover-password/${id}`, payload);
    },
    getBuyerCart(store_id, id) {
        let url = id ? `${resource}/get_cart/${store_id}/${id}}` : `${resource}/get_cart/${store_id}`
        return client.get(url);
    },
    setBuyerCart(id) {
        return client.put(`${resource}/set_cart/${id}`);
    },
    addItemCart(id, payload) {
        return client.post(`${resource}/add_item/${id}`, payload);
    },
    removeItemCart(id) {
        return client.delete(`${resource}/remove_item/${id}`);
    },
    getUser() {
        return client.get(`${resource}/user`);
    },
    getBuyersByStore(params) {
        return client.get(`${resource}/buyers-by-store`, params);
    },
    updateBuyer(payload) {
        return client.put(`${resource}`, payload);
    },
};