import jwt_decode from "jwt-decode";
import cookies from 'vue-cookies';
import router from '@/router';
import Repository from "@/repositories/RepositoryFactory";


export default {
    install(Vue, store) {
        Vue.prototype.$auth = {
            isLoggedIn() {
                if (this.isTokenExpired()) return false
                return true
            },
            isTokenExpired() {
                const token = this.getToken();
                if (this.tokenExists() && token != false) {
                    try {
                        const { exp } = jwt_decode(token);
                        if (Date.now() >= exp * 1000) {
                            this.setToken(false)
                            return true;
                        }
                    } catch (err) {
                        return true;
                    }
                    return false;
                } else {
                    return true
                }
            },
            setToken(token) {
                cookies.set("auth_token", token)
            },
            getToken() {
                let token = cookies.get("auth_token");
                return token
            },
            tokenExists() {
                if (cookies.isKey("auth_token")) return true
                return false
            },
            logout() {
                this.setToken(false);
                router.go();
            },
            setUser(user) {
                store.commit("auth/setUser", user);
            },
            user() {
                return store.state.auth.user;
            },
            getScope() {
                const token = this.getToken();
                const { scopes } = jwt_decode(token);
                return scopes
            },
            async getUser() {
                const OwnerRepository = Repository.get("owner");
                const BuyerRepository = Repository.get("buyer");
                if (this.getScope().includes('owner')) {
                    const { data } = await OwnerRepository.getUser();
                    store.commit("auth/setUser", data);
                    return data;
                } else {
                    const { data } = await BuyerRepository.getUser();
                    store.commit("auth/setUser", data);
                    return data;
                }
            },
            ownerHasStore() {
                if (this.isLoggedIn() && this.getScope().includes('owner')) {
                    if(this.user()) {
                    return this.user().stores.length == 0 ?  false : true
                    }
                }
            }
        };
    }
}