<template>
  <div>
    <slot
      :createAdditionalGroup="createAdditionalGroup"
      :updateAdditionalGroup="updateAdditionalGroup"
      :deleteAdditionalGroup="deleteAdditionalGroup"
      :createAdditional="createAdditional"
      :deleteAdditional="deleteAdditional"
      :updateAdditional="updateAdditional"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const ComplementRepository = Repository.get("complement");
const ComplementGroupRepository = Repository.get("complement_group");
const StoreRepository = Repository.get("store");
export default {
  computed: {
    ...mapState("store", ["store"]),
  },
  methods: {
    async getAdditionalsGroup(id) {
      try {
        const { data } = await StoreRepository.getStoreComplementsGroup(id);
        this.$store.commit("additional/setGroupAdditionals", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    // async getAdditionals() {
    //   try {
    //     const { data } = await ComplementRepository.get();
    //     console.log(data);
    //   } catch (error) {
    //     this.$notifications.showError({ code: error });
    //   }
    // },
    async createAdditionalGroup(payload) {
      try {
        await ComplementGroupRepository.create(this.store.hash_id, payload);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additionals-group-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async updateAdditionalGroup(payload) {
      try {
        await ComplementGroupRepository.update(payload);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additionals-group-updated-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteAdditionalGroup(id) {
      try {
        await ComplementGroupRepository.delete(id);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additionals-group-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async createAdditional(payload) {
      try {
        await ComplementRepository.create(payload);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additional-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async updateAdditional(payload) {
      try {
        await ComplementRepository.update(payload);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additional-updated-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteAdditional(id) {
      try {
        await ComplementRepository.delete(id);
        this.getAdditionalsGroup(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-additional-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
  },
  created() {
    this.getAdditionalsGroup(this.store.hash_id);
  },
};
</script>

<style>
</style>