<template>
  <v-container class="px-4 py-10">
    <v-row dense>
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">
              {{ $t("fo-profile") }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="!loading" >
        <v-card rounded="xl" class="pa-8 mb-2">
          <v-card-title class="text-h6 font-weight-bold primary--text mb-3">{{ $t("fo-update-profile") }}</v-card-title>
          <v-card-text>
            <Buyer>
              <template v-slot="{ updateBuyer }">
                <ProfileForm v-if="$auth.user()" @updateBuyer="updateBuyer" />
              </template>
            </Buyer>
          </v-card-text>
        </v-card>
      </v-col>
      <Loader v-else :height="300" />
    </v-row>
  </v-container>
</template>

<script>
import Buyer from "@/components/shared/Buyer";
import ProfileForm from "@/components/shop/default/ProfileForm";
import Loader from "@/components/shared/Loader";
import { mapState } from "vuex";
export default {
  components: {
    Buyer,
    ProfileForm,
    Loader,
  },
   computed: {
    ...mapState("buyer", ["loading"]),
  },
};
</script>

<style>
</style>