<template>
  <v-snackbar v-model="show" right top color="transparent" elevation="0">
    <v-alert dismissible v-model="show" elevation="2" :type="type">
      {{ !message ? $t(code) : $t(message) }}
    </v-alert>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      show: false,
      message: null,
      code: null,
      color: null,
      type: null,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "alert/showMessage") {
        this.message = state.alert.message;
        this.code = state.alert.code;
        this.color = state.alert.color;
        this.type = state.alert.type;
        (this.show = true), (this.loading = true);
      }
    });
  }
};
</script>

<style >
</style>