<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="rounded-lg">
      <v-card-title class="text-h5 justify-center">{{
        $t("fo-are-you-sure-you-want-to-delete-it")
      }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
          @click="dialog = false"
          >{{ $t("fo-cancel") }}</v-btn
        >
        <v-btn
          color="error"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
          @click="$emit('yes')"
          >{{ $t("fo-yes") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
   computed: {
    dialog: {
      get() {
        return this.$store.state.toggle.deleteModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleDeleteModal", value);
      },
    },
  },
};
</script>

<style>
</style>