<template>
  <v-container class="px-4 py-10">
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">
              {{ $t("fo-orders") }}
            </div>
            <div class="text-body-1 font-weight-black secondary--text text--darken-4">
              {{ $t("fo-view-your-orders") }}
            </div>
          </v-col>
          <!-- <v-col cols="4">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('fo-search')"
              v-model="search"
              hide-details
              solo
              flat
            ></v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" v-if="orders.data.length > 0">
        <v-card flat rounded="lg" v-for="order, index in orders.data" :key="index" class="mb-2" @click.stop="viewDetails(order)" :ripple="false">
          <v-card-title class="text-body-1 font-weight-bold">
            {{ buyerInfo(order.buyer_info).name }}
          </v-card-title>
          <v-card-subtitle class="text-caption grey--text">{{ `${$t("fo-order-placed-in")}: ${$date.formatDate( order.created_at, language )} ${$date.formatTime(order.created_at, language )}` }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <div>{{ `${$t("fo-order")} id` }} </div>
                <div class="text-caption grey--text">{{ order.hash_id }}</div>
              </v-col>
              <v-col>
                <div>{{ $t("fo-total") }}</div>
                <div v-if="store.currency_code">{{ $money.formatMoney(store.currency_code, (order.amount + order.delivery_fee)/100) }}</div>
              </v-col>
              <v-col>
                <div>{{ $t("fo-payment-method") }}</div>
                <div>{{ $t( "fo-" + order.payment_method.toLowerCase().replace("_", "-"))}}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row align="center" dense>
              <v-col cols="auto">
                <v-icon class="me-2">mdi-map-marker</v-icon>
                <span class="text-body-2">{{ order.delivery_method == 'DELIVERY' ? buyerInfo(order.buyer_info).address : $t("fo-withdraw") }}</span>
              </v-col>
              <v-col cols="12" xl="4" lg="4" md="4" sm="6" class="ms-auto">
                <div @click.stop>
                  <v-select
                    v-model="order.store_status"
                    :items="status"
                    item-text="status"
                    @change="updateStatus($event, order)"
                    dense
                    outlined
                    hide-details
                    return-object
                  >
                <template slot="selection" slot-scope="data">
                 <span class="text-capitalize">{{ data.item.allowsToDelete == 0 ? $t("fo-" + data.item.status.toLowerCase().replaceAll("_", "-")) : data.item.status.toLowerCase() }}</span> 
                </template>
                <template slot="item" slot-scope="data">
                  <span class="text-capitalize">{{ data.item.allowsToDelete == 0 ? $t("fo-" + data.item.status.toLowerCase().replaceAll("_", "-")) : data.item.status.toLowerCase() }}</span>
                </template>
                </v-select>
                </div>
              </v-col>
              <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
                <v-btn color="primary" depressed @click.stop="openStatusModal(order)" width="100%">
                  <v-icon>mdi-plus</v-icon>
                  status
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" scrollable max-width="500px">
          <v-card class="rounded-lg" id="printMe">
            <v-card-title>
              <span class="text-h5"> {{ $t("fo-order") }}</span>
            </v-card-title>
            <v-card-text>
              <v-row v-if="order.cart">
                <v-col cols="12" v-for="(item, index) in order.cart.items" :key="index">
                  <v-row align="start" dense class="font-weight-black">
                    <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                      {{ item.quantity + "x " + item.name }}
                    </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(store.currency_code, item.price / 100) }}
                    </v-col>
                  </v-row>
                  <v-row align="start" dense v-for="(complement, index) in item.cart_complements" :key="index">
                    <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                      {{ complement.name }}
                    </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(currencyCode, complement.price / 100) }}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" v-if="item.details">
                    <span class="text-body-2 grey--text">{{ `${$t("fo-dets")}: ${item.details}`  }}</span>
                </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                   <v-row v-if="order.delivery_fee" align="start" dense>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="auto me-auto">{{ $t('fo-subtotal') }} </v-col>
                        <v-col cols="auto"> {{ $money.formatMoney(currencyCode, order.amount / 100) }} </v-col>
                      </v-row>
                      </v-col>
                      <v-col cols="12">
                      <v-row>
                        <v-col cols="auto me-auto">{{ $t('fo-delivery-fee') }} </v-col>
                        <v-col cols="auto"> {{ $money.formatMoney(currencyCode, order.delivery_fee / 100) }} </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row align="start" dense class="font-weight-black">
                    <v-col cols="auto me-auto"> {{ $t("fo-total-payable") }} </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(currencyCode, total) }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="order.buyer_info">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-client-data") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-account </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).name }}</span>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-cellphone </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).phone }}</span>
                    </v-col>
                    <v-col cols="12" v-if="order.delivery_method == 'DELIVERY'">
                      <v-icon class="me-2"> mdi-map-marker </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).address }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-delivery-method") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-map-marker </v-icon>
                      <span>{{ $t("fo-"+ order.delivery_method.toLowerCase()) }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-order-date") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-calendar </v-icon>
                      <span>{{ `${$date.formatDate( order.created_at, language )} ${$date.formatTime(order.created_at, language )}` }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-payment-method") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-credit-card </v-icon>
                      <span v-if="order.payment_method">{{ $t("fo-"+ order.payment_method.toLowerCase().replace('_', '-')) }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-5 px-5 hidden-print">
              <v-spacer></v-spacer>
              <v-btn
               @click="print"
                color="primary"
                :ripple="false"
                depressed
                large
                class="rounded-lg mx-2 hidden-print"
              >
                {{ $t("fo-print") }}
              </v-btn>
              <v-btn
                @click="dialog = false"
                color="primary"
                :ripple="false"
                depressed
                large
                class="rounded-lg mx-2 hidden-print"
              >
                {{ $t("fo-close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" v-else>
        {{ $t("fo-you-have-no-orders-yet") }}
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <Loader :height="400" />
      </v-col>
    </v-row>
    <v-row justify="end" v-if="orders">
      <v-col cols="auto">
         <v-pagination
          v-model="orders.current_page"
          :total-visible="7"
          :length="orders.last_page"
          class="elevation-0"
          @input="next"
        ></v-pagination>
      </v-col>
    </v-row>
    <StoreStatus @updateStatus="updateStatus" @updateOrders="updateOrders">
      <template v-slot="{ createStoreStatus, deleteStoreStatus, updateStoreStatus }">
        <StatusModal @createStoreStatus="createStoreStatus" @deleteStoreStatus="deleteStoreStatus" @updatestatus="updateStatus" @updateStoreStatus="updateStoreStatus" />
      </template>
    </StoreStatus>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/shared/Loader";
import StoreStatus from "@/components/shared/StoreStatus"
import StatusModal from "@/components/owner/StatusModal";
export default {
  components: {
    Loader,
    StoreStatus,
    StatusModal
  },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 8,
      dialog: false,
    };
  },
  computed: {
    ...mapState("order", ["order", "orders"]),
    ...mapState("store", ["store"]),
    ...mapState("store_status", ["status"]),
    ...mapState("toggle", ["loading"]),
    currencyCode() {
      return !this.store.currency_code ? 'USD' : this.store.currency_code
    },
    language() {
      return !this.store.currency_code ? 'en-US' : this.store.language.replace("_", "-");
    },
    total() {
      return !this.order.delivery_fee ? this.order.amount/100 : (this.order.amount + this.order.delivery_fee)/100;
    }
  },
  created() {
    this.$emit("getOrders");
  },
  methods: {
    viewDetails(item) {
      this.$store.commit("order/setOrder", item);
      this.$nextTick(() => {
        this.dialog = true;
      });
    },
    buyerInfo(info) {
      const obj = JSON.parse(info);
      return obj;
    },
    next() {
       this.$emit("getOrders", this.orders.current_page);
    },
    updateStatus(info, order) {
      this.$store.commit("order/setOrder", order);
      this.$store.commit("order/updateOrder", { key: 'store_status_hash_id', value: info.hash_id });
      this.$emit("updateOrderStatus", this.order);
    },
    updateOrders() {
      this.$emit("getOrders");
    },
    openStatusModal(order) {
      this.$store.commit("order/setOrder", order);
      this.$store.commit("toggle/toggleStatusModal", true);
    },
    print() {
      this.$htmlToPaper('printMe');
    }
  },
};
</script>

<style>

</style>