
// initial state
const state = () => ({
    cartDrawer: false,
    infoDrawer: false,
    shopModal: false,
    addressModal: false,
    additionalsModal: false,
    userNavigation: false,
    ownerNavigation: false,
    filterModal: false,
    dialog: false,
    loading: false,
    redirectModal: false,
    categoryModal: false,
    statusModal: false,
    deleteModal: false
});

// mutations
const mutations = {
    toggleCartDrawer(state, payload) {
        state.cartDrawer = payload;
    },
    toggleInfoDrawer(state, payload) {
        state.infoDrawer = payload;
    },
    toggleShopModal(state, payload) {
        state.shopModal = payload;
    },
    toggleAddressModal(state, payload) {
        state.addressModal = payload;
    },
    toggleAdditionalsModal(state, payload) {
        state.additionalsModal = payload;
    },
    toggleUserNavigation(state, payload) {
        state.userNavigation = payload;
    },
    toggleOwnerNavigation(state, payload) {
        state.ownerNavigation = payload;
    },
    toggleDialog(state, payload) {
        state.dialog = payload;
    },
    toggleLoading(state, payload) {
        state.loading = payload;
    },
    toggleFilterModal(state, payload) {
        state.filterModal = payload;
    },
    toggleRedirectModal(state, payload) {
        state.redirectModal = payload;
    },
    toggleCategoryModal(state, payload) {
        state.categoryModal = payload;
    },
    toggleStatusModal(state, payload) {
        state.statusModal = payload;
    },
    toggleDeleteModal(state, payload) {
        state.deleteModal = payload;
    }
}

export default {
    namespaced: true,
    state,
    mutations
}