
// initial state
const initialState = () => ({
  order: {
    id: null,
    buyer_hash_id: null,
    store_hash_id: null,
    cart_hash_id: null,
    amount: null,
    payment_method: null,
    delivery_method: null,
    buyer_name: null,
    buyer_phone: null,
    buyer_address: null,
    store_address: null,
    timezone: null,
    created_at: null,
    delivery_fee: null,
    store_status: {
      status: null,
      description: null,
      hash_id: null,
      store_hash_id: null
  },
    store_status_hash_id: null,
    payment: {       
      customer: {
        name: null,
        email: null,
        document: null,
        phone: {
            area_code: null,
            number : null
        }
      },             
      payment_method: null,
      card_token: null,
      billing_address: {
        line_1: null,
        zip_code: null,
        city: null,
        state: null,
      },
      metadata: {
        store_name: null
      },
  },
  },
  orders: [],
  step: 0,
});

const state = initialState;

//getters
const getters = {

}

// mutations
const mutations = {
  updateOrder(state, { key, value }) {
    state.order[key] = value;
  },
  setOrders(state, payload) {
    state.orders = payload
  },
  setOrder(state, payload) {
    state.order.id = payload.id
    state.order.hash_id = payload.hash_id
    state.order.buyer_hash_id = payload.buyer_hash_id
    state.order.store_hash_id = payload.store_hash_id
    state.order.cart_hash_id = payload.cart_hash_id
    state.order.amount = payload.amount
    state.order.payment_method = payload.payment_method
    state.order.delivery_method = payload.delivery_method
    state.order.buyer_name = payload.buyer_name
    state.order.buyer_phone = payload.buyer_phone
    state.order.buyer_address = payload.buyer_address
    state.order.address_id = payload.address_id
    state.order.store_address = payload.store_address
    state.order.timezone = payload.timezone
    state.order.buyer_info = payload.buyer_info
    state.order.cart = payload.cart
    state.order.created_at = payload.created_at
    state.order.delivery_fee = payload.delivery_fee
    state.order.store_status = payload.store_status
    state.order.store_status_hash_id = payload.store_status_hash_id
  },
  updatePayment(state, { key, value }) {
    state.order.payment[key] = value;
  },
  updatePaymentCustomer(state, { key, value }) {
      state.order.payment.customer[key] = value;
  },
  updatePaymentCustomerAddress(state, { key, value }) {
    state.order.payment.billing_address[key] = value;
  },
  updatePaymentCustomerPhone(state, { key, value }) {
      state.order.payment.customer.phone[key] = value;
   },
   updatePaymentMetadata(state, { key, value }) {
    state.order.payment.metadata[key] = value;
  },
  setPayment(state, payload) {
    state.order.payment = payload
  },
  reset(state) {
    Object.assign(state, initialState())
  },
  setStep(state, payload) {
    state.step = payload;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}