<template>
  <component :is="template" />
</template>

<script>
import AccountOrders from '@/components/shop/default/Account/Orders'
export default {
  components: {
    AccountOrders
  },
   data: () => ({
    user: {
      template: 'default'
    },
  }),
  computed: {
    template() {
      if(this.user.template == 'default') {
        return 'AccountOrders'
      } else {
        return 'BuyerNewTemplate'
      }
    }
  }
}
</script>

<style>

</style>