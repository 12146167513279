<template>
  <v-row>
    <v-col cols="12">
      <div>{{ $t('fo-your-order') }}</div>
      <div class="text-h6 font-weight-black">{{ store.name }}</div>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col v-if="cart.items.length > 0" cols="12" class="custom-items">
      <v-row v-for="(item, index) in cart.items" :key="index">
        <v-col cols="12">
          <v-row align="start" dense>
            <v-col cols="auto me-auto"> {{ item.quantity + "x " + item.name }} </v-col>
            <v-col cols="auto" v-if="store.currency_code">
              {{ $money.formatMoney(store.currency_code, item.price/100) }}
            </v-col>
            <v-col cols="12" v-if="item.cart_complements">
               <v-row
                align="start"
                dense
                class="text-body-2 grey--text"
                v-for="complement,index in item.cart_complements" :key="index"
              >
                <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                  {{ complement.name }}
                </v-col>
                <v-col cols="auto" v-if="store.currency_code">
                  {{ $money.formatMoney(store.currency_code, complement.price / 100) }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="item.details">
                <span class="text-body-2 grey--text">{{ `${$t("fo-dets")}: ${item.details}` }}</span>
            </v-col>
            <v-col cols="12">
              <v-row justify="start">
                <!-- <v-col cols="auto" class="px-0">
                  <v-btn
                    text
                    :ripple="false"
                    color="primary"
                    class="text-none px-0"
                    @click="$emit('editItemOrder', item)"
                    >{{ $t('fo-edit') }}</v-btn
                  >
                </v-col> -->
                <v-col v-if="hasRemoveButton" cols="auto">
                  <v-btn
                    text
                    :ripple="false"
                    plain
                    color="primary"
                    class="text-none font-weight-bold pa-0 ma-0"
                    @click="$emit('removeItemCart', item.cart_item_hash_id)"
                    >{{ $t('fo-remove') }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" v-if="store.currency_code">
      <v-row align="start" dense>
        <v-col cols="auto me-auto"> {{ $t('fo-subtotal') }} </v-col>
        <v-col cols="auto"> {{ $money.formatMoney(store.currency_code, subtotal) }} </v-col>
      </v-row>
      <v-row v-if="hasDeliveryFee" align="start" dense>
        <v-col cols="auto me-auto">{{ $t('fo-delivery-fee') }} </v-col>
        <v-col cols="auto"> 
          <span v-if="store.delivery_fee > 0" >{{ $money.formatMoney(store.currency_code, store.delivery_fee/100) }}</span>
          <v-chip v-else color="primary" small>{{ $t("fo-free") }}</v-chip>
         </v-col>
      </v-row>
      <v-row align="start" dense class="font-weight-black mt-3">
        <v-col cols="auto me-auto"> {{ $t('fo-total-payable') }} </v-col>
        <v-col cols="auto"> {{ $money.formatMoney(store.currency_code, total) }} </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    hasRemoveButton: {
      type: Boolean,
      default: true,
    },
    hasDeliveryFee: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState("cart", ["cart"]),
    ...mapState("store", ["store"]),
    totalOrderItems() {
      if(this.cart.items.length > 0){
      let total = this.cart.items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
      return total/100 }
      else { return 0 }
    },
    totalComplementsItems() {
      const total = this.cart.items.reduce((result, item) => {
        return result + (item.quantity * item.cart_complements.reduce((a, complement) => a + complement.price, 0));
      }, 0);
      return total/100
    },
    subtotal() {
      return this.totalOrderItems + this.totalComplementsItems
    },
    total() {
      return this.hasDeliveryFee == true ? this.subtotal + this.store.delivery_fee/100 : this.subtotal;
    },
  },
  methods: {
    proceed() {
      if (this.subtotal < this.store.min_order / 100) {
        this.$notifications.showError({
          message: `${this.$t('fo-order-value-must-be-at-least')} ${this.$money.formatMoney(this.store.currency_code, this.store.min_order / 100)}`,
        });
      } else {
        this.store.need_register == 1 && !this.$auth.isLoggedIn()
          ? this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}/login` })
          : this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}/counter`});
      }
    },
  }
};
</script>

<style lang="scss">
.custom-items {
  max-height: 400px;
  overflow-y: auto;
  @media only screen and (max-height: 750px) {
    max-height: 250px;
  }
}
</style>