<template>
  <v-container class="px-4 py-10">
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-black primary--text">Dashboard</div>
        <div class="text-body-1 font-weight-black secondary--text text--darken-4">
          {{ $t("fo-welcome-to-builderall-delivery") }}
        </div>
      </v-col>
      <v-col v-if="store.hash_id" cols="12" align="center">
        <v-card class="rounded-lg pa-3" flat>
          <v-card-title class="text-h5">{{ $t("fo-here-os-the-link-to-your-store") }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="start" dense>
              <v-col cols="12">
                <Link :link="link" />
              </v-col>
              <v-col cols="auto">
                <v-row no-gutters class="flex-column" align="start">
                  <v-col cols="auto">
                    <v-alert
                      dense
                      text
                      border="left"
                      icon="mdi-information-outline"
                      color="warning"
                    >
                      {{ $t("fo-this-link-should-not-be-embedded") }}
                    </v-alert>
                  </v-col>
                  <v-col cols="auto">
                    <v-alert
                      dense
                      text
                      border="left"
                      icon="mdi-information-outline"
                      color="info"
                    >
                      {{ $t("fo-for-a-better-experience") }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Link from "@/components/shared/Link";
export default {
  components: {
    Link,
  },
  computed: {
    ...mapState("store", ["store"]),
    link() {
      return this.store.store_url ? `${this.store.store_url}` : `${process.env.VUE_APP_URL}/delivery/${this.store.url_slug}/${this.store.hash_id}`;
    },
  },
};
</script>

<style>
</style>