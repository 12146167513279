
// initial state
const initialState = () => ({
    additional: {
        hash_id: null,
        complement_group_hash_id: "",
        name: "",
        description: "",
        price: 0,
        image: null,
    },
    additionalGroup: {
        hash_id: null,
        name: "",
        description: "",
        maximum: "",
        mandatory: null,
    },
    additionals: [],
    groupAdditionals: []
});

const state = initialState;

//getters
const getters = {

}

// mutations
const mutations = {
    updateAdditionalInfo(state, { key, value }) {
        state.additional[key] = value;
    },
    updateAdditionalGroupInfo(state, { key, value }) {
        state.additionalGroup[key] = value;
    },
    setAdditionals(state, payload) {
        state.additionals = payload
    },
    setGroupAdditionals(state, payload) {
        state.groupAdditionals = payload
      },
    setAdditional(state, payload) {
        state.additional.hash_id = payload.hash_id
        state.additional.complement_group_hash_id = payload.complement_group_hash_id
        state.additional.name = payload.name
        state.additional.description = payload.description
        state.additional.price = payload.price
        state.additional.image = payload.image
    },
    setAdditionalGroup(state, payload) {
        state.additionalGroup.hash_id = payload.hash_id
        state.additionalGroup.name = payload.name
        state.additionalGroup.description = payload.description
        state.additionalGroup.maximum = payload.maximum
        state.additionalGroup.mandatory = payload.mandatory
    },
    reset(state) {
        Object.assign(state, initialState())
    },
    resetAdditional(state) {
        state.additional.hash_id = null,
        state.additional.complement_group_hash_id = "",
        state.additional.name = "",
        state.additional.description = "",
        state.additional.price = 0,
        state.additional.image = null
    },
    resetAdditionalGroup(state) {
        state.additionalGroup.hash_id = null,
        state.additionalGroup.name = "",
        state.additionalGroup.description = "",
        state.additionalGroup.maximum = "",
        state.additionalGroup.mandatory = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}