<template>
  <v-navigation-drawer
    color="primary"
    v-model="navigation"
    :right="$vuetify.rtl"
    v-click-outside="{ handler: onClickOutsideWithConditional, closeConditional, }"
    app
    dark
    width="200"
  >
   <v-list color="primary" v-if="$vuetify.breakpoint.xsOnly">
      <v-list-item link v-if="$auth.user()">
        <v-list-item-content>
          <v-list-item-title class="text-h6 white--text">
            {{ `${$t("fo-hello")} ${$auth.user().name}` }}
          </v-list-item-title>
          <v-list-item-subtitle class="white--text">{{
            $auth.user().email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list flat shaped v-if="$auth.isLoggedIn()">
      <v-list-item
        :to="`/delivery/${$route.params.slug}/${$route.params.id}${item.link}`"
        v-for="item in items"
        :key="item.title"
        link
        exact-active-class="primary--text white"
        :ripple="false"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="openAddressModal">
         <v-list-item-icon>
          <v-icon>mdi-image</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('fo-address') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item v-if="$auth.isLoggedIn()">
        <v-btn
          color="secondary"
          class="primary--text rounded-lg"
          depressed
          large
          block
          @click="logout"
          >{{ $t("fo-logout") }}</v-btn
        >
      </v-list-item>
      <v-list-item v-if="!$auth.isLoggedIn()">
        <v-btn
          color="secondary"
          :ripple="false"
          :to="`/delivery/${$route.params.slug}/${$route.params.id}/login`"
          depressed
          large
          block
          class="rounded-lg primary--text"
          >{{ $t("fo-login") }}</v-btn
        >
      </v-list-item>
      <v-list-item v-if="!$auth.isLoggedIn()">
        <v-btn
          color="secondary"
          :ripple="false"
          :to="`/delivery/${$route.params.slug}/${$route.params.id}/register`"
          large
          class="rounded-lg"
          outlined
          block
          depressed
          >{{ $t("fo-register") }}</v-btn
        >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", link: "" },
        {
          title: this.$t("fo-orders"),
          icon: "mdi-pencil",
          link: "/account/orders",
        },
        { title: this.$t('fo-profile'), icon: "mdi-account", link: "/account/profile" },
      ],
    };
  },
  computed: {
    navigation: {
      get() {
        return this.$store.state.toggle.userNavigation;
      },
      set(value) {
        return this.$store.commit("toggle/toggleUserNavigation", value);
      },
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    onClickOutsideWithConditional () {
      this.navigation = false;
    },
    closeConditional () {
      return this.navigation;
    },
    openAddressModal() {
      this.$store.commit("toggle/toggleAddressModal", true);
    }
  }
};
</script>

<style>
</style>