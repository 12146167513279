<template>
  <div>
    <Alert />
    <v-stepper style="box-shadow: none" v-model="step" class="fill-height">
      <v-stepper-items>
        <v-stepper-content step="0" :class="{ 'px-0': $vuetify.breakpoint.xs }">
          <Cart>
            <template v-slot="{ getCart }">
              <Order @getCart="getCart" />
            </template>
          </Cart>
        </v-stepper-content>
         <v-stepper-content step="1" :class="{ 'px-0': $vuetify.breakpoint.xs }">
            <Payment />
        </v-stepper-content>
        <v-stepper-content step="2" :class="{ 'px-0': $vuetify.breakpoint.xs }">
          <SharedOrder>
            <template v-slot="{ createOrder }">
              <Review @createOrder="createOrder" />
            </template>
          </SharedOrder>
        </v-stepper-content>
        <v-stepper-content step="3" :class="{ 'px-0': $vuetify.breakpoint.xs }">
          <OrderPlacedCard />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Alert from "@/components/shared/Alert";
import Order from "@/components/shop/default/Counter/Order";
import SharedOrder from "@/components/shared/Order";
import Review from "@/components/shop/default/Counter/OrderReview";
import OrderPlacedCard from "@/components/shop/default/Counter/OrderPlacedCard";
import Payment from "@/components/shop/default/Counter/Payment"
import Cart from "@/components/shared/Cart";

export default {
  components: {
    Cart,
    Alert,
    Order,
    Review,
    SharedOrder,
    OrderPlacedCard,
    Payment
  },
  created() {
    this.$store.commit("order/setStep", 0);
     if(!this.cart.hash_id) {
      this.back();
    }
  },
  methods: {
    back() {
      this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}` });
    },
  },
  computed: {
    ...mapState("order", ["step"]),
    ...mapState("cart", ["cart"]),
  },
};
</script>

<style scoped>
</style>