<template>
  <v-row>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <span class="font-weight-bold">{{ $t('fo-by-category') }}</span>
        </v-col>
        <v-col cols="12">
          <Categories>
            <template>
              <div v-if="selectedFilters.length > 0">
                <v-chip
                  v-for="(selected, index) in selectedFilters"
                  color="primary"
                  class="ma-1"
                  :key="index"
                  close
                  @click:close="removeCategory(index)"
                >
                  <strong>{{ selected.description }}</strong>
                </v-chip>
              </div>
              <v-list
                v-for="(option, index) in activeCategories"
                :key="index"
                flat
                class="ma-0 pa-0"
                style="max-height: 30px"
              >
                <v-list-item
                  class="ma-0 pa-0"
                  :ripple="false"
                  v-if="!selectedFilters.includes(option)"
                  @click="selectedCategory(option)"
                >
                  <v-list-item-content class="ma-0 pa-0">
                    <v-list-item-title
                      class="
                        text-xl-body-1
                        text-lg-caption
                        text-md-caption
                        text-body-1
                      "
                      >{{ option.description }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </Categories>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <span class="font-weight-bold">{{ $t('fo-by-price') }}</span>
        </v-col>
        <v-col cols="12">
          <v-range-slider
            v-model="range"
            :max="max"
            :min="min"
            hide-details
          ></v-range-slider>
        </v-col>
        <v-col cols="12">
          <span class="grey--text">{{
            $t('fo-price') + ' ' + $money.formatMoney(store.currency_code, range[0]) + " - " + $money.formatMoney(store.currency_code, range[1])
          }}</span>
        </v-col>
        <v-col cols="12" class="mt-2">
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            large
            @click="$emit('searchProductPrice', range)"
            class="rounded-lg"
            >{{ $t('fo-apply') }}</v-btn
          >
          <v-btn
            color="primary"
            :ripple="false"
            depressed
            large
            @click="resetFilter"
            class="rounded-lg ms-3"
            >{{ $t('fo-reset') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Categories from "@/components/shared/Categories";
import { mapState } from "vuex";
export default {
  components: {
    Categories,
  },
  data() {
    return {
      chip1: true,
      min: 0,
      max: 1000,
      range: [0, 1000],
      selectedFilters: [],
    }
  },
  methods: {
    selectedCategory(option) {
      this.selectedFilters.push(option);
      this.$emit("searchProductCategory", this.selectedFilters);
    },
    removeCategory(index) {
      this.selectedFilters.splice(index, 1);
      this.$emit("searchProductCategory", this.selectedFilters);
    },
    resetFilter() {
      this.selectedFilters = [];
      this.range = [0,1000];
      this.$emit("searchProductCategory", this.selectedFilters);
      this.$emit('searchProductPrice', this.range);
    }
  },
  computed: {
    ...mapState("category", ["categories"]),
    ...mapState("store", ["store"]),
    activeCategories() {
      return !this.categories.length || this.categories.filter((category) => { return category.active == 1 })
    }
  },
};
</script>

<style>
</style>
