<template>
  <Store>
    <template v-slot="{ getUrlByDomain }">
      <Redirect @getUrlByDomain="getUrlByDomain" />
    </template>
  </Store>
</template>

<script>
import Store from "@/components/shared/Store";
import Redirect from "@/components/shop/default/Redirect";
export default {
  components: {
    Store,
    Redirect,
  },
};
</script>

<style>
</style>