<template>
  <v-form @submit.prevent ref="stripeForm" v-model="valid" lazy-validation>
    <v-row dense class="flex-column">
      <v-col>
        <v-text-field
          :label="$t('fo-card-name')"
          :rules="cardHolderName"
          outlined
          :value="order.payment.customer.name"
          @input="updatePaymentCustomer('name', $event)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :label="$t('fo-email')"
          :rules="emailRules"
          required
          outlined
          :value="order.payment.customer.email"
          @input="updatePaymentCustomer('email', $event)"
        ></v-text-field>
      </v-col>
      <v-col>
        <div ref="card" id="custom-stripe-form"></div>
      </v-col>
      <v-col class="mt-4">
        <v-btn depressed large :disabled="!valid" color="primary" block @click="validate">{{ $t("fo-proceed") }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
export default {
  mixins: [validationRules],
  data() {
    return {
      stripe: {},
      elements: {},
      cardElement: {},
      valid: true
    };
  },
  async mounted() {
    this.stripe = await loadStripe(this.publicKey, { locale: this.$i18n.locale });
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create("card");
    this.cardElement.mount(this.$refs.card);
    this.setBuyerInfo();
  },
  methods: {
    updatePaymentCustomer(key, value) {
      this.$store.commit("order/updatePaymentCustomer", { key: key, value });
    },
    createPayment() {
      this.stripe.createToken(this.cardElement, this.order.payment.customer).then((result) => { 
        if(result.token) {
            this.$store.commit("order/updatePayment", { key: "card_token", value: result.token.id });
            this.$store.commit("payment/updateCard", result.token);
            this.$store.commit("order/setStep", 2);
        } 
        if(result.error) {
            this.$notifications.showError({ message: result.error.message });
        }
    });
    },
    validate() {
      if(this.$refs.stripeForm.validate()) {
        this.createPayment();
      }
    },
    setBuyerInfo() {
      this.updatePaymentCustomer('name', this.$auth.user() ? this.$auth.user().name : this.order.buyer_name);
      this.updatePaymentCustomer('email', this.$auth.user() ? this.$auth.user().email : null);
    }
  },
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("store", ["store"]),
    publicKey() {
      return this.store.owner_gateway ? this.store.owner_gateway.config.pk : null
    }
  }
};
</script>

<style>
#custom-stripe-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #0000006b;
  font-size: 16px;
  height: 56px;
}
</style>