<template>
  <Authentication />
</template>

<script>
import Authentication from "@/components/shared/Authentication";
export default {
  components: {
    Authentication
  },
 
};
</script>

<style>
</style>