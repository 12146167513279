<template>
  <div>
    <slot :login="login" :register="register" :forgotPassword="forgotPassword" :recoverPassword="recoverPassword" />
  </div>
</template>

<script>
import Repository from "@/repositories/RepositoryFactory";
const BuyerRepository = Repository.get("buyer");
const OwnerRepository = Repository.get("owner");
export default {
  methods: {
    async login(payload) {
      this.$store.commit("buyer/setLoading", true);
      try {
        const { data } = await BuyerRepository.login(this.$route.params.id, payload);
        const token = data.access_token;
        const user = data.user;
        this.$auth.setToken(token);
        this.$auth.setUser(user);
        if(this.getCartId()) {
          this.setBuyerCart(this.getCartId())
        }
        this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}` });
        this.$store.commit("buyer/setLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("buyer/setLoading", false);
      }
    },
    async register(payload) {
      try {
        await BuyerRepository.register(this.$route.params.id, payload);
        this.login(payload);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async forgotPassword(payload) {
      this.$store.commit("buyer/setLoading", true);
      try {
        const {data} = await BuyerRepository.forgotPassword(this.$route.params.id, payload);
        this.$store.commit("buyer/setLoading", false);
        this.$notifications.showSuccess({ message: 'fo-email-sent' });
        this.$emit('emailSent', data);
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}`});
        this.$store.commit("buyer/setLoading", false);
      }
    },
    async recoverPassword(payload) {
      this.$store.commit("buyer/setLoading", true);
      try {
        this.$route.query.email ? payload.email = this.$route.query.email : payload.email = null;
        const {data} = await BuyerRepository.recoverPassword(this.$route.params.id, payload);
        this.$store.commit("buyer/setLoading", false);
        this.$notifications.showSuccess({ message: 'fo-password-update-successful' });        
        this.redirectToLogin(data);
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}`});
        this.$store.commit("buyer/setLoading", false);
      }
    },
    async ownerLogin(queryToken) {
      try {
        const { data } = await OwnerRepository.login({
          params: { token: queryToken },
        });
        const token = data.access_token;
        this.$cookies.remove("auth_token");
        this.$auth.setToken(token);
        this.$auth.getUser();
        this.$router.push({ path: "/owner/dashboard" });
      } catch (error) {
        window.location.href = process.env.VUE_APP_OFFICE_URL;
        this.$notifications.showError({ code: error });
      }
    },
    // async getStores(id) {
    //   try {
    //     const { data } = await OwnerRepository.getStores(id);
    //     if(data.length) {
    //        this.$store.commit("owner/setStore", data[0]);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
     async setBuyerCart(id) {
      try {
        await BuyerRepository.setBuyerCart(id);
        this.$cookies.remove("cart_hash");
      } catch (error) {
        console.log(error);
      }
    },
     getCartId() {
      let cartHash = this.$cookies.get("cart_hash");
      return cartHash;
    },
    redirectToLogin(data) {
      let redirect = "";
        if (data.status) {
            redirect = setInterval(() => {
              this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}/login`});
              clearInterval(redirect);
            }, 2000);
        }        
    }
  },
  created() {
    if (this.$route.query.token) {
      let queryToken = this.$route.query.token;
      this.ownerLogin(queryToken);
    }
  },
};
</script>