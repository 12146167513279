<template>
  <Config>
    <router-view />
  </Config>
</template>

<script>
import Config from "@/components/shared/Config";
export default {
  components: {
    Config,
  },
};
</script>

<style>
</style>