import client from './Clients/AxiosClient';
const resource = '/owner';

export default {
    login(params) {
        return client.post(`${resource}/login`, null, params);
    },
    getStores(id) {
        return client.get(`${resource}/${id}/stores`);
    },
    getUser() {
        return client.get(`${resource}/user`);
    }
};