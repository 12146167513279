import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from "./i18n";
import notifier from './plugins/notifier'
import monetaryMask from './plugins/monetaryMask'
import formatDate from './plugins/formatDate'
import validateCpf from './plugins/validateCpf'
import auth from './plugins/auth'
import VueCookies from 'vue-cookies';
import VueCountryCode from "vue-country-code";
import "./plugins/vuetify-money.js";
import "../public/main.css";
import "@fontsource/lato"
import VueHtmlToPaper from './plugins/html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    "/print/vuetify.css",
    "/print/print.css"
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);
Vue.use(notifier, store);
Vue.use(monetaryMask, i18n);
Vue.use(formatDate, i18n);
Vue.use(validateCpf);
Vue.use(auth, store);
Vue.use(VueCookies, { expires: '6m', path: '/', domain: '', secure: '', sameSite: 'Lax' });
Vue.use(VueCountryCode);

Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  router,
  store,
  async beforeCreate() {
    if (this.$auth.isLoggedIn()) {
      const user = await this.$auth.getUser();
      if(this.$auth.getScope().includes('buyer') && user.hash_store_id != this.$route.params.id) {
        this.$auth.setToken(false);
        this.$store.dispatch('clearAll');
      }
    }
    if(this.$i18n.locale == 'he') {
      this.$vuetify.rtl = true
    }
  },
  // created() {
  //   if(this.$cookies.isKey('reload')) {
  //     this.$router.go();
  //     this.$cookies.remove('reload')
  //   }
  // },
  render: h => h(App)
}).$mount('#app')
