<template>
  <Cart>
    <template v-slot="{ removeItemCart, getCart }">
      <CartDrawer @removeItemCart="removeItemCart"  @getCart="getCart"/>
    </template>
  </Cart>
</template>

<script>
import Cart from "@/components/shared/Cart";
import CartDrawer from "@/components/shop/default/CartDrawer";
export default {
  components: {
    Cart,
    CartDrawer,
  },
};
</script>

<style>
</style>