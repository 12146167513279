
// initial state
const state = () => ({
    message: '',
    code: '',
    color: '',
    type: ''
});

// mutations
const mutations = {
    showMessage(state, payload) {
        state.message = payload.message
        state.code = payload.code
        state.color = payload.color
        state.type = payload.type
    }
}

export default {
    namespaced: true,
    state,
    mutations
}