var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-img',{staticClass:"rounded-lg custom-image",attrs:{"src":_vm.src,"height":_vm.height,"dark":"","contain":""},on:{"click":_vm.onButtonClick}}),_c('div',{staticClass:"text-caption",class:_vm.imageError ? 'red--text font-weight-bold' : 'grey--text'},[_vm._v(_vm._s(_vm.$t("fo-1-MB-max-file-size")))]),(_vm.hasText)?_c('div',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(((_vm.$t("fo-dimensions")) + ": 900x700 px (" + (_vm.$t("fo-recommended")) + ")")))]):_vm._e(),_c('v-file-input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"image/png, image/jpeg"},on:{"change":_vm.onFileChanged},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),_c('v-dialog',{attrs:{"eager":"","scrollable":"","persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("fo-crop-image")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("fo-resize-image")))]),_c('v-card-text',[_c('cropper',{ref:"cropper",staticClass:"custom-cropper",attrs:{"src":_vm.src,"stencil-size":{
              width: 900,
              height: 700
            },"stencil-props":{
              handlers: {},
              movable: false,
              resizable: false,
            },"image-restriction":"stencil","moveImage":{
            touch: true,
            mouse: true
          }}})],1),_c('v-card-actions',{staticClass:"pb-5 px-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg mx-2",attrs:{"color":"primary","ripple":false,"depressed":"","large":""},on:{"click":_vm.uploadImage}},[_vm._v(" "+_vm._s(_vm.$t("fo-crop"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }