<template>
  <div>
    <Products v-if="store.hash_id">
      <template
        v-slot="{
          products,
          getStoreProducts,
          setProduct,
          searchProduct,
          searchProductCategory,
          searchProductPrice,
        }"
      >
        <v-container>
          <v-card class="rounded-xl pa-5"  v-if="$vuetify.breakpoint.xsOnly">
            <v-row>
              <v-col cols="auto" class="me-auto">
                <v-row align="center">
                  <v-col>
                    <v-img
                      :src="store.logo_url ? store.logo_url : require('@/assets/images/no-photo.png')"
                      width="80"
                    />
                  </v-col>
                  <v-col
                    v-if="store.name"
                    cols="auto"
                    class="
                      font-weight-bold
                      text-h6
                    "
                  >
                    {{ store.name }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <v-row class="mt-3" align="center">
            <v-col cols="auto" v-if="products.length > 0">
              <span>{{ `${products.length} ${$t("fo-results")}` }}</span>
            </v-col>
            <v-col cols="auto" class="ms-auto" v-if="$vuetify.breakpoint.smAndUp">
              <v-row align="center" class="text-subtitle">
                <v-col cols="auto" class="align-items-center grey--text">
                  <v-icon small color="grey" left class="me-2">
                    mdi-currency-usd
                  </v-icon>
                  <span>{{ `${$t("fo-min-order")} ${$money.formatMoney(store.currency_code, store.min_order / 100)}` }}</span>
                </v-col>
                <v-divider vertical inset class="my-3"></v-divider>
                <v-col cols="auto" class="align-items-center grey--text">
                  <span v-if="store.delivery_fee > 0 && store.delivery_types.includes('DELIVERY')">
                    <v-icon small color="grey" left class="me-2">
                      mdi-moped-outline
                    </v-icon>
                    {{ $money.formatMoney(store.currency_code, store.delivery_fee / 100) }}</span>
                  <v-chip v-else color="primary" small>
                    <v-icon small left class="me-2">
                      mdi-moped-outline
                    </v-icon>
                    {{ store.delivery_types.includes('DELIVERY') ? $t("fo-free-delivery") : $t("fo-only-withdraw") }}</v-chip>
                </v-col>
                <v-divider vertical inset class="my-3"></v-divider>
                <v-col cols="auto" class="align-items-center grey--text">
                  <v-icon small color="grey" left class="me-2">
                    mdi-clock-time-three-outline
                  </v-icon>
                  <span>{{ `${store.delivery_time_min} - ${store.delivery_time_max} ${$t("fo-min")}` }}</span>
                </v-col>
                <v-divider vertical inset class="my-3"></v-divider>
                <v-col cols="auto">
                  <v-btn @click.stop="infoDrawer = !infoDrawer" text color="primary" depressed :ripple="false" class="text-none font-weight-bold pa-0 ma-0">{{ $t('fo-more-info') }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.xsOnly" cols="6" class="ml-auto">
              <v-btn
                color="primary"
                width="100%"
                outlined
                class="rounded-lg"
                dark
                @click.stop="filterModal = !filterModal"
              >
                {{ $t("fo-filters") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12">
              <v-row>
                <v-col cols="12">
                  <SearchInput @search="searchProduct" />
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12">
                  <v-card class="fill-height pa-5" flat>
                    <Filters
                      @searchProductCategory="searchProductCategory"
                      @searchProductPrice="searchProductPrice"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
           <ProductList @getProducts="getStoreProducts" :products="products" @setProduct="setProduct" />
          </v-row>
          <Cart>
            <template v-slot="{ addItemCart }">
              <ProductModal @addItemCart="addItemCart" />
            </template>
          </Cart>
          <v-dialog v-model="filterModal" scrollable>
            <v-card class="text-center rounded-xl">
              <v-card-title></v-card-title>
              <v-card-text>
                <Filters
                    @searchProductCategory="searchProductCategory($event); closeFilterModal()"
                    @searchProductPrice="searchProductPrice($event); closeFilterModal()"
                  />
              </v-card-text>
              <v-card-actions></v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </template>
    </Products>
     <InfoDrawer />
  </div>
</template>

<script>
import Cart from "@/components/shared/Cart";
import Products from "@/components/shared/Products";
import ProductModal from "@/components/shop/default/ProductModal";
import ProductList from "@/components/shop/default/ProductList";
import Filters from "@/components/shop/default/Filters";
import SearchInput from "@/components/shop/default/SearchInput";
import InfoDrawer from "@/components/shop/default/InfoDrawer";

import { mapState } from "vuex";
export default {
  components: {
    Cart,
    Products,
    ProductModal,
    ProductList,
    Filters,
    SearchInput,
    InfoDrawer
  },
  data() {
    return {
      chip1: true,
      min: -50,
      max: 90,
      range: [-20, 70],
    };
  },
  computed: {
    ...mapState("toggle", ["loading", "filterModal"]),
    ...mapState("store", ["store"]),
     infoDrawer: {
      get() {
        return this.$store.state.toggle.infoDrawer;
      },
      set(value) {
        return this.$store.commit("toggle/toggleInfoDrawer", value);
      },
    },
    filterModal: {
      get() {
        return this.$store.state.toggle.filterModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleFilterModal", value);
      },
    },
  },
  methods: {
    closeFilterModal() {
      this.filterModal = false
    }
  }
};
</script>

<style>
.custom-teste{
  max-height: 600px;
  overflow-y: scroll;
}
</style>