
// initial state
const initialState = () => ({
    info: {
      hash_id: "",
      description: "",
      active: 1,
      store_hash_id: null,
    },
    categories: [],
    loading: false
  });
  
const state = initialState;

  //getters
  const getters = {
 
  }
  
  // mutations
  const mutations = {
    updateCategoryInfo(state, { key, value }) {
      state.info[key] = value;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setCategories(state, payload) {
      state.categories = payload
    },
    setCategory(state, payload) {
      state.info.hash_id = payload.hash_id
      state.info.description = payload.description
      state.info.active = payload.active
      state.info.store_hash_id = payload.store_hash_id
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    resetCategory(state) {
      state.info.hash_id = "",
      state.info.description = "",
      state.info.active = 1,
      state.info.store_hash_id = null
    },
    reset(state) {
        Object.assign(state, initialState())
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  }