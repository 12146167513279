<template>
  <Products>
    <template v-slot="{ getStoreProducts, deleteProduct }">
      <v-container class="px-4 py-10">
        <v-row>
          <v-col cols="12">
            <v-row align="center">
              <v-col cols="auto me-auto">
                <div class="text-h5 font-weight-black primary--text">
                  {{ $t("fo-products") }}
                </div>
                <div class="text-body-1 font-weight-black secondary--text text--darken-4">
                  {{ $t("fo-create-and-update-products") }}
                </div>
              </v-col>
              <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto">
                <v-btn
                  color="primary"
                  :ripple="false"
                  width="100%"
                  large
                  depressed
                  class="rounded"
                  :to="{ path: `products/new` }"
                  >{{ $t("fo-new") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <ProductList
            @getProducts="getStoreProducts"
            @delete="deleteProduct"
          />
        </v-row>
      </v-container>
    </template>
  </Products>
</template>

<script>
import Products from "@/components/shared/Products";
import ProductList from "@/components/owner/ProductList";
export default {
  components: {
    Products,
    ProductList,
  },
};
</script>

<style>
</style>