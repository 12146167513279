<template>
  <div>
    <v-img :src="src" :height="height" dark class="rounded-lg custom-image" contain  @click="onButtonClick">
    </v-img>
    <div
      class="text-caption"
      :class="imageError ? 'red--text font-weight-bold' : 'grey--text'"
      >{{ $t("fo-1-MB-max-file-size") }}</div
    >
    <div v-if="hasText" class="grey--text text-caption">{{ `${$t("fo-dimensions")}: 900x700 px (${$t("fo-recommended")})` }}</div>
    <v-file-input
      ref="uploader"
      v-model="selectedFile"
      class="d-none"
      type="file"
      accept="image/png, image/jpeg"
      @change="onFileChanged"
    ></v-file-input>
    <v-dialog v-model="dialog" eager scrollable persistent max-width="500px">
      <v-card class="rounded-lg">
        <v-card-title>{{ $t("fo-crop-image") }}</v-card-title>
        <v-card-subtitle>{{ $t("fo-resize-image") }}</v-card-subtitle>
          <v-card-text>
            <cropper
              class="custom-cropper"
              ref="cropper"
              :src="src"
              :stencil-size="{
                width: 900,
                height: 700
              }"
              :stencil-props="{
                handlers: {},
                movable: false,
                resizable: false,
              }"
              image-restriction="stencil"
              :moveImage="{
              touch: true,
              mouse: true
            }"
            />
          </v-card-text>
        <v-card-actions class="pb-5 px-5">
          <v-spacer></v-spacer>
          <v-btn
            @click="uploadImage"
            color="primary"
            :ripple="false"
            depressed
            large
            class="rounded-lg mx-2"
          >
            {{ $t("fo-crop") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
export default {
  components: {
		Cropper,
	},
  props: {
    height: {
      type: Number,
    },
    image: {
      type: String,
    },
    image_url: {
      type: String,
    },
    hasCrop: {
      type: Boolean,
      default: false
    },
    hasText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      img: null,
      selectedFile: null,
      imageError: null,
      dialog: false
    };
  },
  methods: {
    onButtonClick() {
      this.$refs.uploader.$refs.input.click();
    },
    onFileChanged() {
      const reader = new FileReader();
      if (this.selectedFile != null) {
        reader.readAsDataURL(this.selectedFile);
        reader.onload = () => {
          if (this.selectedFile.size <= 1000000) {
            this.img = reader.result;
            this.valid = true;
            this.imageError = false;
            if(this.hasCrop) {
              this.dialog = true;
            }
          } else {
            this.img = require("@/assets/images/no-photo.png");
            this.$notifications.showError({
              message: "fo-must-be-smaller-than-1MB",
            });
            this.valid = false;
            this.imageError = true;
          }
        };
      }
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        this.img = canvas.toDataURL();
        this.dialog = false;
      }
    }
  },
  computed: {
    src() {
      if (this.image_url && !this.img) {
        return this.image_url;
      }
      if (this.img) {
        this.$emit("update", this.img);
        return this.image;
      } else {
        return require("@/assets/images/no-photo.png");
      }
    },
  },
};
</script>

<style>
.custom-image {
  cursor: pointer;
}
.custom-cropper {
  cursor: move;
  height: 1000px;
}
</style>