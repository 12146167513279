import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import toggle from './modules/toggle'
import alert from './modules/alert'
import buyer from './modules/buyer'
import store from './modules/store'
import order from './modules/order'
import product from './modules/product'
import cart from './modules/cart'
import category from './modules/category'
import auth from './modules/auth'
import additional from './modules/additional'
import domain from './modules/domain'
import payment from './modules/payment'
import gateway from './modules/gateway'
import store_status from './modules/store_status'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    toggle,
    alert,
    buyer,
    store,
    order,
    product,
    cart,
    category,
    auth,
    additional,
    domain,
    payment,
    gateway,
    store_status
  },
  actions: {
    clearAll({ commit }){
      commit("user/reset")
      commit("buyer/reset")
      commit("store/reset")
      commit("order/reset")
      commit("product/reset")
      commit("cart/reset")
      commit("category/reset")
      commit("auth/reset")
      commit("additional/reset")
      commit("domain/reset")
    }
  }
})