<template>
  <svg class="custom-icon" width="34" height="32" viewBox="0 0 34 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1867 32C12.183 32 11.2204 31.5786 10.5107 30.8284C9.801 30.0783 9.40229 29.0609 9.40229 28C9.40229 26.9391 9.801 25.9217 10.5107 25.1716C11.2204 24.4214 12.183 24 13.1867 24C14.1904 24 15.1529 24.4214 15.8626 25.1716C16.5724 25.9217 16.9711 26.9391 16.9711 28C16.9711 29.0609 16.5724 30.0783 15.8626 30.8284C15.1529 31.5786 14.1904 32 13.1867 32ZM26.432 32C25.4284 32 24.4658 31.5786 23.7561 30.8284C23.0464 30.0783 22.6477 29.0609 22.6477 28C22.6477 26.9391 23.0464 25.9217 23.7561 25.1716C24.4658 24.4214 25.4284 24 26.432 24C27.4357 24 28.3983 24.4214 29.108 25.1716C29.8177 25.9217 30.2164 26.9391 30.2164 28C30.2164 29.0609 29.8177 30.0783 29.108 30.8284C28.3983 31.5786 27.4357 32 26.432 32ZM1.76161 3.846C1.28925 3.8299 0.841324 3.62024 0.512569 3.26136C0.183814 2.90249 0 2.42254 0 1.923C0 1.42346 0.183814 0.943509 0.512569 0.584635C0.841324 0.225761 1.28925 0.0161036 1.76161 0H3.93953C5.64629 0 7.1222 1.252 7.49307 3.012L9.86399 14.296C10.2349 16.056 11.7108 17.308 13.4175 17.308H27.6317L30.3602 5.768H12.6777C12.2098 5.74543 11.7681 5.53308 11.4445 5.1751C11.1209 4.81713 10.9404 4.34109 10.9404 3.846C10.9404 3.35091 11.1209 2.87487 11.4445 2.5169C11.7681 2.15892 12.2098 1.94657 12.6777 1.924H30.3602C30.9134 1.92383 31.4593 2.05696 31.9565 2.31327C32.4536 2.56958 32.889 2.94232 33.2294 3.40317C33.5698 3.86401 33.8063 4.40082 33.921 4.97279C34.0356 5.54476 34.0254 6.13684 33.8911 6.704L31.1625 18.24C30.9658 19.0724 30.5114 19.8114 29.8717 20.3394C29.2319 20.8674 28.4434 21.1541 27.6317 21.154H13.4175C11.7615 21.1542 10.1549 20.5574 8.86304 19.4622C7.57122 18.367 6.67145 16.8388 6.31234 15.13L3.93953 3.846H1.76161Z"/>
   </svg>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon {
    fill: currentColor;
}
</style>