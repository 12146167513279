import axios from "axios";
// import store from '@/store';
import Vue from 'vue'
// import router from '@/router';


const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}/api`; // Incase of /api/v1;

const instance = axios.create({
  baseURL,
  timeout: 15000
});

instance.interceptors.request.use(
  config => {
    const token = Vue.prototype.$auth.getToken();
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

instance.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && originalRequest.url === '/login') {
    this.$router.push({ path: "/login" });
    return Promise.reject(error);
  }

  // if (error.response.status === 401 && !originalRequest._retry) {
  // originalRequest._retry = true;
  // router.go()
  // return axios.post(`${process.env.VUE_APP_API_URL}/api/auth/refresh`, {},
  //   {
  //     headers: {
  //       'Authorization': `Bearer ${store.getters.token}`,
  //       'Accept': 'application/json',
  //     }
  //   })
  //   .then(res => {
  //     store.commit("login", res.data.access_token);
  //     originalRequest.headers = {
  //       'Authorization': `Bearer ${res.data.access_token}`,
  //       'Accept': 'application/json',
  //     }
  //     return axios(originalRequest);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     this.$router.push({ path: "/login" });
  //   })
  // }
  return Promise.reject(error);
});



export default instance;


