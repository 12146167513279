import client from './Clients/AxiosClient';
const resource = '/owner-gateway';

export default {
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id, store_id) {
        return client.delete(`${resource}/${id}/${store_id}`);
    },
    getGatewayById(id) {
        return client.get(`${resource}/${id}`);
    },
    getGatewayByOwner(params) {
        return client.get(`${resource}`, params);
    }
};