
// initial state
const initialState = () => ({
    step: 0,
    loading: false,
    valid: false,
    disable_resend_email: false,
    info: {
      name: "",
      email: "",
      phone: "",
      password: null,
      confirm_password: null,
      country: "",
      language: "",
      currency: "",
      store_id: "",
      new_password: "",
      birth_date: null,
  },
  address: {
        hash_id: null,
        title: null,
        zip_code: null,
        address: null,
        // street: null,
        city_name: null,
        state_name: null,
        // neighborhood: null,
        // complement: null,
        // number: null,
        main_address: 0
    },
    addresses: [],
    card: {
        number: null,
        name_printed: null, 
        expire_date: null, 
        cvv: null, 
        nickname: null, 
        documento: null
    },
    buyers: {}
  });
  
  const state = initialState;
  
  //getters
  const getters = {
 
  }
  
  // mutations
  const mutations = {
    updateBuyerInfo(state, { key, value }) {
      state.info[key] = value;
    },
    setBuyer(state, payload) {
      state.info.name = payload.name,
      state.info.email = payload.email,
      state.info.phone = payload.phone,
      state.info.password = payload.password,
      state.info.confirm_password = payload.confirm_password,
      state.info.country = payload.country,
      state.info.language = payload.language,
      state.info.currency = payload.currency,
      state.info.store_id = payload.hash_store_id,
      state.info.new_password = payload.new_password,
      state.info.birth_date = payload.birth_date
    },
    setBuyers(state, payload) {
      state.buyers = payload
    },
    updateAddressInfo(state, { key, value }) {
      state.address[key] = value;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setValid(state, payload) {
      state.valid = payload;
    },
    setDisableResendEmail(state, payload) {
      state.disable_resend_email = payload;  
    },
    setStep(state, payload) {
      state.step = payload;
    },
    setAddresses(state, payload) {
      state.addresses = payload
    },
    setAddress(state, payload) {
      state.address.hash_id = payload.hash_id,
      state.address.title = payload.title,
      state.address.zip_code = payload.zip_code,
      state.address.address = payload.address,
      // state.address.street = payload.street,
      state.address.city_name = payload.city_name,
      state.address.state_name = payload.state_name,
      // state.address.neighborhood = payload.neighborhood,
      // state.address.complement = payload.complement,
      // state.address.number = payload.number,
      state.address.main_address = payload.main_address
    },
    resetAddress(state) {
      state.address.hash_id = null,
      state.address.title = null,
      state.address.zip_code = null,
      state.address.address = null,
      // state.address.street = null,
      state.address.city_name = null,
      state.address.state_name = null,
      // state.address.neighborhood = null,
      // state.address.complement = null,
      // state.address.number = null,
      state.address.main_address = 0,
      state.address.buyer_hash_id = null
    },
    resetAddresses(state) {
      state.addresses.splice(0);
    },
    reset(state) {
      Object.assign(state, initialState())
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  }