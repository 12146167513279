<template>
  <div>
    <slot
      :getStoreStatus="getStoreStatus"
      :createStoreStatus="createStoreStatus"
      :updateStoreStatus="updateStoreStatus"
      :deleteStoreStatus="deleteStoreStatus"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const StoreStatusRepository = Repository.get("store_status");
export default {
  computed: {
    ...mapState("store", ["store"]),
    ...mapState("order", ["order"]),
    ...mapState("store_status", ["info"]),
  },
  methods: {
    async getStoreStatus() {
      try {
        const { data } = await StoreStatusRepository.get(this.store.hash_id);
        this.$store.commit("store_status/setStatus", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async createStoreStatus() {
      try {
        this.$store.commit("store_status/updateStoreStatusInfo", { key: "store_hash_id", value: this.store.hash_id})
        const { data } = await StoreStatusRepository.create(this.info);
        this.$emit('updateStatus', data.storeStatus, this.order)
        this.getStoreStatus();
        this.$notifications.showSuccess({ message: 'fo-status-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}` });
      }
    },
    async updateStoreStatus() {
      try {
        await StoreStatusRepository.update(this.info);
        this.getStoreStatus();
        this.$emit("updateOrders");
        this.$notifications.showSuccess({ message: 'fo-status-updated-successfully' });
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}` });
      }
    },
    async deleteStoreStatus(id) {
      try {
        await StoreStatusRepository.delete(id);
         this.getStoreStatus();
        this.$notifications.showSuccess({ message: 'fo-status-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}` });
      }
    },
  },

  mounted() {
    this.getStoreStatus();
  }
};
</script>

<style>
</style>