<template>
  <div>
    <slot
      :createOrder="createOrder"
      :getOrders="getOrders"
      :getBuyerOrders="getBuyerOrders"
      :updateOrderStatus="updateOrderStatus"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const OrderRepository = Repository.get("order");
export default {
  computed: {
    ...mapState("store", ["store"]),
    ...mapState("payment", ["payment"]),
    ...mapState("order", ["order"]),
    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
  methods: {
    async createOrder(payload) {
      this.$store.commit("toggle/toggleLoading", true);
      this.updatePaymentMetadata("store_name", this.store.name);
      try {
        const { data } = await OrderRepository.create(payload);
        if(data.pix) {
          this.$store.commit("payment/updatePixData", { key : "qr_code", value: data.pix.qr_code });
          this.$store.commit("payment/updatePixData", { key : "qr_code_url", value: data.pix.qr_code_url });
        }
        this.redirectBuyer(data);
        this.$store.commit("toggle/toggleLoading", false);
        this.$store.commit("cart/resetCart");
        this.$store.commit("cart/resetCartItem");
      } catch (error) {
        this.$store.commit("toggle/toggleLoading", false);
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
     async getOrders(page) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await OrderRepository.getOrders({
          params: { store_hash_id: this.store.hash_id, page: page },
        });
        this.$store.commit("order/setOrders", data.orders);
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async updateOrderStatus(payload) {
      try {
        await OrderRepository.updateOrderStatus(payload);
        this.getOrders();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getBuyerOrders() {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await OrderRepository.getOrdersByBuyer({
          params: { store_hash_id: this.$route.params.id },
        });
        this.$store.commit("order/setOrders", data.orders);
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    cleanCookiesCart() {
      this.$cookies.remove("cart_hash");
    },
    redirectBuyer(data) {
      if(data.link) {
        window.location.href = data.link;
      } else {
        this.$store.commit("order/setStep", 3);
      }
      this.cleanCookiesCart();
    },
    updatePayment(key, value) {
      this.$store.commit("order/updatePayment", { key: key, value });
    },
    updatePaymentMetadata(key, value) {
      this.$store.commit("order/updatePaymentMetadata", { key: key, value });
    },
  },
};
</script>

<style>
</style>