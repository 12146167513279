<template>
  <div>
    <v-col v-if="!loading" cols="12">
      <v-row  v-if="addresses.length > 0">
        <v-col v-for="(address, n) in addresses" :key="n" cols="12">
          <v-item-group>
            <v-item v-slot="{ active, toggle }" disabled>
              <v-card :ripple="false" :class="active ? 'border-orange' : 'border-blue'" rounded="xl" class="pa-7" @click.native="toggle" @click="$emit('setOrderAddress', address)">
                <v-row align="center" class="text-body-1">
                  <v-col cols="auto">
                    <v-row align="center">
                      <v-col cols="auto" class="font-weight-bold pt-0">
                        <v-row align="center">
                          <v-col cols="auto"> {{ address.title }}</v-col>
                          <v-col cols="auto" v-if="address.main_address == 1">
                            <v-chip small color="primary" outlined>
                              <v-icon small left>
                                mdi-map-marker
                              </v-icon>
                               {{ $t("fo-main-address") }}
                            </v-chip>
                        </v-col>
                        </v-row>
                        <v-row class="flex-column">
                          <v-col cols="auto" class="py-0 grey--text">
                            {{ `${address.address}` }}
                          </v-col>
                          <v-col cols="auto" class="py-0 grey--text">
                            {{ `${address.city_name}, ${address.state_name}` }}
                          </v-col>
                          <!-- <v-col v-if="address.complement" cols="auto" class="py-0 grey--text">
                            {{ `${$t("fo-complement")}: ${address.complement}` }}
                          </v-col> -->
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto" class="ms-auto">
                    <v-btn
                      :ripple="false"
                      large
                      icon
                      @click.stop="$emit('setAddress', address)"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      large
                      icon
                      @click.stop="$emit('deleteAddress', address.hash_id)"
                    >
                      <v-icon color="primary">
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row v-else>
        {{ $t("fo-you-have-no-registered-addresses") }}
      </v-row>
       <v-row>
        <v-col cols="12">
          <v-btn
            block
            depressed
            :ripple="false"
            @click="newAddress"
            color="primary"
            class="text-none font-weight-bold rounded-lg"
          >{{ $t("fo-new-address") }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <Loader :height="400" />
    </v-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/shared/Loader";
export default {
  components: {
    Loader
  },
  computed: {
    ...mapState("buyer", ["addresses", "loading"]),
  },
  methods: {
    newAddress() {
      this.$store.commit('buyer/resetAddress');
      this.$store.commit('buyer/setStep', 1);
    }
  }
};
</script>

<style>
</style>