import client from './Clients/AxiosClient';
const resource = '/store_status';

export default {
    get(id) {
        return client.get(`${resource}/all/${id}`);
    },
    getStatusById(id) {
        return client.get(`${resource}/${id}`);
    },
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
};