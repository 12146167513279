<template>
  <Categories v-if="this.$store.state.store.store.hash_id">
    <template v-slot="{ createCategory, getCategory, deleteCategory, updateCategory }">
      <OwnerCategories @getCategory="getCategory" @createCategory="createCategory" @updateCategory="updateCategory" @deleteCategory="deleteCategory" />
    </template>
  </Categories>
</template>

<script>
import Categories from "@/components/shared/Categories";
import OwnerCategories from "@/components/owner/Categories";
export default {
  components: {
    Categories,
    OwnerCategories
  }
};
</script>

<style>
</style>