export default {
    install(Vue) {
        Vue.prototype.$date = {
            getDate(locale) {
                return new Date().toLocaleDateString(locale);
            },
            getTime(locale) {
                return new Date().toLocaleTimeString(locale, {hour: '2-digit', minute: '2-digit'});
            },
            formatDate(date, locale) {
                return new Date(date).toLocaleDateString(locale);
            },
            formatTime(date, locale) {
                return new Date(date).toLocaleTimeString(locale);
            }
        };
    }
}