<template>
  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-lg'">
      <v-card-title>
        <span class="text-h5">Status</span>
        <v-spacer></v-spacer>
        <v-btn :ripple="false" plain icon color="primary" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-3">
          <v-col>
            <v-form
              @submit.prevent
              ref="statusForm"
              v-model="valid"
              lazy-validation
            >
              <v-row align="start" dense>
                <v-col>
                  <v-text-field
                    outlined
                    :value="info.status"
                    @input="updateCategoryInfo('status', $event)"
                    :rules="required"
                    label="Status"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    @click="validate"
                    color="primary"
                    :ripple="false"
                    depressed
                    large
                    height="56"
                    class="rounded-lg mx-2"
                  >
                    {{ $t("fo-save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="filteredStatus.length > 0" dense>
          <v-col cols="12" class="text-h6">
            {{ $t("fo-your-custom-status") }}
          </v-col>
          <v-col cols="12">
            <v-row dense v-for="status in filteredStatus" :key="status.hash_id">
              <v-col cols="auto" class="text-capitalize">
                {{ status.status.toLowerCase() }}
              </v-col>
              <v-col cols="auto" class="ms-auto">
                <v-btn icon small color="primary" @click="editStoreStatus(status)"
                  ><v-icon>mdi-pencil-outline</v-icon></v-btn
                >
                <v-btn icon small color="error" @click="$emit('deleteStoreStatus', status.hash_id)"
                  ><v-icon>mdi-delete-outline</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5 px-5">
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
          color="primary"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
        >
          {{ $t("fo-cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
export default {
  mixins: [validationRules],
  props: {
    editedIndex: {
      type: Number,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    ...mapState("store_status", ["info", "status", "loading"]),
    dialog: {
      get() {
        return this.$store.state.toggle.statusModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleStatusModal", value);
      },
    },
    filteredStatus() {
      return this.status.filter((item) => item.allowsToDelete == 1);
    },
  },
  methods: {
    updateCategoryInfo(key, value) {
      this.$store.commit("store_status/updateStoreStatusInfo", {
        key: key,
        value,
      });
    },
    validate() {
      if (this.$refs.statusForm.validate()) {
        this.save();
      }
    },
    editStoreStatus(status) {
      this.$store.commit("store_status/setStoreStatus", status);
    },
    close() {
      this.dialog = false;
      this.$refs.statusForm.resetValidation();
      this.$store.commit("store_status/resetStatus");
    },
    save() {
      !this.info.hash_id ? this.$emit("createStoreStatus") : this.$emit("updateStoreStatus");
      this.close();
    },
  },
};
</script>

<style>
</style>