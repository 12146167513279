<template>
  <v-dialog v-model="dialog" content-class="rounded-xl" :width="width" scrollable persistent :fullscreen="fullscreen">
    <v-card :class="!fullscreen ? 'rounded-xl' : 'rounded-0'">
      <v-card-title class="text-xl-h6 text-lg-h6 text-body-1">
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          plain
          icon
          color="primary"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <slot name="modal-content" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
    },
    fullscreen: {
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close')
    }
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.toggle.dialog;
      },
      set(value) {
        return this.$store.commit("toggle/toggleDialog", value);
      },
    },
  },
};
</script>

<style>
</style>