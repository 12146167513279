import client from './Clients/AxiosClient';
const resource = '/cart';

export default {
    get() {
        return client.get(`${resource}/all`);
    },
    getCategoryById(id) {
        return client.get(`${resource}/${id}`);
    },
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    update(payload, id) {
        return client.put(`${resource}/${id}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
};