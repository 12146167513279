import client from './Clients/AxiosClient';
const resource = '/complement_group';

export default {
    get() {
        return client.get(`${resource}/all`);
    },
    create(id, payload) {
        return client.post(`${resource}/${id}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
};