<template>
  <v-window v-model="step">
    <v-window-item :value="i" v-for="(component, i) in components" :key="i">
      <component
        :is="component"
        v-if="step == i"
        @setAddress="$emit('setAddress', $event)"
        @deleteAddress="$emit('deleteAddress', $event)"
        @createAddress="$emit('createAddress', $event)"
        @updateAddress="$emit('updateAddress', $event)"
        @cancel="$emit('cancel')"
        @setOrderAddress="$emit('setOrderAddress', $event)"
      />
    </v-window-item>
  </v-window>
</template>

<script>
import { mapState } from "vuex";
import AddressForm from "@/components/shop/default/AddressForm";
import AddressList from "@/components/shop/default/AddressList";
export default {
  components: {
    AddressForm,
    AddressList,
  },
  data() {
    return {
      components: ["AddressList", "AddressForm"],
    };
  },
  computed: {
    ...mapState("buyer", ["step"]),
  },
};
</script>

<style>
</style>