// initial state
const initialState = () => ({
  step: 1,
  info: {
    domain: null,
    ip_1: null,
    ip_2: null,
    ns_1: null,
    ns_2: null,
    store_hash_id: null,
    SSL_configured: null,
  },
});

const state = initialState;

//getters
const getters = {

}

// mutations
const mutations = {
  updateDomainInfo(state, { key, value }) {
    state.info[key] = value;
  },
  setDomainInfo(state, payload) {
    state.info.domain = payload.domain
    state.info.ip_1 = payload.ip_1
    state.info.ip_2 = payload.ip_2
    state.info.ns_1 = payload.ns_1
    state.info.ns_2 = payload.ns_2
    state.info.store_hash_id = payload.store_hash_id
    state.info.SSL_configured = payload.SSL_configured
  },
  setStep(state, payload) {
    state.step = payload
  },
  reset(state) {
      Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}