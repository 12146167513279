<template>
  <svg class="custom-icon" width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.2737 3.02238H29.1248V0.768612C29.1248 0.564764 29.0439 0.369264 28.8997 0.225121C28.7556 0.0809786 28.5601 0 28.3562 0C28.1524 0 27.9569 0.0809786 27.8127 0.225121C27.6686 0.369264 27.5876 0.564764 27.5876 0.768612V3.02238H7.98531V0.768612C7.98531 0.564764 7.90433 0.369264 7.76019 0.225121C7.61604 0.0809786 7.42055 0 7.2167 0C7.01285 0 6.81735 0.0809786 6.67321 0.225121C6.52906 0.369264 6.44808 0.564764 6.44808 0.768612V3.02238H3.29927C2.42456 3.02339 1.58597 3.37132 0.967457 3.98983C0.348944 4.60835 0.0010171 5.44694 0 6.32165V29.7005C0.000966256 30.5753 0.348871 31.4139 0.967389 32.0325C1.58591 32.651 2.42453 32.999 3.29927 33H32.2737C33.1484 32.999 33.987 32.651 34.6055 32.0325C35.2241 31.4139 35.572 30.5753 35.5729 29.7005V6.32165C35.5719 5.44694 35.224 4.60835 34.6055 3.98983C33.987 3.37132 33.1484 3.02339 32.2737 3.02238ZM3.29927 4.5596H6.44808V6.15543C6.44808 6.35928 6.52906 6.55478 6.67321 6.69893C6.81735 6.84307 7.01285 6.92405 7.2167 6.92405C7.42055 6.92405 7.61604 6.84307 7.76019 6.69893C7.90433 6.55478 7.98531 6.35928 7.98531 6.15543V4.56056H27.5876V6.1564C27.5876 6.36024 27.6686 6.55574 27.8127 6.69989C27.9569 6.84403 28.1524 6.92501 28.3562 6.92501C28.5601 6.92501 28.7556 6.84403 28.8997 6.69989C29.0439 6.55574 29.1248 6.36024 29.1248 6.1564V4.56152H32.2737C32.7408 4.56208 33.1887 4.74791 33.519 5.07823C33.8493 5.40856 34.0351 5.85642 34.0357 6.32357V8.78889H1.53723V6.32165C1.53778 5.8545 1.72361 5.40664 2.05393 5.07631C2.38426 4.74598 2.83212 4.56016 3.29927 4.5596ZM32.2737 31.462H3.29927C2.83209 31.4614 2.3842 31.2756 2.05387 30.9452C1.72353 30.6149 1.53773 30.167 1.53723 29.6998V10.3242H34.0357V29.7005C34.035 30.1676 33.8491 30.6153 33.5188 30.9455C33.1885 31.2757 32.7407 31.4614 32.2737 31.462Z"/>
    <path d="M20.0625 13.8794H15.5108C15.307 13.8794 15.1115 13.9604 14.9673 14.1045C14.8232 14.2487 14.7422 14.4442 14.7422 14.648C14.7422 14.8519 14.8232 15.0474 14.9673 15.1915C15.1115 15.3356 15.307 15.4166 15.5108 15.4166H20.0625C20.2664 15.4166 20.4619 15.3356 20.606 15.1915C20.7502 15.0474 20.8311 14.8519 20.8311 14.648C20.8311 14.4442 20.7502 14.2487 20.606 14.1045C20.4619 13.9604 20.2664 13.8794 20.0625 13.8794Z"/>
    <path d="M11.2816 13.8794H6.73004C6.52619 13.8794 6.33069 13.9604 6.18655 14.1045C6.0424 14.2487 5.96143 14.4442 5.96143 14.648C5.96143 14.8519 6.0424 15.0474 6.18655 15.1915C6.33069 15.3356 6.52619 15.4166 6.73004 15.4166H11.2816C11.4854 15.4166 11.6809 15.3356 11.8251 15.1915C11.9692 15.0474 12.0502 14.8519 12.0502 14.648C12.0502 14.4442 11.9692 14.2487 11.8251 14.1045C11.6809 13.9604 11.4854 13.8794 11.2816 13.8794Z"/>
    <path d="M28.8431 13.8794H24.2916C24.0877 13.8794 23.8922 13.9604 23.7481 14.1045C23.6039 14.2487 23.5229 14.4442 23.5229 14.648C23.5229 14.8519 23.6039 15.0474 23.7481 15.1915C23.8922 15.3356 24.0877 15.4166 24.2916 15.4166H28.8431C29.0469 15.4166 29.2424 15.3356 29.3866 15.1915C29.5307 15.0474 29.6117 14.8519 29.6117 14.648C29.6117 14.4442 29.5307 14.2487 29.3866 14.1045C29.2424 13.9604 29.0469 13.8794 28.8431 13.8794Z"/>
    <path d="M20.0625 19.644H15.5108C15.307 19.644 15.1115 19.725 14.9673 19.8692C14.8232 20.0133 14.7422 20.2088 14.7422 20.4127C14.7422 20.6165 14.8232 20.812 14.9673 20.9561C15.1115 21.1003 15.307 21.1813 15.5108 21.1813H20.0625C20.2664 21.1813 20.4619 21.1003 20.606 20.9561C20.7502 20.812 20.8311 20.6165 20.8311 20.4127C20.8311 20.2088 20.7502 20.0133 20.606 19.8692C20.4619 19.725 20.2664 19.644 20.0625 19.644Z"/>
    <path d="M11.2816 19.644H6.73004C6.52619 19.644 6.33069 19.725 6.18655 19.8692C6.0424 20.0133 5.96143 20.2088 5.96143 20.4127C5.96143 20.6165 6.0424 20.812 6.18655 20.9561C6.33069 21.1003 6.52619 21.1813 6.73004 21.1813H11.2816C11.4854 21.1813 11.6809 21.1003 11.8251 20.9561C11.9692 20.812 12.0502 20.6165 12.0502 20.4127C12.0502 20.2088 11.9692 20.0133 11.8251 19.8692C11.6809 19.725 11.4854 19.644 11.2816 19.644Z"/>
    <path d="M28.8431 19.644H24.2916C24.0877 19.644 23.8922 19.725 23.7481 19.8692C23.6039 20.0133 23.5229 20.2088 23.5229 20.4127C23.5229 20.6165 23.6039 20.812 23.7481 20.9561C23.8922 21.1003 24.0877 21.1813 24.2916 21.1813H28.8431C29.0469 21.1813 29.2424 21.1003 29.3866 20.9561C29.5307 20.812 29.6117 20.6165 29.6117 20.4127C29.6117 20.2088 29.5307 20.0133 29.3866 19.8692C29.2424 19.725 29.0469 19.644 28.8431 19.644Z"/>
    <path d="M20.0625 25.4086H15.5108C15.307 25.4086 15.1115 25.4895 14.9673 25.6337C14.8232 25.7778 14.7422 25.9733 14.7422 26.1772C14.7422 26.381 14.8232 26.5765 14.9673 26.7207C15.1115 26.8648 15.307 26.9458 15.5108 26.9458H20.0625C20.2664 26.9458 20.4619 26.8648 20.606 26.7207C20.7502 26.5765 20.8311 26.381 20.8311 26.1772C20.8311 25.9733 20.7502 25.7778 20.606 25.6337C20.4619 25.4895 20.2664 25.4086 20.0625 25.4086Z"/>
    <path d="M11.2816 25.4086H6.73004C6.52619 25.4086 6.33069 25.4895 6.18655 25.6337C6.0424 25.7778 5.96143 25.9733 5.96143 26.1772C5.96143 26.381 6.0424 26.5765 6.18655 26.7207C6.33069 26.8648 6.52619 26.9458 6.73004 26.9458H11.2816C11.4854 26.9458 11.6809 26.8648 11.8251 26.7207C11.9692 26.5765 12.0502 26.381 12.0502 26.1772C12.0502 25.9733 11.9692 25.7778 11.8251 25.6337C11.6809 25.4895 11.4854 25.4086 11.2816 25.4086Z"/>
    <path d="M28.8431 25.4086H24.2916C24.0877 25.4086 23.8922 25.4895 23.7481 25.6337C23.6039 25.7778 23.5229 25.9733 23.5229 26.1772C23.5229 26.381 23.6039 26.5765 23.7481 26.7207C23.8922 26.8648 24.0877 26.9458 24.2916 26.9458H28.8431C29.0469 26.9458 29.2424 26.8648 29.3866 26.7207C29.5307 26.5765 29.6117 26.381 29.6117 26.1772C29.6117 25.9733 29.5307 25.7778 29.3866 25.6337C29.2424 25.4895 29.0469 25.4086 28.8431 25.4086Z"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon {
    fill: currentColor;
}
</style>