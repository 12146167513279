import CategoryRepository from './CategoryRepository';
import StoreRepository from './StoreRepository';
import CurrencyRepository from './CurrencyRepository';
import OwnerRepository from './OwnerRepository';
import ProductRepository from './ProductRepository';
import BuyerRepository from './BuyerRepository';
import ComplementRepository from './ComplementRepository';
import ComplementGroupRepository from './ComplementGroupRepository';
import CartRepository from './CartRepository';
import OrderRepository from './OrderRepository';
import AddressRepository from './AddressRepository';
import PaymentRepository from './PaymentRepository';
import GatewayRepository from './GatewayRepository';
import OwnerGatewayRepository from './OwnerGatewayRepository';
import StoreStatusRepository from './StoreStatusRepository';

const repositories = {
    'categories': CategoryRepository,
    'store': StoreRepository,
    'currency': CurrencyRepository,
    'owner': OwnerRepository,
    'product': ProductRepository,
    'buyer': BuyerRepository,
    'complement': ComplementRepository,
    'complement_group': ComplementGroupRepository,
    'cart': CartRepository,
    'order': OrderRepository,
    'address': AddressRepository,
    'payment': PaymentRepository,
    'gateway': GatewayRepository,
    'owner_gateway': OwnerGatewayRepository,
    'store_status': StoreStatusRepository
}
export default {
    get: name => repositories[name]
};