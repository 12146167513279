<template>
  <Addresses>
    <template v-slot="{ setAddress, deleteAddress, createAddress, updateAddress }">
      <v-dialog v-model="addressModal" :content-class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl'" width="800" scrollable persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl'">
          <v-card-title class="text-xl-h6 text-lg-h6 text-body-1">
            <span>{{ $auth.isLoggedIn() ? $t('fo-my-addresses') : $t('fo-address') }}</span>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              plain
              icon
              color="primary"
              @click="setStep"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="$auth.isLoggedIn()">
                <AddressStep
                  @setAddress="setAddress"
                  @deleteAddress="deleteAddress"
                  @createAddress="createAddress"
                  @updateAddress="updateAddress"
                  @cancel="cancel"
                  @setOrderAddress="setOrderAddress"
                />
              </v-col>
              <v-col v-else>
                <AddressForm @createAddress="createAddress" @cancel="cancel" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </Addresses>
</template>
<script>
import AddressStep from "@/components/shop/default/AddressStep";
import AddressForm from "@/components/shop/default/AddressForm";
import Addresses from "@/components/shared/Addresses";

export default {
  components: {
    AddressStep,
    Addresses,
    AddressForm
  },
  methods: {
    setStep() {
      this.addressModal = false
      this.$store.commit("buyer/setStep", 0);
    },
    cancel() {
      this.$auth.isLoggedIn() ? this.$store.commit("buyer/setStep", 0) : this.addressModal = false;
    },
    setOrderAddress(payload) {
      this.$store.commit("buyer/setAddress", payload);
      this.addressModal = false;
    },
  },
   computed: {
    addressModal: {
      get() {
        return this.$store.state.toggle.addressModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleAddressModal", value);
      },
    },
  },
};
</script>
<style>
</style>