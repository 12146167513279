export default {
    install(Vue) {
        Vue.prototype.$cpf = {
            test(strCPF) {
                var Soma;
                var Resto;
                var i;
                Soma = 0;
                strCPF = this.removeSpecialChars(strCPF);
                if (this.duplicateCount(strCPF)) return false;
                for (i = 1; i <= 9; i++)
                    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                Resto = (Soma * 10) % 11;
                if (Resto == 10 || Resto == 11) Resto = 0;
                if (Resto != parseInt(strCPF.substring(9, 10))) return false;
                Soma = 0;
                for (i = 1; i <= 10; i++)
                    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                Resto = (Soma * 10) % 11;
                if (Resto == 10 || Resto == 11) Resto = 0;
                if (Resto != parseInt(strCPF.substring(10, 11))) return false;
                return true;
            },
            removeSpecialChars(str) {
                return str.replace(/\D+/g, "");
            },
            duplicateCount(str) {
                if (new Set(str).size > 1) return false;
                else return true;
            }
        };
    }
}