export const validationRules = {
    data() {
        return {
            required: [(v) => !!v || this.$t('fo-required')],
            multipleSelectRules: [(v) => v && v.length > 0 || this.$t('fo-required') ],
            emailRules: [
                (v) => !!v || this.$t('fo-required'),
                (v) => /.+@.+\..+/.test(v) || this.$t('fo-invalid-email'),
                (v) => /^[\S]+$/.test(v) || this.$t('fo-must-not-contain-spaces'),
            ],
            passwordRules: [
                (v) => !!v || this.$t('fo-required'),
                (v) => (v && v.length >= 8) || this.$t('fo-min-8'),
                (v) => /(?=.*[A-Za-z])/.test(v) || this.$t('fo-must-contain-letters'),
                (v) => /(?=.*[0-9])/.test(v) || this.$t('fo-must-contain-numbers'),
                (v) => /[`!@#$%^&*()_+\-={};':"|,.<>?~]/.test(v) || this.$t('fo-must-contain-special-char'),
                (v) => /^[\S]+$/.test(v) || this.$t('fo-must-not-contain-spaces'),
            ],
            quantityRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
            ],
            nameRules: [
                (v) => !!v || this.$t('fo-required'),
                (v) => (v && v.length >= 3) || this.$t('fo-min-3'),
                (v) => (v && v.length <= 50) || this.$t('fo-max-50'),
              ],
            phoneRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers')
              // (v) => (v && v.length <= 9) || this.$t("seller_central_address.form.phoneRules.min")
            ],
            zipcodeRules: [
              (v) => !!v || this.$t('fo-required'),
            ],
            stateRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v.length <=20) || this.$t('fo-max-20'),
              (v) => /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(v) || this.$t('fo-only-letters')
            ],
            cityRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v.length <=100) || this.$t('fo-max-100'),
              (v) => /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(v) || this.$t('fo-only-letters')
            ],
            streetavenueRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v.length <= 100) || this.$t('fo-max-100'),
            ],
            amountRules: [
              (v) => (v && this.$money.formatFloatDb(v) > 0) || this.$t('fo-required'),
            ],
            radioRules: [(v) => v !== null || this.$t('fo-required')],
            urlRules: [(v) => /^(?!http|https|ftp?)(?!:\/\/|www\.?).*\..+$/g.test(v) || this.$t('fo-invalid-domain')],
            deliveryTimeRules: [(v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers')],
            cardHolderName: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v.length >= 3) || this.$t('fo-min-3'),
              (v) => (v && v.length <= 50) || this.$t('fo-max-50'),
              (v) => /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(v) || this.$t('fo-only-letters'),
            ],
            cardRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
              (v) => (v && v.length >= 13 && v.length <= 19) || this.$t("fo-between-13-19")
            ],
            cardMonthRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => /^(1[012]|[1-9])$/.test(v) || this.$t("fo-between-1-12")
            ],
            cardYearRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v >= new Date().getFullYear()) || this.$t("fo-year-must-be-higher"),
              (v) => (v && v.length == 4) ||  this.$t("fo-max-4")
            ],
            cardCvvRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) => (v && v.length >= 3 && v.length <= 4) || this.$t("fo-between-3-4"),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers')
            ],
            cpfRules: [
              (v) => !!v || this.$t("fo-required"),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
              (v) => (v && v.length == 11) || this.$t("fo-must-have-11"),
              (v) => v && this.$cpf.test(v) || "CPF inválido"
            ],
            pagarmePhoneRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
              (v) => (v && v.length == 9) || this.$t("fo-must-have-9")
            ],
            pagarmeAreaCodeRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
              (v) => (v && v.length == 2) || this.$t("fo-must-have-2")
            ],
            cepRules: [
              (v) => !!v || this.$t('fo-required'),
              (v) =>  /^[0-9]+$/.test(v) || this.$t('fo-only-numbers'),
              (v) => (v && v.length == 8) || this.$t('fo-min-8')
            ],
        }
    }
}