<template>
  <v-app-bar color="white" class="elevation-3" app clipped-left>
    <v-app-bar-nav-icon @click.stop="ownerNavigation = !ownerNavigation" color="primary" class="px-8" :class="[{ active: ownerNavigation }]" :ripple="false" plain></v-app-bar-nav-icon>
    <v-divider vertical></v-divider>
    <img :src="require('@/assets/icons/delivery-app.svg')" width="70" alt="" class="ps-4">
    <v-toolbar-title  class="font-weight-bold text-h5 primary--text me-auto ps-2">Delivery</v-toolbar-title>
    <v-spacer></v-spacer>
    <span v-if="$auth.user() && !$vuetify.breakpoint.xsOnly">{{ `${$t("fo-hello")} ${$auth.user().name}` }}</span>
    <v-btn :ripple="false" depressed icon @click="logout"
      ><v-icon color="error" medium>mdi-logout</v-icon></v-btn
    >
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.setToken(false);
      window.location.href = process.env.VUE_APP_OFFICE_URL;
    },
  },
  computed: {
    ownerNavigation: {
      get() {
        return this.$store.state.toggle.ownerNavigation;
      },
      set(value) {
        return this.$store.commit("toggle/toggleOwnerNavigation", value);
      },
    },
  },
};
</script>

<style scoped>
.v-btn.active .v-icon {
  transform: rotate(-180deg);
}
</style>