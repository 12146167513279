<template>
    <component :is="template" />
  </template>
  
  <script>
  import RecoverPasswordDefault from '@/components/shop/default/RecoverPassword'
  export default {
    components: {
        RecoverPasswordDefault
    },
     data: () => ({
      user: {
        template: 'default'
      },
    }),
    computed: {
      template() {
        if(this.user.template == 'default') {
          return 'RecoverPasswordDefault'
        } else {
          return 'LoginNewTemplate'
        }
      }
    }
  }
  </script>
  
  <style>
  
  </style>