<template>
  <v-card flat class="mx-auto rounded-lg">
    <v-list-item three-line>
      <v-list-item-avatar tile size="80">
        <v-img
          class="rounded-lg"
          :src="
            product.image_url
              ? product.image_url
              : require('@/assets/images/no-photo.png')
          "
        ></v-img
      ></v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-body-1">
          {{ product.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ product.description }} </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-actions class="pa-4">
      <v-btn
        color="primary"
        depressed
        :ripple="false"
        rounded-xl
        small
        :to="{ path: `products/${product.hash_id}` }"
      >
        {{ $t("fo-edit") }}
      </v-btn>
      <v-btn
        color="primary"
        depressed
        icon
        :ripple="false"
        rounded-xl
        small
        @click="confirm"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-chip class="ml-auto" color="error" small v-if="product.active == 0">
        {{ $t("fo-inactive") }}
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object, //force
    },
  },
  methods: {
    confirm() {
      this.$store.commit("product/setProduct", this.product);
      this.$store.commit("toggle/toggleDeleteModal", true);
    },
  },
};
</script>

<style>
</style>