<template>
  <v-form ref="addressform" v-model="valid" lazy-validation>
    <v-row dense>
      <v-col cols="12" sm="12" md="12" class="mt-2">
        <v-text-field
          :label="$t('fo-address-name')"
          required
          outlined
          :value="address.title"
          @input="updateAddressInfo('title', $event)"
          :rules="nameRules"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="12" lg="8" xl="8">
        <v-text-field
          :label="$t('fo-street-avenue')"
          :value="address.street"
          @input="updateAddressInfo('street', $event)"
          :rules="streetavenueRules"
          outlined
          required
        ></v-text-field>
      </v-col>
       <v-col cols="6" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          :label="$t('fo-number')"
          :value="address.number"
          @input="updateAddressInfo('number', $event)"
          :disabled="address.number == 'SN' ? true : false"
          :rules="required"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="1" xl="1">
        <v-checkbox
          :input-value="address.number"
          :false-value="null"
          true-value="SN"
          @change="updateAddressInfo('number', $event)"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <v-text-field
          :label="$t('fo-neighborhood')"
          :value="address.neighborhood"
          @input="updateAddressInfo('neighborhood', $event)"
          :rules="stateRules"
          outlined
          required
        ></v-text-field>
      </v-col> -->
      <!-- <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-text-field
          :label="$t('fo-complement')"
          outlined
          :value="address.complement"
          @input="updateAddressInfo('complement', $event)"
        ></v-text-field>
      </v-col> -->
      <!-- <v-col cols="12" sm="12" md="12" lg="4" xl="4" xs="12" align="center">
        <v-btn
          class="font-weight-bold text-xl-h6 text-lg-h6 text-md-h6 text-sm-subtitle-2 text-caption"
          text
          x-large
          depressed
          plain
          :ripple="false"
        >
          Não sei meu CEP
        </v-btn>
      </v-col> -->
      <v-col cols="12">
        <v-text-field
          :label="$t('fo-full-address')"
          outlined
          :value="address.address"
          @input="updateAddressInfo('address', $event)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <v-text-field
          :label="$t('fo-zipcode')"
          :value="address.zip_code"
          @input="updateAddressInfo('zip_code', $event)"
          :rules="zipcodeRules"
          outlined
          required
          placeholder="00000000"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <v-text-field
          :label="$t('fo-city')"
          :value="address.city_name"
          @input="updateAddressInfo('city_name', $event)"
          :rules="cityRules"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <v-text-field
          :label="$t('fo-state')"
          :value="address.state_name"
          @input="updateAddressInfo('state_name', $event)"
          :rules="stateRules"
          maxlength="2"
          outlined
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="pa-1" v-if="$auth.isLoggedIn()">
        <v-checkbox :input-value="address.main_address"
          class="my-0 py-0"
          :false-value="0"
          :true-value="1"
          @change="updateAddressInfo('main_address', $event)" :label="$t('fo-this-is-my-main-address')">
        </v-checkbox>
      </v-col>
      <v-col cols="12" align="right" class="pa-2">
        <v-btn
          large
          :class="$vuetify.breakpoint.xs ? 'my-2' : ''"
          color="error"
          depressed
          :width="$vuetify.breakpoint.xs ? '100%' : ''"
          class="rounded-lg me-2"
          @click="$emit('cancel')"
        >
          {{ $t("fo-cancel") }}
        </v-btn>
        <v-btn
          large
          :class="$vuetify.breakpoint.xs ? 'my-2' : ''"
          :loading="loading"
          :disabled="valid ? loading : !valid"
          color="primary"
          depressed
          :width="$vuetify.breakpoint.xs ? '100%' : ''"
          class="rounded-lg"
          @click="validateAddress"
        >
          {{ $t("fo-save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationRules } from "@/mixins/formValidation";
import { mapState } from "vuex";
export default {
  mixins: [validationRules],
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    updateAddressInfo(key, value) {
      this.$store.commit("buyer/updateAddressInfo", { key: key, value });
    },
    validateAddress() {
      if (this.$refs.addressform.validate()) {
         !this.address.hash_id
          ? this.$emit("createAddress", this.address)
          : this.$emit("updateAddress", this.address);
      }
    },
  },
  computed: {
    ...mapState("buyer", ["address", "loading"]),
  },
};
</script>

<style>
</style>