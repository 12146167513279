<template>
  <div>
    <slot :setAddress="setAddress" :deleteAddress="deleteAddress" :createAddress="createAddress" :updateAddress="updateAddress"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const AddressRepository = Repository.get("address");
export default {
  computed: {
    ...mapState("buyer", ["address", "addresses"]),
  },
  methods: {
    setAddress(payload) {
      this.$store.commit('buyer/setAddress', payload)
      this.$store.commit('buyer/setStep', 1)
    },
    async createAddress(payload) {
      if(this.$auth.isLoggedIn() && this.$auth.getScope().includes('buyer')) {
        this.createLoggedBuyerAddress(payload);
      } else {
        this.createUnloggedBuyerAddress(payload);
      }
    },
    createUnloggedBuyerAddress(payload) {
      this.$store.commit("buyer/setAddress", payload);
      this.$store.commit('toggle/toggleAddressModal', false);
    },
    async createLoggedBuyerAddress(payload) {
      this.$store.commit("buyer/setLoading", true);
      try {
        await AddressRepository.create(payload);
        await this.getBuyerAddresses();
        this.$store.commit('buyer/setStep', 0)
        this.$store.commit("buyer/setLoading", false);
        this.$notifications.showSuccess({ message: 'fo-address-created-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("buyer/setLoading", false);
      } finally {
        this.$store.commit("buyer/setLoading", false);
      }
    },
    async updateAddress(payload) {
      try {
        await AddressRepository.update(payload);
        await this.getBuyerAddresses();
        this.$store.commit('buyer/setStep', 0)
        this.$notifications.showSuccess({ message: 'fo-address-updated-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getBuyerAddresses() {
      this.$store.commit("buyer/setLoading", true);
      try {
        const { data } = await AddressRepository.getBuyerAddresses();
        if(data.addresses) {
          this.$store.commit("buyer/setAddresses", data.addresses);
          let result = data.addresses.find(item => { return item.main_address === 1 });
          if(result) {
            this.$store.commit('buyer/setAddress', result)
          }
        } else {
          this.$store.commit("buyer/resetAddress");
          this.$store.commit("buyer/resetAddresses");
        }
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("buyer/setLoading", false);
      } finally {
        this.$store.commit("buyer/setLoading", false);
      }
    },
    async getAddressById(id) {
      try {
        const { data } = await AddressRepository.getAddressById(id);
        this.$store.commit("buyer/setAddress", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteAddress(id) {
      try {
        await AddressRepository.delete(id);
        await this.getBuyerAddresses();
        this.$notifications.showSuccess({ message: 'fo-address-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
  },
  mounted() {
    if(this.$auth.isLoggedIn()) {
      this.getBuyerAddresses();
    }
  }
}
</script>