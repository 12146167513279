<template>
  <v-container class="py-16 fill-height">
    <v-row justify="center" align="start" class="fill-height">
      <v-col cols="12" xl="6" lg="6" md="6" sm="7">
        <v-card class="rounded-xl pa-8" min-height="400">
          <v-row align="center" justify="center" class="flex-column fill-height">
            <v-col cols="auto" class="text-center">
              <v-icon size="100" color="primary"
                >mdi-checkbox-marked-circle-outline</v-icon
              >
            </v-col>
            <v-col cols="auto" class="text-center primary--text text-xl-h4 text-lg-h4 text-md-h4 text-h6 font-weight-bold pb-5">
              {{ $t("fo-your-order-has-been-successfully-placed") }}
            </v-col>
            <v-col  cols="auto" class="text-h5 primary--text font-weight-bold" v-if="!$auth.isLoggedIn()">
              {{ $t("fo-sign-up-to-see-next-orders") }}
            </v-col>
            <v-col v-if="pixData.qr_code && pixData.qr_code_url">
              <v-row justify="center">
                <v-col cols="auto">
                  Aqui estão seus dados pix:
                </v-col>
              </v-row>
              <v-row align="center" justify="center"  v-if="$vuetify.breakpoint.smAndUp">
                <v-img :src="pixData.qr_code_url" max-height="300" max-width="300"></v-img>
              </v-row>
              <v-row class="flex-column" align="center">
                <v-col cols="12">
                  <v-textarea
                    v-model="pixData.qr_code"
                    auto-grow
                    readonly
                    solo
                    flat
                    ref="contentToCopy"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col>
                  <v-btn depressed color="primary" outlined block :ripple="false" @click="copyCode">{{ $t("fo-copy-code") }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <div v-if="!$auth.isLoggedIn()">
                <v-btn  block :ripple="false" color="primary" class="mb-3" depressed @click="goToRegister"
                >Registrar-se</v-btn
              >
                  <v-btn  block :ripple="false" color="primary" depressed @click="goToHome"
                >{{ $t("fo-back-to-home-page") }}</v-btn
              >
              </div>
              <v-btn  v-else @click="goToOrders" block color="primary" depressed :ripple="false"
                >{{ $t("fo-view-my-orders") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("payment", ["pixData"]),
  },
  methods: {
    goToHome() {
      this.$router.go();
    },
    goToOrders() {
      window.location.href = `${process.env.VUE_APP_URL}/delivery/${this.$route.params.slug}/${this.$route.params.id}/account/orders`
    },
    goToRegister() {
      this.$router.push({ path: `/delivery/${this.$route.params.slug}/${this.$route.params.id}/register`});
    },
    copyCode() {
      let contentToCopy = this.$refs.contentToCopy.$el.querySelector("textarea");
      contentToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style>
</style>