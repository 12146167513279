<template>
  <v-dialog v-model="dialog" eager scrollable persistent max-width="500px">
    <v-card class="rounded-lg">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          plain
          icon
          color="primary"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent
          ref="categoryForm"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12" class="pt-5 pb-0">
              <v-text-field
                outlined
                :value="info.description"
                @input="updateCategoryInfo('description', $event)"
                :rules="required"
                :label="$t('fo-name')"
              ></v-text-field>
              <v-radio-group
                class="py-0 my-0"
                row
                @change="updateCategoryInfo('active', $event)"
                :value="info.active"
              >
                <v-radio :label="$t('fo-active')" :value="1"></v-radio>
                <v-radio :label="$t('fo-inactive')" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-5 px-5">
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
          color="primary"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
        >
          {{ $t("fo-cancel") }}
        </v-btn>
        <v-btn
          @click="validate"
          color="primary"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
        >
          {{ $t("fo-save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
export default {
  mixins: [validationRules],

  props: {
    editedIndex: {
      type: Number,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    ...mapState("category", ["info", "categories", "loading"]),
    formTitle() {
      return !this.info.hash_id ? this.$t("fo-new-category") : this.$t("fo-edit-category");
    },
    dialog: {
      get() {
        return this.$store.state.toggle.categoryModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleCategoryModal", value);
      },
    },
  },
  methods: {
    updateCategoryInfo(key, value) {
      this.$store.commit("category/updateCategoryInfo", { key: key, value });
    },
    validate() {
      if (this.$refs.categoryForm.validate()) {
        this.save();
      }
    },
    close() {
      this.dialog = false;
      this.$refs.categoryForm.resetValidation();
      this.$store.commit("category/resetCategory");
    },
    save() {
      !this.info.hash_id ? this.$emit("createCategory", this.info) : this.$emit("updateCategory", this.info);
      this.close();
    },
  },
};
</script>

<style>
</style>