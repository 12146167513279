<template>
  <v-text-field
    class="custom-search-input"
    filled
    solo
    flat
    :label="$t('fo-search')"
    clearable
    prepend-inner-icon="mdi-magnify"
    hide-details
    @input="$emit('search', $event)"
  ></v-text-field>
</template>

<script>
export default {};
</script>

<style>
.custom-search-input .v-input__slot {
  background: #f3f5f8 !important;
}
</style>