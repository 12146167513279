
// initial state
const initialState = () => ({
  item: {
    store_id: null,
    buyer_hash_id: null,
    cart_hash_id: null,
    hash_id: null,
    quantity: 1,
    details: null,
    complements: [],
  },
  cart: {
    buyer_hash_id: null, 
    hash_id: null,
    store_hash_id: null,
    items: []
  },
});

const state = initialState;

//getters
const getters = {

}

// mutations
const mutations = {
  updateCartItem(state, { key, value }) {
    state.item[key] = value;
  },
  updateItemComplements(state, payload) {
    console.log(state.item.complements)
    const index = state.item.complements.indexOf(payload)
    if (index >= 0) state.item.complements.splice(index, 1)
    else state.item.complements = [...state.item.complements, payload]
  },
  cleanItemComplements(state) {
    state.item.complements.splice(0)
  },
  setCart(state, payload) {
    state.cart.buyer_hash_id = payload.buyer_hash_id 
    state.cart.hash_id = payload.hash_id
    state.cart.store_hash_id = payload.store_hash_id
    state.cart.items = payload.items
  },
  setCartItem(state, payload) {
    state.info.store_id = payload.store_id
    state.info.buyer_hash_id = payload.buyer_hash_id
    state.info.cart_hash_id = payload.cart_hash_id
    state.info.hash_id = payload.hash_id
    state.info.quantity = payload.quantity
    state.info.comments = payload.comments
    state.info.complements = payload.complements
  },
  resetCartItem(state) {
    state.item.store_id = null,
    state.item.buyer_hash_id = null,
    state.item.cart_hash_id = null,
    state.item.hash_id = null,
    state.item.quantity = 1,
    state.item.details = null,
    state.item.complements = []
  },
  incrementQuantity(state) {
    state.item.quantity++;
  },
  decrementQuantity(state) {
    if (state.item.quantity > 1) {
      state.item.quantity--;
    }
  },
  resetCart(state) {
    state.cart.buyer_hash_id = null, 
    state.cart.hash_id = null,
    state.cart.store_hash_id = null,
    state.cart.items = []
  },
  reset(state) {
      Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}