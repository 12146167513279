<template>
  <Modal width="900" :fullscreen="$vuetify.breakpoint.xsOnly" @close="close">
    <template v-slot:modal-content>
      <v-card-text style="height: 1000px" class="rounded-xl">
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="custom-column">
            <v-img
              class="rounded-xl custom-image"
              :height="$vuetify.breakpoint.xsOnly ? 200 : 340"
              :src="info.image_url ? info.image_url : require('@/assets/images/no-photo.png')"
            ></v-img>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <v-row class="flex-column">
              <v-col cols="12">
                <v-row align="start" dense>
                  <v-col cols="auto" class="me-auto">
                    <v-row class="flex-cloumn" dense>
                      <v-col cols="12">
                        <span class="text-body-1 font-weight-bold">{{
                          info.name
                        }}</span>
                      </v-col>
                      <!-- <v-col cols="12" class="pt-0">
                        <v-row justify="start" align="center" dense>
                          <v-col cols="auto">
                            <v-rating
                              :value="4.5"
                              color="primary"
                              dense
                              half-increments
                              size="18"
                            ></v-rating>
                          </v-col>
                          <v-col cols="auto">
                            <span class="text-caption ms-2 font-weight-black">
                              4.5
                            </span>
                          </v-col>
                        </v-row>
                      </v-col> -->
                    </v-row>
                  </v-col>
                  <v-col cols="auto" v-if="store.currency_code">
                    <span class="primary--text font-weight-bold">{{
                      $money.formatMoney(store.currency_code, price)
                    }}</span>
                  </v-col>
                  <v-col cols="12">
                    <div class="font-weight-black mb-2">
                      {{ $t("fo-description") }}
                    </div>
                    <p>
                      {{ info.description }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-list class="pa-0" flat>
                  <v-list-item-group
                    class="mb-3"
                    multiple
                    v-for="(group, i) in complementGroups"
                    :key="`item-${i}`"
                    v-model="group.checked"
                  >
                    <v-subheader class="my-4">
                      <v-row align="center">
                        <v-col>
                          <div class="font-weight-bold">{{ group.name }}</div>
                          <div class="text-caption font-weight-bold">{{ group.checked.length + " de " + group.maximum }}</div>
                        </v-col>
                        <v-col cols="auto" class="ms-auto">
                          <v-card class="rounded-l-xl align-items-center justify-items-center pa-5" width="130" color="tertiary" flat>
                            <span class="white--text text-caption font-weight-bold">{{ group.mandatory == 1 ? $t("fo-mandatory") : $t("fo-optional") }}</span>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-subheader>
                    <v-list-item
                      two-line
                      :ripple="false"
                      :disabled="group.checked.length == group.maximum && group.checked.indexOf(complement) === -1"
                      v-for="(complement, index) in group.complements"
                      @click="updateItemComplements(complement)"
                      :key="`item-${index}`"
                      :value="complement"
                      active-class="primary--text"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-bold"
                            v-text="complement.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-if="store.currency_code">{{ $money.formatMoney(store.currency_code, complement.price / 100) }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="primary"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  :label="$t('fo-details')"
                  @input="updateCartItem('details', $event)"
                  :value="item.details"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'py-4' : 'py-4 px-8'">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" xl="3" lg="3" md="3" sm="3">
            <v-row>
              <v-col cols="3">
                <v-btn
                  tile
                  @click.native="decrement()"
                  class="font-weight-bold"
                  color="primary"
                  text
                  depressed
                  large
                  plain
                  :ripple="false"
                >
                  <v-icon dark>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn
                  depressed
                  text
                  tile
                  :ripple="false"
                  large
                  plain
                  width="100%"
                  class="
                    font-weight-bold
                    text-xl-h5 text-lg-subtitle-1 text-md-h6 text-sm-h6
                  "
                >
                  {{ item.quantity }}
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn
                  tile
                  @click="increment()"
                  class="font-weight-bold"
                  depressed
                  text
                  large
                  plain
                  :ripple="false"
                  color="primary"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              large
              :disabled="isDisabled"
              class="rounded-lg"
              depressed
              :ripple="false"
              @click="add()"
            >
              {{ `${$t("fo-add")}    ${$money.formatMoney(store.currency_code, total)}` }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/shop/default/Modal.vue";
import { mapState } from "vuex";
export default {
  components: {
    Modal,
  },
  methods: {
    increment() {
      this.$store.commit("cart/incrementQuantity");
    },
    decrement() {
      this.$store.commit("cart/decrementQuantity");
    },
    add() {
      this.updateCartItem("hash_id", this.info.hash_id);
      this.updateCartItem("store_id", this.store.hash_id);
      this.updateCartItem("cart_hash_id", this.cart.hash_id);
      this.$emit('addItemCart', this.item);
      this.$store.commit('toggle/toggleDialog', false);
    },
    //faz update adicionando novos complementos dentro do array de de complementos no item da store
    updateItemComplements(complement) {
      this.$store.commit("cart/updateItemComplements", complement);
    },
    //responsável por fazer o update do carrinho na store
    updateCartItem(key, value) {
      this.$store.commit("cart/updateCartItem", { key: key, value });
    },
    close() {
      this.$store.commit("cart/resetCartItem");
    }
  },
  computed: {
    ...mapState("product", ["info"]),
    ...mapState("cart", ["item", "cart"]),
    ...mapState("store", ["store"]),
    price() {
      return this.info.price / 100;
    },
    total() {
      let totalComplements = this.item.complements.reduce((acc, item) => acc + item.price/100, 0)
      return (totalComplements + this.price) * this.item.quantity;
    },
    //faz controle do botão de adicionar
    isDisabled(){
      return !this.complementGroups.length > 0 ? false : this.complementGroups.some(item=>item.mandatory == true && item.checked.length < 1);
    },
    //adiciona o array checked a cada grupo de complemento, para fazer o controle de quais elementos foram selecionados ou não
    complementGroups() {
      return (
        !this.info.complement_groups || this.info.complement_groups.map((complement) => {
          this.$set(complement, "checked", []);
          return complement;
        }).sort((a,b) => { return a.pivot.id - b.pivot.id } )
      );
    },
  },
};
</script>

<style scoped>
.custom-image {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
.custom-column {
  overflow: visible !important;
}
</style>