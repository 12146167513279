<template>
  <SharedGateway v-if="this.$store.state.store.store.hash_id">
    <template v-slot="{ getGateways, getGatewayByOwner, createGateway, deleteGateway, updateGateway }">
      <Gateway
        @getGateways="getGateways"
        @getGatewayByOwner="getGatewayByOwner"
        @createGateway="createGateway"
        @deleteGateway="deleteGateway"
        @updateGateway="updateGateway"
      />
    </template>
  </SharedGateway>
</template>

<script>
import SharedGateway from "@/components/shared/Gateway";
import Gateway from "@/components/owner/Gateway";
export default {
  components: {
    SharedGateway,
    Gateway,
  },
};
</script>

<style>
</style>