<template>
  <div>
    <slot
      :getBuyersByStore="getBuyersByStore"
      :updateBuyer="updateBuyer"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const BuyerRepository = Repository.get("buyer");
export default {
  computed: {
    ...mapState("store", ["store"]),
  },
  methods: {
    async getBuyersByStore(params) {
      this.$store.commit("buyer/setLoading", true);
      try {
        const { data } = await BuyerRepository.getBuyersByStore({
          params: { store_hash_id: this.store.hash_id, filter: params.filter, order: params.order, page: params.page },
        });
        this.$store.commit("buyer/setBuyers", data);
        this.$store.commit("buyer/setLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("buyer/setLoading", false);
      } 
    },
     async updateBuyer(payload) {
      this.$store.commit("buyer/setLoading", true);
      try {
        await BuyerRepository.updateBuyer(payload);
        const data = await this.$auth.getUser();
        this.$store.commit("buyer/setBuyer", data);
        this.$store.commit("buyer/setLoading", false);
        this.$notifications.showSuccess({ message: "fo-updated-profile" });
      } catch (error) {
        this.$notifications.showError({ message: `fo-${error.response.data.message}` });
        this.$store.commit("buyer/setLoading", false);
      } 
    },
  },
};
</script>