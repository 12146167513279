import client from './Clients/AxiosClient';
const resource = '/store';

export default {
    get() {
        return client.get(`${resource}/all`);
    },
    getStoreById(id) {
        return client.get(`${resource}/${id}`);
    },
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
    getStoreItems(id) {
        return client.get(`${resource}/${id}/items`);
    },
    getStoreCategories(id) {
        return client.get(`${resource}/${id}/categories`);
    },
    getStoreComplementsGroup(id) {
        return client.get(`${resource}/${id}/complement_groups`);
    },
    connectDomain(payload) {
        return client.post(`${resource}/connect`, payload);
    },
    disconnectDomain(payload) {
        return client.post(`${resource}/disconnect`, payload);
    },
    isConnectedDomain(payload) {
        return client.post(`${resource}/is-connected`, payload);
    },
    getNs(payload) {
        return client.get(`${resource}/get-ns`, payload);
    },
    getNsByStore(param) {
        return client.get(`${resource}/get-ns-by-store`, param)
    },
    getUrlByDomain(payload) {
        return client.get(`${resource}/get-url-by-domain/${payload}`);
    }
};