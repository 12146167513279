<template>
  <div>
    <slot
      :getGateways="getGateways"
      :getGatewayByOwner="getGatewayByOwner"
      :createGateway="createGateway"
      :deleteGateway="deleteGateway"
      :updateGateway="updateGateway"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const GatewayRepository = Repository.get("gateway");
const OwnerGatewayRepository = Repository.get("owner_gateway");
export default {
  computed: {
    ...mapState("store", ["store"]),
  },
  methods: {
    async createGateway(payload) {
      this.$store.commit("gateway/updateGateway", { key: "store_hash_id", value: this.store.hash_id });
      try {
        await OwnerGatewayRepository.create(payload);
        this.$notifications.showSuccess({ message: "fo-gateway-created-successfully" });
        this.getGatewayByOwner();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async updateGateway(payload) {
      this.$store.commit("gateway/updateGateway", { key: "store_hash_id", value: this.store.hash_id });
      try {
        await OwnerGatewayRepository.update(payload);
        this.$notifications.showSuccess({ message: "fo-gateway-updated-successfully" });
        this.getGatewayByOwner();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async deleteGateway(id) {
      try {
        await OwnerGatewayRepository.delete(id, this.store.hash_id);
        this.$notifications.showSuccess({ message: "fo-gateway-deleted-successfully" });
        this.getGatewayByOwner();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getGatewayById(id) {
      try {
        await OwnerGatewayRepository.getGatewayById(id);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getGatewayByOwner() {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await OwnerGatewayRepository.getGatewayByOwner({
          params: { owner_hash_id: this.$auth.user().hash_id, store_hash_id: this.store.hash_id },
        });
        this.$store.commit("toggle/toggleLoading", false);
        this.$store.commit("gateway/setOwnerGateways", data);
      } catch (error) {
        this.$store.commit("toggle/toggleLoading", false);
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async getGateways() {
      try {
        const { data } = await GatewayRepository.get();
        this.$store.commit("gateway/setGateways", data.data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
  },
};
</script>