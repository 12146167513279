<template>
  <Store>
    <slot v-if="store.theme" />
    <Loader v-else height="100%" />
  </Store>
</template>

<script>
import { mapState } from "vuex";
import Store from "@/components/shared/Store";
import Loader from "@/components/shared/Loader";
export default {
  components: {
    Store,
    Loader
  },
  data() {
    return {
      defaultColors: {
        primary: {
          base: "#FF5E03",
          // darken1: "#DB5103",
          // lighten1: "#FC6A17",
          // lighten2: "#FF772A",
          // lighten3: "#FC975E",
        },
        secondary: "#FFFFFF",
        tertiary: "#40484E",
        background: "#FFFFFF",
      },
    };
  },
  mounted() {
    this.setStoreTheme();
  },
  methods: {
    setStoreTheme() {
      this.$vuetify.theme.themes.light.primary.base = this.defaultColors.primary.base;
      // this.$vuetify.theme.themes.light.primary.darken1 = this.defaultColors.primary.darken1;
      // this.$vuetify.theme.themes.light.primary.lighten1 = this.defaultColors.primary.lighten1;
      // this.$vuetify.theme.themes.light.primary.lighten2 = this.defaultColors.primary.lighten2;
      // this.$vuetify.theme.themes.light.primary.lighten3 = this.defaultColors.primary.lighten3;
      this.$vuetify.theme.themes.light.secondary.base = this.defaultColors.secondary;
      this.$vuetify.theme.themes.light.tertiary.base = this.defaultColors.tertiary;
      this.$vuetify.theme.themes.light.tertiary.base = this.defaultColors.tertiary;
      this.$vuetify.theme.themes.light.background = this.defaultColors.background;
    },
  },
  computed: {
    ...mapState("store", ["store"]),
  },
  watch: {
    "store.name"(newValue) {
      if (newValue) {
        document.title = newValue;
      }
    },
    "store.theme"(newValue) {
      if (newValue) {
        this.$vuetify.theme.themes.light.primary.base = newValue;
      }
    },
  },
};
</script>

<style>
</style>