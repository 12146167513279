<template>
  <div>
    <SharedOrder>
      <template v-slot="{ getBuyerOrders }">
        <OrderList @getBuyerOrders="getBuyerOrders" />
      </template>
    </SharedOrder>
  </div>
</template>

<script>
import SharedOrder from "@/components/shared/Order";
import OrderList from "@/components/shop/default/OrderList";
export default {
  components: {
    SharedOrder,
    OrderList
  },
};
</script>

<style>
</style>