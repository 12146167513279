<template>
  <v-container class="px-4 py-10">
    <v-row>
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">
              {{ $t("fo-customers") }}
            </div>
            <div class="text-body-1 font-weight-black secondary--text text--darken-4">
              {{ $t("fo-manage-your-customers") }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="3">
            <v-text-field
              v-model="params.filter"
              solo
              flat
              :label="$t('fo-number-email-or-phone')"
              clearable
              hide-details
              append-icon="mdi-magnify"
              @click:append="$emit('getBuyersByStore', params)"
              @keyup.enter="$emit('getBuyersByStore', params)"
              @click:clear="clearFilterParam"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="params.order"
              @change="$emit('getBuyersByStore', params)"
              :items="items"
              item-text="text"
              item-value="value"
              :label="$t('fo-order-by')"
              solo
              flat
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat class="d-flex flex-column rounded-lg" min-height="450">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="buyers.data"
              disable-filtering
              disable-sort
              :loading="loading"
              :loading-text="$t('fo-loading-customers')"
              :no-data-text="$t('fo-no-registered-customers')"
              hide-default-footer
            >
            </v-data-table>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              v-model="params.page"
              :length="buyers.last_page"
              class="elevation-0"
              @input="nextPage"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      pageCount: 0,
      itemsPerPage: 8,
      params: {
        filter: null,
        order: null,
        page: 1,
      },
      items: [
        { text: this.$t("fo-name"), value: "name" },
        { text: this.$t("fo-email"), value: "email" },
        { text: this.$t("fo-phone"), value: "phone" },
      ],
      headers: [
        {
          text: this.$t("fo-name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("fo-email"),
          value: "email",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("fo-phone"),
          value: "phone",
          sortable: false,
          align: "center",
        }
      ],
    };
  },
  computed: {
    ...mapState("buyer", ["buyers", "loading"]),
  },
  mounted() {
    this.$emit("getBuyersByStore", this.params);
  },
  methods: {
    clearFilterParam() {
      this.params.filter = null;
      this.$emit("getBuyersByStore", this.params);
    },
    nextPage(page) {
      this.params.page = page;
      this.$emit("getBuyersByStore", this.params);
    }
  },
};
</script>

<style>
</style>