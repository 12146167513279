<template>
  <div>
    <slot
      :products="filteredProducts"
      :setProduct="setProduct"
      :searchProduct="searchProduct"
      :searchProductCategory="searchProductCategory"
      :searchProductPrice="searchProductPrice"
      :createProduct="createProduct"
      :updateProduct="updateProduct"
      :deleteProduct="deleteProduct"
      :getStoreProducts="getStoreProducts"
      :getProduct="getProduct"
      :detachComplementGroup="detachComplementGroup"
      :attachComplementGroup="attachComplementGroup"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
const ProductRepository = Repository.get("product");
const StoreRepository = Repository.get("store");

export default {
  data: () => ({
    search: null,
    filters: [],
    range: [],
  }),
  computed: {
    ...mapState("store", ["store"]),
    ...mapState("product", ["products"]),
    filteredProducts() {
      return (
        !this.activeProdutcs.length ||
        this.activeProdutcs
          .filter((i) => {
            return !this.search || i.name.toLowerCase().includes(this.search.toLowerCase());
          })
          .filter((i) => {
            return (
              !this.filters.length ||
              this.filters.find(({ hash_id }) => i.category_hash_id === hash_id)
            );
          })
          .filter((i) => {
            return (
              !this.range.length ||
              ((i.price >= this.range[0] * 100) && (i.price <= this.range[1] * 100))
            );
          })
      );
    },
    activeProdutcs() {
      return !this.products.length || this.products.filter((product) => { return product.active == 1 })
    }
  },
  methods: {
    async getStoreProducts(id) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await StoreRepository.getStoreItems(id);
        this.$store.commit("product/setProducts", data);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async createProduct(payload) {
      this.$store.commit("product/setButtonLoading", true);
      try {
        await ProductRepository.create(this.store.hash_id, payload);
        this.$router.push("/owner/products");
        this.$store.commit("product/setButtonLoading", false);
        this.$notifications.showSuccess({ message: 'fo-product-created-successfully' });
      } catch (error) {
        this.$store.commit("product/setButtonLoading", false);
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("product/setButtonLoading", false);
      }
    },
    async getProduct(id) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        const { data } = await ProductRepository.getProductById(id);
        this.$store.commit("product/setProduct", data);
        this.$store.commit("toggle/toggleLoading", false);
      } catch (error) {
        this.$notifications.showError({ code: error });
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async updateProduct(payload) {
      this.$store.commit("product/setButtonLoading", true);
      try {
        await ProductRepository.update(payload);
        this.$router.push("/owner/products");
        this.$store.commit("product/setButtonLoading", false);
        this.$notifications.showSuccess({ message: 'fo-product-updated-successfully' });
      } catch (error) {
        this.$store.commit("product/setButtonLoading", false);
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("product/setButtonLoading", false);
      }
    },
    async deleteProduct(id) {
      try {
        await ProductRepository.delete(id);
        this.getStoreProducts(this.store.hash_id);
        this.$notifications.showSuccess({ message: 'fo-product-deleted-successfully' });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    setProduct(product) {
      this.$store.commit("toggle/toggleLoading", true);
      try {
        this.$store.dispatch("product/setProduct", product);
        this.$store.commit("product/cleanCheckedComplementGroups");
        this.$store.commit("cart/cleanItemComplements");
        this.$store.commit("cart/resetCartItem");
        this.$store.commit("toggle/toggleDialog", true);
      } catch (error) {
        this.$notifications.showError({ code: error });
      } finally {
        this.$store.commit("toggle/toggleLoading", false);
      }
    },
    async detachComplementGroup(id, payload) {
      try {
        await ProductRepository.detachComplementGroup(id, { complement_group_hash_id: payload });
        this.getProduct(id);
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async attachComplementGroup(id, payload) {
      try {
        await ProductRepository.attachComplementGroup(id, { complement_group_hash_id: payload });
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    searchProduct(search) {
      this.search = search;
    },
    searchProductCategory(filters) {
      this.filters = filters;
    },
    searchProductPrice(range) {
      this.range = range;
    },
  },
  // mounted() {
  //   this.getStoreProducts(this.store.hash_id);
  // },
};
</script>

<style>
</style>