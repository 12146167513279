import client from './Clients/AxiosClient';
const resource = '/address';

export default {
    create(payload) {
        return client.post(`${resource}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`);
    },
    getAddressById(id) {
        return client.get(`${resource}/${id}`);
    },
    getBuyerAddresses() {
        return client.get(`${resource}/all`);
    }
};