<template>
  <v-form @submit.prevent ref="pagarmeForm" v-model="valid" lazy-validation>
    <v-row dense class="flex-column">
      <v-col>
        {{ $t("fo-client-data") }}
      </v-col>
      <v-col>
        <v-text-field
          :rules="nameRules"
          outlined
          :value="order.payment.customer.name"
          @input="updatePaymentCustomer('name', $event)"
          :placeholder="$t('fo-buyer-name')"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :rules="emailRules"
          outlined
          :value="order.payment.customer.email"
          @input="updatePaymentCustomer('email', $event)"
          :placeholder="$t('fo-email')"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              :rules="cpfRules"
              outlined
              maxlength="11"
              :value="order.payment.customer.document"
              @input="updatePaymentCustomer('document', $event)"
              placeholder="CPF"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              :rules="pagarmeAreaCodeRules"
              outlined
              maxlength="2"
              :value="order.payment.customer.phone.area_code"
              @input="updatePaymentCustomerPhone('area_code', $event)"
              placeholder="DDD"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="pagarmePhoneRules"
              outlined
              maxlength="9"
              :value="order.payment.customer.phone.number"
              @input="updatePaymentCustomerPhone('number', $event)"
              :placeholder="$t('fo-phone')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="flex-column" v-if="order.payment.payment_method !== 'PIX'">
      <v-col>
        <v-text-field
          :rules="required"
          outlined
          v-model="order.payment.billing_address.line_1"
          :placeholder="$t('fo-address')"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              :rules="cepRules"
              outlined
              maxlength="8"
              v-model="order.payment.billing_address.zip_code"
              :placeholder="$t('fo-zipcode')"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="cityRules"
              outlined
              v-model="order.payment.billing_address.city"
              :placeholder="$t('fo-city')"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="stateRules"
              outlined
              maxlength="2"
              v-model="order.payment.billing_address.state"
              :placeholder="$t('fo-state')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        {{ $t("fo-card-data") }}
      </v-col>
      <v-col>
        <v-text-field
          :rules="cardHolderName"
          outlined
          :placeholder="$t('fo-card-name')"
          v-model="card.holder_name"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :rules="cardRules"
          maxlength="19"
          outlined
          :placeholder="$t('fo-card-number')"
          v-model="card.number"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              :rules="cardMonthRules"
              outlined
              maxlength="2"
              placeholder="12"
              v-model="card.exp_month"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="cardYearRules"
              outlined
              maxlength="4"
              placeholder="2024"
              v-model="card.exp_year"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="cardCvvRules"
              outlined
              maxlength="4"
              placeholder="123"
              v-model="card.cvv"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          depressed
          large
          :disabled="!valid"
          color="primary"
          block
          @click="validate"
          >{{ $t("fo-proceed") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import { validationRules } from "@/mixins/formValidation";
import axios from 'axios'
export default {
  mixins: [validationRules],
  data() {
    return {
      card: {
        number: null,
        holder_name: null,
        exp_month: null,
        exp_year: null,
        cvv: null,
      },
      valid: true,
    };
  },
  mounted() {
    this.setBuyerInfo();
    console.log(this.order)
    console.log(this.address)

  },
  methods: {
    updatePaymentCustomer(key, value) {
      this.$store.commit("order/updatePaymentCustomer", { key: key, value });
    },
    updatePaymentCustomerAddress(key, value) {
      this.$store.commit("order/updatePaymentCustomerAddress", { key: key, value });
    },
    updatePaymentCustomerPhone(key, value) {
      this.$store.commit("order/updatePaymentCustomerPhone", {
        key: key,
        value,
      });
    },
    validate() {
      if (this.$refs.pagarmeForm.validate()) {
        if(this.order.payment.payment_method == "PIX") {
          this.$store.commit("order/setStep", 2);
        } else {
          this.getToken();
        }
      }
    },
    getToken() {
       axios.post(`https://api.pagar.me/core/v5/tokens?appId=${this.publicKey}`, { card: this.card, type: "card"})
        .then((dataToken) => {
          this.$store.commit("order/updatePayment", { key: "card_token", value: dataToken.data.id });
          this.$store.commit("payment/updateCard", dataToken.data);
          this.$store.commit("order/setStep", 2);
        })
        .catch((error) => {
          let array = error.response.data.errors["request.card"];
          array.forEach((value) => {
            this.$notifications.showError({ message: value });
          });
        });
    },
    setBuyerInfo() {
      this.updatePaymentCustomer('name', this.$auth.user() ? this.$auth.user().name : this.order.buyer_name);
      this.updatePaymentCustomer('email', this.$auth.user() ? this.$auth.user().email : null);
      this.updatePaymentCustomerPhone('number', this.$auth.user() ? this.$auth.user().phone : this.order.buyer_phone);
      this.updatePaymentCustomerAddress('line_1', this.address.street);
      this.updatePaymentCustomerAddress('zip_code', this.address.zip_code);
      this.updatePaymentCustomerAddress('city', this.address.city_name);
      this.updatePaymentCustomerAddress('state', this.address.state_name);
    }
  },
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("store", ["store"]),
    ...mapState("buyer", ["address"]),
    publicKey() {
      return this.store.owner_gateway ? this.store.owner_gateway.config.pk : null
    }
  },
};
</script>

<style>
</style>