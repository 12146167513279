<template>
  <v-col  v-if="!loading" :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12">
    <v-row v-if="visibleProducts.length > 0">
      <v-col v-for="product in visibleProducts" :key="product.id" cols="12" xl="4" lg="4" md="4" sm="4">
        <ProductCard :product="product" @setProduct="$emit('setProduct', product)" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        {{ $t("fo-no-registered-products") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          block
          depressed
          :ripple="false"
          @click="loadMore"
          :disabled="disableButton"
          color="primary"
          class="text-none font-weight-bold rounded-lg"
          >{{ $t("fo-load-more") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-col>
  <v-col v-else cols="12">
    <Loader :height="400" />
 </v-col>
</template>

<script>
import Loader from "@/components/shared/Loader";
import ProductCard from "@/components/shop/default/ProductCard";
import { mapState } from "vuex";
export default {
  props: ["products"],
  components: {
    Loader,
    ProductCard,
  },
  data() {
    return {
      itemsPerPage: 6,
    }
  },
  created() {
    this.getProducts();
  },
  computed: {
    ...mapState("toggle", ["loading"]),
    ...mapState("product", ["paginationInfo"]),
    ...mapState("store", ["store"]),
    disableButton() {
      return !this.products.length || this.products.length <= this.itemsPerPage ? true : false;
    },
    visibleProducts () {
      return !this.products.length || this.products.slice(0, this.itemsPerPage);
    },
  },
  methods: {
    getProducts() {
      this.$emit("getProducts", this.store.hash_id);
    },
    loadMore() {
      this.itemsPerPage += 6;
    }
  }
};
</script>

<style>
</style>