// initial state
const initialState = () => ({
    step: 1,
    gateway: {
        hash_id: null,
        gateway_hash_id: null,
        owner_hash_id: null,
        store_hash_id: null,
        config: {
            pk: null,
            sk: null,
            payment_methods: []
        },
        is_active: true,
        test: 0,
        gateway: null
    },
    gateways: [],
    ownerGateways: []
});

const state = initialState;

//getters
const getters = {

}

// mutations
const mutations = {
    updateGateway(state, { key, value }) {
        state.gateway[key] = value;
    },
    updateGatewayConfig(state, { key, value }) {
        state.gateway.config[key] = value;
    },
    setGateway(state, payload) {
        state.gateway.hash_id = payload.hash_id
        state.gateway.gateway_hash_id = payload.gateway_hash_id
        state.gateway.owner_hash_id = payload.owner_hash_id
        state.gateway.store_hash_id = payload.store_hash_id
        state.gateway.config = payload.config
        state.gateway.test = payload.test
        state.gateway.is_active = payload.is_active
        state.gateway.gateway = payload.gateway
    },
    setGateways(state, payload) {
        state.gateways = payload
    },
    setOwnerGateways(state, payload) {
        state.ownerGateways = payload
    },
    stringfyConfig(state, payload) {
        state.gateway.config = JSON.stringify(payload);
    },
    setStep(state, payload) {
        state.step = payload
    },
    resetGateway(state) {
        state.gateway.hash_id = null
        state.gateway.gateway_hash_id = null
        state.gateway.owner_hash_id = null
        state.gateway.store_hash_id = null
        state.gateway.config = { pk: null, sk: null, payment_methods: [] }
        state.gateway.is_active = true
        state.gateway.test = 0
        state.gateway.gateway = null
    },
    resetGateways(state) {
        state.gateways.splice(0);
    },
    reset(state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}