<template>
  <svg class="custom-icon" width="71" height="53" viewBox="0 0 71 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5933 0C3.3748 0 0 3.36508 0 7.57143V13.4603V21.0317V45.4286C0 49.6349 3.3748 53 7.5933 53H63.2775C67.496 53 70.8708 49.6349 70.8708 45.4286V20.1905V13.4603V7.57143C71.7145 3.36508 68.3397 0 63.2775 0H7.5933ZM7.5933 4.20635H63.2775C64.9649 4.20635 66.6523 5.88889 66.6523 7.57143V10.9365H4.2185V7.57143C4.2185 5.88889 5.9059 4.20635 7.5933 4.20635ZM4.2185 15.1429H66.6523V17.6667H4.2185V15.1429ZM4.2185 22.7143H66.6523V44.5873C66.6523 46.2698 64.9649 47.9524 63.2775 47.9524H7.5933C5.9059 47.9524 4.2185 46.2698 4.2185 44.5873V22.7143ZM46.4035 28.6032C42.185 28.6032 37.9665 31.9683 37.9665 37.0159C37.9665 41.2222 41.3413 45.4286 46.4035 45.4286C48.0909 45.4286 48.9346 44.5873 50.622 44.5873C51.4657 45.4286 53.1531 45.4286 54.8405 45.4286C59.059 45.4286 63.2775 42.0635 63.2775 37.0159C63.2775 31.9683 59.9027 28.6032 54.8405 28.6032C53.1531 28.6032 52.3094 29.4444 50.622 29.4444C48.9346 28.6032 47.2472 28.6032 46.4035 28.6032ZM10.9681 30.2857C10.1244 30.2857 8.437 31.127 8.437 32.8095C8.437 34.4921 9.2807 35.3333 10.9681 35.3333H26.1547C26.9984 35.3333 28.6858 34.4921 28.6858 32.8095C28.6858 31.9683 27.8421 30.2857 26.1547 30.2857H10.9681ZM46.4035 32.8095H47.2472V33.6508C47.2472 34.4921 47.2472 34.4921 47.2472 35.3333C47.2472 36.1746 47.2472 36.1746 47.2472 37.0159V37.8571H46.4035C43.8724 40.381 42.185 38.6984 42.185 36.1746C42.185 34.4921 43.8724 32.8095 46.4035 32.8095ZM53.9968 32.8095C55.6842 32.8095 57.3716 34.4921 57.3716 36.1746C57.3716 37.8571 55.6842 39.5397 53.9968 39.5397C53.1531 39.5397 52.3094 39.5397 51.4657 38.6984C51.4657 38.6984 50.622 37.8571 50.622 37.0159V36.1746C50.622 35.3333 50.622 34.4921 51.4657 33.6508C52.3094 32.8095 53.1531 32.8095 53.9968 32.8095ZM10.9681 37.0159C10.1244 37.0159 8.437 37.8571 8.437 39.5397C8.437 40.381 9.2807 42.0635 10.9681 42.0635H17.7177C18.5614 42.0635 20.2488 41.2222 20.2488 39.5397C20.2488 37.8571 19.4051 37.0159 17.7177 37.0159H10.9681Z"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon {
    fill: currentColor;
}
</style>