<template>
  <v-container class="px-4 py-10">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">{{ $t("fo-store") }}</div>
            <div class="text-body-1 font-weight-black secondary--text text--darken-4">{{ $t('fo-create-and-update-store') }}</div></v-col
          >
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels flat class="rounded-lg" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("fo-general-information")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content eager class="mt-4">
              <v-form
                @submit.prevent
                ref="generalForm"
                v-model="validgeneral"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <ImageComponent
                      :image="store.logo"
                      :image_url="store.logo_url"
                      :hasCrop="true"
                      :hasText="true"
                      @update="updateStore('logo', $event)"
                    />
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col class="pb-0">
                        <v-text-field
                          :value="store.name"
                          @input="updateStore('name', $event)"
                          :label="$t('fo-name')"
                          :rules="nameRules"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          :value="store.email"
                          @input="updateStore('email', $event)"
                          :label="$t('fo-email')"
                          :rules="emailRules"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-alert
                          dense
                          text
                          class="text-subtitle-2 text-xl-body-1 text-lg-body-1 text-md-body-1 text-sm-body-1"
                          border="left"
                          icon="mdi-information-outline"
                          color="warning"
                        >
                         {{ $t("fo-this-number-will-be-used-to-receive-orders") }}
                        </v-alert>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-row dense>
                          <v-col cols="auto">
                            <vue-country-code
                              ref="vueCountryCodeRef"
                              @onSelect="onSelectCountryCode"
                              :defaultCountry="defaultCountry"
                              disabledFetchingCountry
                            >
                            </vue-country-code>
                          </v-col>
                          <!-- <v-col cols="2">
                            <v-text-field
                              :value="store.area_code"
                              @input="updateStore('area_code', $event)"
                              :rules="phoneRules"
                              outlined
                              required
                              placeholder="DDD"
                            ></v-text-field>
                          </v-col> -->
                          <v-col>
                            <v-text-field
                              :value="store.phone"
                              @input="updateStore('phone', $event)"
                              :rules="phoneRules"
                              :label="$t('fo-phone')"
                              append-icon="mdi-whatsapp"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-switch
                          class="my-0 py-0"
                          :input-value="store.notify_by_whatsapp"
                          @change="updateStore('notify_by_whatsapp', $event)"
                          :true-value="1"
                          :false-value="0"
                          :label="$t('fo-enable-receiving-orders-via-whatsapp')"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("fo-address")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content eager class="mt-4">
              <v-form
                @submit.prevent
                ref="addressForm"
                v-model="validaddress"
                lazy-validation
              >
                <v-row dense>
                  <!-- <v-col cols="12" xl="8" lg="8" md="8">
                    <v-text-field
                      :value="store.street"
                      @input="updateStore('street', $event)"
                      :rules="streetavenueRules"
                      :label="$t('fo-street-avenue')"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="4">
                    <v-row align="center">
                      <v-col cols="6">
                        <v-text-field
                          :label="$t('fo-number')"
                          :value="store.number"
                          @input="updateStore('number', $event)"
                          :disabled="no_number"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                       <v-col cols="6">
                        <v-checkbox
                          dense
                          class="my-0 py-0"
                          v-model="no_number"
                          :label="$t('fo-no-number')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                   <v-col cols="12">
                    <v-text-field
                      :value="store.neighborhood"
                      @input="updateStore('neighborhood', $event)"
                      :rules="stateRules"
                      :label="$t('fo-neighborhood')"
                      outlined
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" xl="12" lg="12" md="12">
                    <v-text-field
                      :value="store.address"
                      @input="updateStore('address', $event)"
                      :rules="streetavenueRules"
                      :label="$t('fo-full-address')"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <v-text-field
                      :value="store.zip_code"
                      @input="updateStore('zip_code', $event)"
                      :rules="zipcodeRules"
                      :label="$t('fo-zipcode')"
                      append-icon="mdi-map-marker"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <v-text-field
                      :value="store.city_name"
                      @input="updateStore('city_name', $event)"
                      :rules="cityRules"
                      :label="$t('fo-city')"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                    <v-text-field
                      :value="store.state_name"
                      @input="updateStore('state_name', $event)"
                      :rules="stateRules"
                      :label="$t('fo-state')"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("fo-settings")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content eager class="mt-4">
              <v-form
                @submit.prevent
                ref="paymentForm"
                v-model="validpayment"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                    <v-row class="flex-column">
                      <v-col class="py-0">
                        <v-combobox
                          :value="store.payment_options"
                          @input="updateStore('payment_options', $event)"
                          :items="payment_options"
                          :rules="multipleSelectRules"
                          color="primary"
                          :label="$t('fo-accepted-payment-methods')"
                          multiple
                          outlined
                        >
                          <template v-slot:selection="{ selected, item }">
                            <v-chip
                              v-if="item === item"
                              color="primary"
                              :input-value="selected"
                              small
                            >
                              <span>
                                {{ $t("fo-"+item.toLowerCase().replace('_', '-'))  }}
                              </span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <span>{{ $t("fo-"+item.toLowerCase().replace('_', '-'))  }}</span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- <v-col class="py-0">
                        <v-text-field
                          :value="store.working_times"
                          @input="updateStore('working_times', $event)"
                          label="Horário de funcionamento"
                          :rules="required"
                          outlined
                        ></v-text-field>
                      </v-col> -->
                      <v-col class="py-0">
                        <MoneyInput
                          :label="$t('fo-minimum-amount-per-order')"
                          :rules="amountRules"
                          :inputValue="store.min_order"
                          @update="updateStore('min_order', $event)"
                        />
                      </v-col>
                      <v-col class="py-0">
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              :value="store.delivery_time_min"
                              @input="updateStore('delivery_time_min', Number($event))"
                              :label="$t('fo-minimum-waiting-time')"
                              suffix="min"
                              :rules="deliveryTimeRules"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :value="store.delivery_time_max"
                              @input="updateStore('delivery_time_max', Number($event))"
                              :label="$t('fo-maximum-waiting-time')"
                              suffix="min"
                              :rules="deliveryTimeRules"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                       <v-col class="py-0">
                        <v-radio-group
                          :label="$t('fo-mandatory-customer-registration')"
                          @change="updateStore('need_register', $event)"
                          :value="store.need_register"
                          :rules="radioRules"
                          row
                        >
                          <v-radio :label="$t('fo-yes')" :value="1"></v-radio>
                          <v-radio :label="$t('fo-no')" :value="0"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                    <v-row class="flex-column">
                      <v-col class="py-0">
                        <v-select
                          color="primary"
                          :value="store.currency_code"
                          @input="updateStore('currency_code', $event)"
                          :items="currency"
                          item-text="code"
                          item-value="code"
                          :rules="required"
                          single-line
                          :label="$t('fo-payment-currency-accepted')"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col class="py-0">
                        <v-combobox
                          :value="store.delivery_types"
                          @input="updateStore('delivery_types', $event)"
                          :items="delivery_types"
                          :rules="multipleSelectRules"
                          color="primary"
                          :label="$t('fo-accepted-delivery-method')"
                          multiple
                          outlined
                        >
                          <template v-slot:selection="{ selected, item }">
                            <v-chip
                              v-if="item === item"
                              color="primary"
                              :input-value="selected"
                              small
                            >
                              <span>
                                {{ $t("fo-"+item.toLowerCase()) }}
                              </span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <span>{{ $t("fo-"+item.toLowerCase()) }}</span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col class="py-0">
                        <MoneyInput
                          :label="$t('fo-fixed-delivery-fee')"
                          :rules="required"
                          :inputValue="store.delivery_fee"
                          @update="updateStore('delivery_fee', $event)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("fo-customize")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content eager class="mt-4">
              <v-col cols="12">
                <v-row align="start">
                  <v-col cols="12" xl="4" lg="4" md="3">
                    <h3 class="mb-2">{{ $t("fo-select-color") }}</h3>
                    <Swatcher :modelValue="store.theme" @update="updateStore('theme', $event)" @setCustomColor="updateStore('theme', $event)" />
                  </v-col>
                  <v-col cols="12" xl="8" lg="8" md="9">
                    <h3 class="mb-2">{{ $t("fo-preview") }}</h3>
                    <v-card elevation="8">  
                      <StorePreview :color="store.theme" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" xl="auto" lg="auto" md="auto" sm="auto" class="ms-auto">
        <v-btn
          @click="validate"
          color="primary"
          :ripple="false"
          width="100%"
          depressed
          large
          class="rounded-lg"
          >{{ $t("fo-save") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MoneyInput from "@/components/shared/MoneyInput";
import Swatcher from "@/components/shared/Swatcher";
import StorePreview from "@/components/owner/StorePreview"
import ImageComponent from "@/components/shared/ImageComponent";
import { validationRules } from "@/mixins/formValidation";
import { mapState } from "vuex";
export default {
  mixins: [validationRules],
  components: {
    MoneyInput,
    ImageComponent,
    Swatcher,
    StorePreview
  },
  data() {
    return {
      payment_options: ["CASH", "PAYMENT_TERMINAL", "ONLINE_PAYMENT"],
      delivery_types: ["DELIVERY", "WITHDRAW"],
      validgeneral: true,
      validaddress: true,
      validpayment: true,
      no_number: false,
      panel: [0, 1, 2, 3],
      switch1: true,
      defaultCountry: this.$auth.user().locale.substring(this.$auth.user().locale.length - 2),
      rendered: false
    };
  },
  created() {
    this.$emit("getCurrency");
  },
  methods: {
    onSelectCountryCode({name, iso2, dialCode}) {
      if(!this.rendered && this.store.country_code) {
        const ref = this.$refs.vueCountryCodeRef
        const country = ref.sortedCountries.find((c) => c.dialCode == this.store.country_code.dialCode && c.name == this.store.country_code.name)
        this.defaultCountry = country.iso2
        this.rendered = true
      }

      let countryCode = {
        name: name,
        iso2: iso2,
        dialCode: dialCode
      }
      this.updateStore("country_code", countryCode);
    },
    updateStore(key, value) {
      this.$store.commit("store/updateStore", { key: key, value });
    },
    validate() {
      if (
        this.$refs.generalForm.validate() &&
        this.$refs.addressForm.validate() &&
        this.$refs.paymentForm.validate()
      ) {
        !this.store.hash_id
          ? this.$emit("createStore", this.store)
          : this.$emit("updateStore", this.store);
      } else {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("store", ["store", "currency"]),
  },
  watch: {
    no_number(value) {
      value ? this.updateStore("number", "SN") : this.updateStore("number", null);
    },
    color(value) {
      this.updateStore("store_customization", value);
    }
    // validgeneral(val) {
    //   if (!val) this.panel = 0;
    // },
    // validaddress(val) {
    //   if (!val) this.panel = 1;
    // },
    // validpayment(val) {
    //   if (!val) this.panel = 2;
    // },
  },
};
</script>

<style>
.vue-country-select {
  width: 100%;
  border-radius: 5px;
  height: 56px;
}
</style>