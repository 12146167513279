<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card class="rounded-lg">
      <v-card-title class="text-h5">{{ $t('fo-create-your-store') }}</v-card-title>
      <v-card-text>
        {{ $t("fo-you-dont-have-a-registered-store") }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :ripple="false"
          depressed
          large
          class="rounded-lg mx-2"
          @click="redirect"
          >{{ $t("fo-go-to-store-creation") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.toggle.redirectModal;
      },
      set(value) {
        return this.$store.commit("toggle/toggleRedirectModal", value);
      },
    },
  },
  methods: {
    redirect() {
      this.$router.push({ path: '/owner/store'});
      this.dialog = false;
    }
  }
};
</script>

<style>
</style>