<template>
  <v-container class="px-4 py-10">
    <v-row  v-if="!loading">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto me-auto">
            <div class="text-h5 font-weight-black primary--text">
              {{ $t("fo-orders") }}
            </div>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="orders.length > 0">
        <v-card rounded="xl" class="pa-5 mb-2" v-for="(order, index) in orders" :key="index" @click="viewDetails(order)" :ripple="false">
          <v-row dense align="center">
            <v-col cols="12" class="text-caption" v-if="store.language">
              <v-row align="center">
                <v-col class="me-auto">
                  {{ `${$t("fo-order-placed-in")}: ${$date.formatDate(order.created_at, language )} • ${$date.formatTime(order.created_at, language )}` }}
                </v-col>
                <v-col cols="auto">
                  <v-chip class="ma-2" color="primary" small>
                    {{ `Status: ${ order.store_status.allowsToDelete == 0 ? $t("fo-" + order.store_status.status.toLowerCase().replaceAll("_", "-")) : order.store_status.status.toLowerCase()}` }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
                <v-row dense align="center" v-for="(item, index) in order.cart.items" :key="index">
                    <v-col cols="auto">
                        <v-img
                          class="rounded-lg"
                          height="40"
                          width="40"
                          :src="item.image_url ? item.image_url : require('@/assets/images/no-photo.png')"
                        ></v-img>
                    </v-col>
                    <v-col cols="auto">
                        <span>{{ `${item.quantity}x ${item.name}` }}</span>
                    </v-col>
                    <!-- <v-col cols="auto" class="ms-auto">
                        {{ $money.formatMoney(currencyCode, item.price / 100) }}
                    </v-col> -->
                </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row align="center">
            <v-col cols="auto" class="ms-auto">
              <v-row dense align="center" class="flex-column">
                <v-col v-if="order.delivery_fee" cols="auto" class="align-items-center ms-auto">
                  {{ `${$t('fo-delivery-fee')}: ${$money.formatMoney(currencyCode, order.delivery_fee / 100)}` }}
                </v-col>
                <v-col cols="auto" class="text-subtitle-1 primary--text font-weight-bold ms-auto">
                  {{ `${$t("fo-total")}: ${$money.formatMoney(currencyCode, (order.amount + order.delivery_fee)/100)}` }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
         <v-dialog v-model="dialog" scrollable max-width="500px">
          <v-card class="rounded-lg">
            <v-card-title>
              <span class="text-h5"> {{ $t("fo-order") }}</span>
            </v-card-title>
            <v-card-text>
              <v-row v-if="order.cart">
                <v-col cols="12" v-for="(item, index) in order.cart.items" :key="index">
                  <v-row align="start" dense class="font-weight-black">
                    <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                      {{ item.quantity + "x " + item.name }}
                    </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(currencyCode, item.price / 100) }}
                    </v-col>
                  </v-row>
                  <v-row align="start" dense v-for="(complement, index) in item.cart_complements" :key="index">
                    <v-col cols="6" xl="auto" lg="auto" md="auto" class="me-auto">
                      {{ complement.name }}
                    </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(currencyCode, complement.price / 100) }}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" v-if="item.details">
                    <span class="text-body-2 grey--text">{{ `${$t("fo-dets")}: ${item.details}`  }}</span>
                </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-row v-if="order.delivery_fee" align="start" dense>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="auto me-auto">{{ $t('fo-subtotal') }} </v-col>
                        <v-col cols="auto"> {{ $money.formatMoney(currencyCode, order.amount / 100) }} </v-col>
                      </v-row>
                      </v-col>
                      <v-col cols="12">
                      <v-row>
                        <v-col cols="auto me-auto">{{ $t('fo-delivery-fee') }} </v-col>
                        <v-col cols="auto"> {{ $money.formatMoney(currencyCode, order.delivery_fee / 100) }} </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row align="start" dense class="font-weight-black">
                    <v-col cols="auto me-auto"> {{ $t("fo-total-payable") }} </v-col>
                    <v-col cols="auto">
                      {{ $money.formatMoney(currencyCode, total) }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="order.buyer_info">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-client-data") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-account </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).name }}</span>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-cellphone </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).phone }}</span>
                    </v-col>
                    <v-col cols="12" v-if="order.delivery_method == 'DELIVERY'">
                      <v-icon class="me-2"> mdi-map-marker </v-icon>
                      <span>{{ buyerInfo(order.buyer_info).address }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-delivery-method") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-map-marker </v-icon>
                      <span>{{ $t("fo-"+ order.delivery_method.toLowerCase()) }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-order-date") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-calendar </v-icon>
                      <span>{{ `${$date.formatDate( order.created_at, language )} ${$date.formatTime(order.created_at, language )}` }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto me-auto">
                      <div class="text-body-1 font-weight-black">
                        {{ $t("fo-payment-method") }}
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-icon class="me-2"> mdi-credit-card </v-icon>
                      <span v-if="order.payment_method">{{ $t("fo-"+ order.payment_method.toLowerCase().replace('_', '-')) }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-5 px-5">
              <v-spacer></v-spacer>
              <v-btn
                @click="dialog = false"
                color="primary"
                :ripple="false"
                depressed
                large
                class="rounded-lg mx-2"
              >
                {{ $t("fo-close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" v-else>
        {{ $t("fo-you-have-no-orders-yet") }}
      </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <Loader :height="400" />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/shared/Loader";
export default {
  components: {
    Loader
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 8,
      dialog: false,
      search: "",
    };
  },
  computed: {
    ...mapState("order", ["order", "orders"]),
    ...mapState("store", ["store"]),
    ...mapState("toggle", ["loading"]),
    currencyCode() {
      return !this.store.currency_code ? 'USD' : this.store.currency_code
    },
    language() {
      return !this.store.currency_code ? 'en-US' : this.store.language.replace("_", "-");
    },
    total() {
      return !this.order.delivery_fee ? this.order.amount/100 : (this.order.amount + this.order.delivery_fee)/100;
    },
  },
  created() {
    this.$emit("getBuyerOrders");
  },
  methods: {
    complementTotal(complements) {
      console.log(complements)
    },
    viewDetails(item) {
      this.$store.commit("order/setOrder", item);
      this.$nextTick(() => {
        this.dialog = true;
      });
    },
    buyerInfo(info) {
      const obj = JSON.parse(info);
      return obj;
    },
  },
};
</script>

<style>
</style>