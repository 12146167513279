<template>
    <v-container class="fill-height">
      <Alert />
      <v-row class="my-auto" align="center" justify="center">
        <v-col cols="6" v-if="$vuetify.breakpoint.smAndUp">
          <v-card flat max-width="600" class="mx-auto">
            <CartImage />
          </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-card class="rounded-xl pa-5" height="100%">
            <v-row class="fill-height" align="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" class="mb-5">
                    <span class="primary--text text-xl-h4 text-lg-h4 text-md-h4 text-sm-h4 text-h5 font-weight-bold"
                      >{{ $t("fo-password-recovery") }}</span
                    >
                  </v-col>
                  <v-col cols="12">
                    <Authentication @emailSent="emailSent">
                      <template v-slot="{ forgotPassword }">
                        <AuthenticationForm 
                          @validate="forgotPassword" 
                          :hasName="false" 
                          :hasLink="false" 
                          :hasPassword="false" 
                          :hasPasswordConfirmation="false" 
                          :hasPhone="false"               
                          :buttonText="disable_resend_email ? $t('fo-recover-password-resend-email') + displayTime : $t('fo-recover-password')"                          
                        />
                      </template>
                    </Authentication>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    {{ $t("fo-you-will-receive-an-email") }}                   
                    <span>
                      <RouterLink
                        :to="`/delivery/${$route.params.slug}/${$route.params.id}/login`"
                        class="text-decoration-none font-weight-bold"
                      >
                         {{ $t("fo-back-to-login") }}
                      </RouterLink></span>                    
                    <v-alert class="mt-0.5" v-if="showMessage">
                      {{ $t("fo-not-receive-email-question") }}? <br/>
                      {{ $t("fo-not-receive-email-answer") }}.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
import Alert from "@/components/shared/Alert";
import AuthenticationForm from "@/components/shop/default/AuthenticationForm";
import Authentication from "@/components/shared/Authentication";
import CartImage from "@/components/shared/CartImage";
import { mapState } from 'vuex';
export default {
  components: {
    AuthenticationForm,
    Authentication,
    Alert,
    CartImage
  },

  data() {
    return {
      totalTime: 120, //  minutos em segundos
      remainingTime: 120, // Inicialmente, o tempo restante é igual ao tempo total
      timer: null,     
      showMessage: false 
    };
  },

  computed: {
    ...mapState('buyer', ['disable_resend_email']),
    displayTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return ` ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    emailSent(event) {
      if (event.status) {
        this.$store.commit('buyer/setValid', false);
        this.$store.commit('buyer/setDisableResendEmail', true);
        this.startTimer();        
        this.showMessage = true;
      }
    },

    startTimer() {
      if (this.timer) return; // Evitar múltiplas instâncias do timer
      this.timer = setInterval(this.updateTime, 1000);
    },

    updateTime() {
      if (this.remainingTime > 0) {
        this.remainingTime--;        
      } else {
        this.stopTimer();
        this.$store.commit('buyer/setValid', true);
        this.$store.commit('buyer/setDisableResendEmail', false);
      }
    },
  
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
      //reset timer depois de enviar o primeiro email
      this.totalTime = 120;
      this.remainingTime = 120;
      },
  },

  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style>
</style>