<template>
  <CustomDrawer :drawerToggle="cartDrawer" @onClickOutsideWithConditional="onClickOutsideWithConditional">
    <template v-slot:custom-drawer-content>
      <div v-if="cart.items.length" class="px-10 py-5">
        <CartItems @removeItemCart="removeItemCart" ref="cartItems" />
        <v-row>
          <v-col cols="12" class="font-weight-black">
            <v-btn
              color="primary"
              :ripple="false"
              depressed
              large
              width="100%"
              class="rounded-lg text-none my-2"
              @click="$refs.cartItems.proceed()"
              >{{ $t("fo-proceed") }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-row v-else class="flex-column">
        <v-col cols="auto" class="mt-8 text-center">
          <div class="font-weight-bold">
            {{ $t("fo-your-cart-is-empty") }}
          </div>
          <div class="text-caption">
            {{ $t("fo-add-items") }}
          </div>
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-card flat width="320" class="ms-auto">
            <CartImage />
          </v-card>
        </v-col>
      </v-row>
    </template>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from "@/components/shared/CustomDrawer";
import CartItems from "@/components/shop/default/CartItems";
import CartImage from "@/components/shared/CartImage";
import { mapState } from "vuex";
export default {
  components: {
    CustomDrawer,
    CartItems,
    CartImage
  },
  mounted() {
    this.$emit("getCart");
  },
  methods: {
    removeItemCart(id) {
      this.$emit("removeItemCart", id);
    },
    onClickOutsideWithConditional(value) {
        this.cartDrawer = value;
    }
  },
  computed: {
    ...mapState("cart", ["cart"]),
    cartDrawer: {
      get() {
        return this.$store.state.toggle.cartDrawer;
       },
       set(value) {
         return this.$store.commit("toggle/toggleCartDrawer", value);
       },
    }
  },
};
</script>

<style>
</style>