import client from './Clients/AxiosClient';
const resource = '/item';

export default {
    get() {
        return client.get(`${resource}/all`);
    },
    getProductById(id) {
        return client.get(`${resource}/${id}`);
    },
    create(id, payload) {
        return client.post(`${resource}/${id}`, payload);
    },
    update(payload) {
        return client.put(`${resource}`, payload);
    },
    delete(id) {
        return client.delete(`${resource}/${id}`)
    },
    attachComplementGroup(id, payload) {
        return client.post(`${resource}/${id}/attach_group`, payload);
    },
    detachComplementGroup(id, payload) {
        return client.post(`${resource}/${id}/detach_group`, payload);
    },
};