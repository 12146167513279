<template>
  <svg class="custom-icon" width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8225 0C23.3607 0 28.6613 5.30059 28.6613 11.8388C28.6613 18.4177 21.7537 24.0575 17.5237 28.7585C17.147 29.1773 16.4925 29.1727 16.1181 28.7548C11.9178 24.0671 4.98368 18.4424 4.98368 11.8388C4.98368 5.30059 10.2843 0 16.8225 0ZM27.6346 22.0258C26.5508 21.4275 27.4598 19.7817 28.5423 20.3794C35.161 24.036 35.5427 30.4629 28.4992 34.33C22.016 37.89 11.629 37.89 5.1457 34.33C-1.89825 30.4625 -1.51562 24.036 5.10222 20.3794C6.18468 19.7817 7.09412 21.4266 6.01029 22.0258C0.788429 24.9102 0.250176 29.4903 6.05332 32.6768C11.9668 35.9237 21.6782 35.9237 27.5916 32.6768C33.3897 29.4931 32.8629 24.9184 27.6346 22.0258ZM16.8225 5.35231C20.4749 5.35231 23.4357 8.31315 23.4357 11.9651C23.4357 15.6175 20.4744 18.5784 16.8225 18.5784C13.1705 18.5784 10.2092 15.6175 10.2092 11.9651C10.2092 8.31315 13.17 5.35231 16.8225 5.35231ZM16.8225 7.24168C14.2136 7.24168 12.0986 9.35624 12.0986 11.9651C12.0986 14.5744 14.2136 16.689 16.8225 16.689C19.4313 16.689 21.5464 14.574 21.5464 11.9651C21.5464 9.3567 19.4313 7.24168 16.8225 7.24168ZM16.8225 1.88937C11.3274 1.88937 6.87305 6.34414 6.87305 11.8388C6.87305 17.235 12.8986 22.5677 16.8225 26.7364C20.7463 22.5677 26.7719 17.235 26.7719 11.8388C26.7719 6.34414 22.3176 1.88937 16.8225 1.88937Z"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
.custom-icon {
    fill: currentColor;
}
</style>