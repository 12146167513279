<template>
  <div>
    <v-container v-if="!loading">
      <v-card class="rounded-lg pa-5" flat>
        <v-form @submit.prevent ref="productForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <h1>{{ info.name || "Item" }}</h1>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" xl="8" lg="8" md="8" class="py-0">
                  <v-select
                    :value="info.category_hash_id"
                    @input="updateProductInfo('category_hash_id', $event)"
                    color="primary"
                    :items="categories"
                    item-text="description"
                    item-value="hash_id"
                    :label="$t('fo-categories')"
                    outlined
                    :rules="required"
                  ></v-select>
                </v-col>
                <v-col cols="12" xl="4" lg="4" md="4" class="pt-0">
                  <v-btn
                    color="primary"
                    :ripple="false"
                    depressed
                    large
                    min-height="56"
                    width="100%"
                    class="rounded-lg"
                    @click="openCategoryModal"
                  >
                    <v-icon left color="white">mdi-plus</v-icon>
                    {{ $t("fo-category") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xl="8" lg="8" md="8">
              <v-row class="flex-column">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :value="info.name"
                    @input="updateProductInfo('name', $event)"
                    :label="$t('fo-name')"
                    outlined
                    maxlength="100"
                    counter="100"
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-textarea
                    :value="info.description"
                    @input="updateProductInfo('description', $event)"
                    outlined
                    name="input-7-5"
                    maxlength="255"
                    counter="255"
                    :label="$t('fo-description')"
                    :rules="required"
                  ></v-textarea>
                </v-col>
                <v-col class="py-0">
                  <MoneyInput
                    :label="$t('fo-value')"
                    :rules="amountRules"
                    :inputValue="info.price"
                    @update="updateProductInfo('price', $event)"
                  />
                </v-col>
                <v-col class="py-0">
                  <v-radio-group
                    class="pa-0"
                    label="Status"
                    @change="updateProductInfo('active', $event)"
                    :value="info.active"
                    :rules="radioRules"
                    row
                  >
                    <v-radio :label="$t('fo-active')" :value="1"></v-radio>
                    <v-radio :label="$t('fo-inactive')" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
              <ImageComponent
                :image="info.image"
                :image_url="info.image_url"
                :hasCrop="true"
                :hasText="true"
                @update="updateProductInfo('image', $event)"
              />
            </v-col>
            <v-col cols="12" order="4">
              <v-row>
                <v-col class="me-auto">
                  <h1 class="mb-2">{{ $t("fo-additionals") }}</h1>
                </v-col>
                <v-col cols="12" xl="auto" lg="auto" md="auto">
                  <v-btn
                    color="primary"
                    class="text-caption text-xl-body-1 text-lg-body-1 text-md-body-1"
                    width="100%"
                    @click="openAddtionalsModal"
                    depressed
                    large
                  >
                    <v-icon left color="white">mdi-plus</v-icon>
                    {{ $t("fo-additionals-group") }}</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :value="info.complement_groups"
                    @input="updateProductInfo('complement_groups', $event)"
                    :items="groupAdditionals"
                    chips
                    color="primary"
                    :label="$t('fo-additionals-group')"
                    item-text="name"
                    item-value="hash_id"
                    outlined
                    multiple
                    hide-selected
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        color="primary"
                        @click:close="detachComplementGroup(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content @click="attachComplementGroup(data.item)">
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.complements.length">
                          <div
                            v-for="(complement, index) in data.item.complements"
                            :key="index"
                          >
                            {{ complement.name }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" order="5">
              <Additional>
                <template
                  v-slot="{
                    createAdditionalGroup,
                    updateAdditionalGroup,
                    deleteAdditionalGroup,
                    createAdditional,
                    deleteAdditional,
                    updateAdditional,
                  }"
                >
                  <Additionals
                    @createAdditionalGroup="createAdditionalGroup"
                    @updateAdditionalGroup="updateAdditionalGroup"
                    @deleteAdditionalGroup="deleteAdditionalGroup"
                    @deleteAdditional="deleteAdditional"
                    @createAdditional="createAdditional"
                    @updateAdditional="updateAdditional"
                  />
                </template>
              </Additional>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xl="auto" lg="auto" md="auto" class="ms-auto">
              <v-btn
                :disabled="valid ? buttonLoading : !valid"
                :loading="buttonLoading"
                color="primary"
                :ripple="false"
                depressed
                large
                width="100%"
                class="rounded-lg"
                @click="validate"
              >
                {{ $t("fo-save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <Loader v-else />
    <Categories>
      <template v-slot="{ createCategoryFromProduct, updateCategory }">
        <CategoryModal
          @createCategory="createCategoryFromProduct"
          @updateCategory="updateCategory"
        />
      </template>
    </Categories>
  </div>
</template>

<script>
import Categories from "@/components/shared/Categories";
import CategoryModal from "@/components/owner/CategoryModal";
import Additionals from "@/components/owner/Additionals";
import Additional from "@/components/shared/Additional";
import MoneyInput from "@/components/shared/MoneyInput";
import ImageComponent from "@/components/shared/ImageComponent";
import Loader from "@/components/shared/Loader";
import { validationRules } from "@/mixins/formValidation";
import { mapState } from "vuex";
export default {
  components: {
    Categories,
    CategoryModal,
    Additionals,
    Additional,
    Loader,
    MoneyInput,
    ImageComponent,
  },
  mixins: [validationRules],
  data() {
    return {
      valid: true,
    };
  },
  created() {
    if (this.$route.params.productId != "new") {
      let id = this.$route.params.productId;
      this.$emit("getProduct", id);
    } else {
      this.$store.commit("product/reset");
    }
  },
  methods: {
    attachComplementGroup(group) {
      if (this.info.hash_id) {
        this.$emit("attachComplementGroup", this.info.hash_id, group.hash_id);
      }
    },
    detachComplementGroup(item) {
      this.info.hash_id
        ? this.$emit("detachComplementGroup", this.info.hash_id, item.hash_id)
        : this.info.complement_groups.splice(
            this.info.complement_groups.indexOf(item.hash_id),
            1
          );
    },
    updateProductInfo(key, value) {
      this.$store.commit("product/updateProductInfo", { key: key, value });
    },
    validate() {
      if (this.$refs.productForm.validate()) {
        !this.info.hash_id
          ? this.$emit("createProduct", this.info)
          : this.$emit("updateProduct", this.info);
      }
    },
    openAddtionalsModal() {
      this.$store.commit("toggle/toggleAdditionalsModal", true);
    },
    openCategoryModal() {
      this.$store.commit("toggle/toggleCategoryModal", true);
    },
  },
  computed: {
    ...mapState("product", ["info", "buttonLoading"]),
    ...mapState("toggle", ["loading"]),
    ...mapState("additional", ["groupAdditionals"]),
    categories: {
      get() {
        return this.$store.state.category.categories.length
          ? this.$store.state.category.categories
          : [];
      },
    },
  },
};
</script>

<style>
</style>