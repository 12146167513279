<template>
  <v-sheet class="custom-sheet" @click.native="$emit('setProduct')">
    <v-card class="mx-auto rounded-lg rounded-b-0" height="200">
      <v-img
        height="200px"
        :src="product.image_url ? product.image_url : require('@/assets/images/no-photo.png')"
      ></v-img
    ></v-card>
    <v-card rounded="xl" class="mt-n4 mx-auto" elevation="6" height="130">
      <v-card-title class="custom-title text-body-2 font-weight-black">{{
        product.name
      }}</v-card-title>
      <!-- <v-card-text>
        <v-row align="center" class="mx-0 pt-0">
          <v-rating
            :value="4.5"
            color="primary"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>
          <div class="text-caption ms-2 font-weight-black">4.5</div>
        </v-row>
      </v-card-text> -->
      <v-card-actions class="px-4 mt-n2">
        <span class=" text-subtitle-1 primary--text font-weight-bold">{{
          $money.formatMoney(store.currency_code, price)
        }}</span>
        <v-spacer></v-spacer>
        <v-icon color="primary">$add_cart</v-icon>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    ...mapState("store", ["store"]),
    price() {
      return this.product.price / 100;
    },
  },
};
</script>

<style scoped>
.custom-sheet {
  cursor: pointer;
}
.custom-title {
  height: 80px;
  overflow: hidden; 
  align-content: start; 
  word-break:break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>