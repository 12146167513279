<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import DefaultLayout from "@/layouts/default";
export default {
  components: {
    DefaultLayout
  },
   data: () => ({
    user: {
      template: 'default'
    },
  }),
  computed: {
     layout() {
      if (this.user.template == "default") {
        return "DefaultLayout";
      } else {
        return "NewLayout";
      }
    },
  }
}
</script>

<style>

</style>