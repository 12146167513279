<template>
  <div>
    <slot :addItemCart="addItemCart" :removeItemCart="removeItemCart" :getCart="getCart" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Repository from "@/repositories/RepositoryFactory";
// const CartRepository = Repository.get("cart");
const BuyerRepository = Repository.get("buyer");
export default {
  computed: {
    ...mapState("cart", ["cart"]),
  },
  methods: {
    async addItemCart(payload) {
      try {
        const { data } = await BuyerRepository.addItemCart(this.$route.params.id, payload);
        this.$store.commit("cart/setCart", data);
        this.storeCartId(data.hash_id);
        this.$notifications.showSuccess({ message: 'fo-item-added-to-cart' });
        this.getCart();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async getBuyerCart(id) {
      try {
        const { data } = await BuyerRepository.getBuyerCart(this.$route.params.id, id);
        if(data.hash_id) {
         this.$store.commit("cart/setCart", data);
        }
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    async setBuyerCart(id) {
      try {
        await BuyerRepository.setBuyerCart(id);
        this.getBuyerCart();
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    storeCartId(id) {
      if ((this.$auth.isLoggedIn() && this.$auth.getScope().includes('owner') && !this.getCartId()) || (!this.$auth.isLoggedIn() && !this.getCartId())) {
        this.$cookies.set("cart_hash", id);
      }
    },
    getCartId() {
      let cartHash = this.$cookies.get("cart_hash");
      return cartHash;
    },
    async removeItemCart(payload) {
      try {
        await BuyerRepository.removeItemCart(payload);
        this.getCart()
      } catch (error) {
        this.$notifications.showError({ code: error });
      }
    },
    getCart() {
      let cartHash = this.getCartId();
      if ((this.$auth.isLoggedIn() && this.$auth.getScope().includes('owner') && cartHash) || (!this.$auth.isLoggedIn() && cartHash)) {
        this.getBuyerCart(cartHash);
      } if(this.$auth.isLoggedIn() && this.$auth.getScope().includes('buyer')) {
        this.getBuyerCart()
      }
    },
  },
  // mounted() {
  //   this.getCart();
  // },
};
</script>

<style>
</style>